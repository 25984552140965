import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <footer className="flex justify-between items-center bg-green-100 fixed bottom-0 w-full md:w-[calc(100vw_-_290px)] right-0 p-2">
            <div className="flex text-green-800 gap-4">
                <Link to="/dashboard/extra/privacy-policy">Privacy Policy</Link>
                <Link to="/dashboard/extra/terms-of-service">Terms of Use</Link>
            </div>
            <div className="right-panel">© {new Date().getFullYear().toString()}</div>
        </footer>
    );
};

export default Footer;
