import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { FloatLabel, InputText } from 'components/ui-kit';
import { classNames } from 'primereact/utils';
import * as React from 'react';
import { Controller, useController } from 'react-hook-form';

export interface InputFieldProps extends BaseFormControlProps {
    placeholder?: string;
    type?: string | 'number' | 'text' | 'password' | 'email' | 'date';
    onValueChange?: (value: string) => void;
    min?: number;
    max?: number;
    className?: string;
    prefixText?: string;
    suffixText?: string;
}

export function InputField({
    name,
    control,
    label,
    placeholder,
    type,
    disabled = false,
    defaultValue,
    required = false,
    readOnly,
    min,
    max,
    onValueChange,
    suffixText,
    errorMessage,
}: InputFieldProps) {
    const {
        field: { onChange, onBlur },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: required ? { required: 'This field is required' } : {},
    });

    React.useEffect(() => {
        onChange(defaultValue);
    }, [defaultValue]);

    return (
        <div className="w-full">
            <FloatLabel>
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: 'Name is required.' }}
                    render={({ field, fieldState }) => (
                        <InputText
                            placeholder={placeholder}
                            id={field.name}
                            {...field}
                            type={type}
                            disabled={disabled}
                            autoFocus
                            className={classNames({ 'p-invalid': fieldState.invalid }, 'w-full')}
                            readOnly={readOnly}
                            min={min}
                            max={max}
                            onBlur={onBlur}
                            onChange={(e) => {
                                onChange(e.target.value);
                                onValueChange && onValueChange(e.target.value);
                            }}
                        />
                    )}
                />
                <label htmlFor="name" className={classNames({ 'p-error': error })}>
                    {label} {required && <span className="text-red-500">*</span>}
                </label>
            </FloatLabel>
            {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
        </div>
    );
}
