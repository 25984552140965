import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { PPRLevelResponse } from 'api/v2/ppr/model/pprLevel.model';
import pprLevelApi from 'api/v2/ppr/pprLevelApi';
import { AddButton } from 'components/Button';
import { FilterButton } from 'components/Button/FilterButton';
import { CompanySelect } from 'components/Common';
import usePromptInputText from 'components/Modal/PromptInputText';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row } from 'components/ui-kit';
import Card from 'components/ui-kit/primereact/Card';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const PPRLevelPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    // const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const cols = useMemo(
        () => [
            {
                field: 'levelName',
                title: lang.text(Translations.wpr.ppr.level),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const { control } = useForm();
    const [companyId, setCompanyId] = useState<string>('');
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        pprLevelApi
            .getPagination({
                ...pagination,
                companyId: companyId,
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                action: <ActionButtons2 id={n.id} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        if (!companyId?.hasValue()) return;
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: '',
        });
        text &&
            pprLevelApi
                .create({
                    levelName: text,
                    companyId: companyId,
                })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };

    const onUpdate = async (data: PPRLevelResponse) => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: data.levelName,
        });
        text &&
            pprLevelApi
                .update({
                    levelName: text,
                    companyId: companyId,
                })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };
    const onDelete = async (id: string) => {
        pprLevelApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <Row>
                        <CompanySelect md={4} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                        <Col md={8}>
                            <div className=" flex justify-end">
                                <AddButton isRender={true} onClick={onAdd} />
                                <FilterButton onClick={() => handleFetchData(defaultPaginationRequest)} className="mx-2" />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <PaginationTable
                onSearch={handleFetchData}
                onPaginate={handleFetchData}
                onRowClick={(item) => {}}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={true}
                wrapperClass="mt-5"
            />
            {PromptInputText}
        </Fragment>
    );
};

export default PPRLevelPage;
