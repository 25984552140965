import { Tab } from 'components/ui-kit';
import { SECRegisterType } from 'enum/sec';
import { TabPanel } from 'primereact/tabview';
import { useState } from 'react';
import UserRegisterPage from './userRegister';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const SECRegisterPage = () => {
    const [key, setKey] = useState<'equipment' | 'user'>('user');
    return (
        <>
            <Tab activeIndex={key === 'user' ? 0 : 1} onTabChange={(k) => setKey(k.index === 0 ? 'user' : 'equipment')} className="mb-3">
                <TabPanel header={'Người dùng'}>{key === 'user' && <UserRegisterPage registerType={SECRegisterType.PERSON} />}</TabPanel>
                <TabPanel header="Thiết bị">{key === 'equipment' && <UserRegisterPage registerType={SECRegisterType.EQUIPMENT} />}</TabPanel>
            </Tab>
        </>
    );
};

export default SECRegisterPage;
