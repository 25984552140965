import { AppIconName } from 'enum/appIcon';
import classNames from 'classnames';

export interface AppIconProps {
    name: AppIconName;
    size?: string;
    color?: string;
    className?: string;
}

const AppIcon = ({ name, size, color, className }: AppIconProps) => {
    return <i className={classNames('pi', name, className)} style={{ fontSize: size, color: color || 'var(--primary-color)' }} />;
};

export default AppIcon;
