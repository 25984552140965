//react-bootstrap
import { Badge } from 'components/ui-kit';

//components
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';
import { defaultPaginationRequest, PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { OrganizationUnitCreateRequest, OrganizationUnitResponse, OrganizationUnitUpdateRequest } from 'api/models/organization';
import orgUnitApi from 'api/v2/organizationUnitApi';
import ImageWithDefault from 'components/bootstrap/ImageWithDefault';
import { AddButton } from 'components/Button';
import { CompanySelect } from 'components/Common';
import usePromptInputText from 'components/Modal/PromptInputText';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row } from 'components/ui-kit';
import Card from 'components/ui-kit/primereact/Card';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const ConfigAreaPage = memo(() => {
    const [companyId, setCompanyId] = useState<string>();
    const [data, setData] = useState<PaginationBase<any>>();
    const { userPms } = useAuth();
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    useEffect(() => {
        handleFetchData();
    }, [companyId]);

    const handleFetchData = (paginate?: PaginationBaseRequest) => {
        if (!paginate) paginate = defaultPaginationRequest;

        orgUnitApi
            .getByCurrentCustomer(paginate, OrganizationUnitType.Area, companyId)
            .then((result: PaginationBase<OrganizationUnitResponse>) => {
                var dt: PaginationBase<any> = {
                    ...result,
                    datas: (result?.datas ?? []).map((item) => ({
                        ...item,
                        isActive: item.isActive ? <Badge color="success">{lang.text(Translations.common.show)}</Badge> : <Badge color="warning">{lang.text(Translations.common.hide)}</Badge>,
                        logo: <ImageWithDefault className=" avatar-40" src={item.logo ?? undefined} />,
                        action: (
                            <ActionButtons2
                                id={item.id}
                                deletePms={userPms.orgAdmin}
                                editPms={userPms.orgAdmin}
                                editAction={() => onSubmitUpdate(item.name, item.id)}
                                onDelete={(id) => onAcceptDelete(id)}
                            />
                        ),
                    })),
                };
                setData(dt);
            })
            .catch((err) => {});
    };

    const onAcceptDelete = async (id: string) => {
        orgUnitApi
            .delete(id ?? '')
            .then((n) => {
                handleFetchData();
            })
            .catch((e) => {});
    };

    const onSubmitUpdate = async (name: string, id: string) => {
        if (!companyId) {
            toast.error('Vui lòng chọn nhà máy trước');
            return;
        }
        var newName = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: name,
        });
        if (newName && newName !== name) {
            var request: OrganizationUnitUpdateRequest = {
                name: newName,
                parentId: companyId,
                id: id,
            };
            orgUnitApi
                .update(request)
                .then(() => {
                    handleFetchData();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const onSubmiAdd = async () => {
        if (!companyId) {
            toast.error('Vui lòng chọn nhà máy trước');
            return;
        }
        var newName = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: '',
        });
        if (newName) {
            var request: OrganizationUnitCreateRequest = {
                name: newName,
                parentId: companyId,
                unitType: OrganizationUnitType.Area,
            };
            orgUnitApi
                .create(request)
                .then(() => {
                    handleFetchData();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const schema = yup.object().shape({
        parentId: yup.string(),
    });

    const { control } = useForm<{ parentId?: string | undefined }>({
        defaultValues: {
            parentId: '',
        },
        resolver: yupResolver(schema),
    });

    const cols = useMemo(
        () => [
            {
                field: 'logo',
                header: '',
            },
            {
                field: 'name',
                header: lang.text(Translations.form.area.name),
            },
            {
                field: 'action',
                header: '',
            },
        ],
        []
    );

    return (
        <Card>
            <Card.Header>
                <Row>
                    <CompanySelect md={4} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                    <Col className="filter-box">
                        <div>
                            <AddButton isRender={userPms.orgAdmin} onClick={onSubmiAdd} />
                        </div>
                    </Col>
                </Row>
            </Card.Header>
            {PromptInputText}
            <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} disableSearch={true} columns={cols} pagination={data} isStriped={true} wrapperClass="mt-5" />
        </Card>
    );
});

export default ConfigAreaPage;
