import { yupResolver } from '@hookform/resolvers/yup';
import { LoginRequest } from 'api/v2/user/model';
import { ImageAndroidDownload, ImageIOSDownload, ImageQRScan } from 'assets/images';
import { InputField } from 'components/Form/InputField';
import { Button, Image } from 'components/ui-kit';
import Translations from 'i18n/translation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface LoginFormProps {
    initialValues?: LoginRequest;
    onSubmit?: (formValues: LoginRequest) => void;
}

export default function LoginForm({ initialValues, onSubmit }: LoginFormProps) {
    const [, setError] = useState<string>('');
    const { t } = useTranslation();

    const schema = yup.object().shape({
        phoneNumber: yup.string().required(t(Translations.form.validate.required)),

        password: yup.string().required(t(Translations.form.validate.required)).min(6, t(Translations.form.password.length)).max(30, t(Translations.form.password.length)),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<LoginRequest>({
        defaultValues: initialValues,
        resolver: yupResolver(schema),
    });

    const handleFormSubmit = async (formValues: LoginRequest) => {
        try {
            setError('');
            await onSubmit?.(formValues);
        } catch (error: any) {
            setError(error.message);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="flex flex-col gap-8 mt-6">
                    <InputField xs={12} name="phoneNumber" control={control} label={t(Translations.form.username.name)} placeholder={t(Translations.form.username.placeholder)} />
                    <InputField xs={12} type="password" name="password" control={control} label={t(Translations.form.password.name)} placeholder={t(Translations.form.password.placeholder)} />
                </div>

                {/* <div className="flex justify-between mt-6">
                    <CheckboxField control={control} name="customCheck1" label={t(Translations.pages.signin.rememberMe)} />
                    <Link to="#">{t(Translations.pages.signin.forgotPassword)}</Link>
                </div>  */}

                <div className="flex justify-center pt-3 pb-3">
                    <Button className="flex items-center" type="submit" disabled={isSubmitting}>
                        {/* {isSubmitting && <Spinner animation="border" className="mr-1" variant="danger" />} */}
                        {t('common.login')}
                    </Button>
                </div>
            </form>

            <div className="my-3 flex justify-end">
                <div>
                    <p className="mb-1 text-success fw-bold fs-6 text-end"> {t(Translations.common.download)}</p>
                    <div className="flex gap-2">
                        <Image className="hidden d-lg-block" width={'100px'} height={'95px'} src={ImageQRScan} />
                        <div className="flex gap-2 flex-lg-column justify-content-lg-between">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.msa.sop" rel="noreferrer">
                                <Image style={{ width: '122px' }} src={ImageAndroidDownload} />
                            </a>
                            <a target="_blank" href="https://apps.apple.com/vn/app/hsse/id1557474738" rel="noreferrer">
                                <Image style={{ width: '122px' }} src={ImageIOSDownload} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
