import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Link } from 'react-router-dom';
import { BaseButton } from './BaseButton';
import { BaseButtonProps } from './BaseButton.props';

export function AddButton(props: BaseButtonProps) {
    if (!props.isRender) return <></>;
    return <BaseButton {...props} icon={'pi pi-plus'} size="sm" text={props?.text ?? lang.text(Translations.common.addNew)} />;
}

export interface AddButtonNavigateProps extends BaseButtonProps {
    url?: string;
    isRender?: boolean;
}

export function AddButtonNavigate(props: AddButtonNavigateProps) {
    if (!props.isRender) return <></>;
    return props?.url ? (
        <Link to={props?.url} className={props.className + 'flex justify-end hvr-glow'}>
            <BaseButton {...props} icon={'pi pi-plus'} className="" size="sm" text={props?.text ?? lang.text(Translations.common.addNew)} />
        </Link>
    ) : (
        <BaseButton {...props} icon={'pi pi-plus'} className="" size="sm" text={props?.text ?? lang.text(Translations.common.addNew)} />
    );
}
