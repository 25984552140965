import { yupResolver } from '@hookform/resolvers/yup';
import { locationValidation } from 'api/models/common/locationModel';
import { TRRoute, trSafeInformationValidation } from 'api/v2/trip/model/route.model';
import routeApi from 'api/v2/trip/routeApi';
import { DeleteButton, SaveButton, UpdateButton } from 'components/Button';
import { CompanySelect } from 'components/Common';
import { InputField } from 'components/FormControl';
import { LocationField } from 'components/FormControl/LocationField';
import { Card, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Routers from 'router/const/routers';
import * as yup from 'yup';
import RestPlacesComponent from '../components/restPlaces.component';
import SafeInformationPlaceComponent from '../components/safeInformation.component';

export default function RouteTemplateCreatePage() {
    const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
    const param = useParams();
    const routeId = param?.id;
    const isCreate = routeId === 'create';
    const navigate = useNavigate();

    const [detail, setDetail] = useState<TRRoute>();
    const { userPms } = useAuth();

    useEffect(() => {
        if (routeId === 'create') {
            setIsReadOnly(false);
            return;
        }
        getRouteDetail(String(routeId));
    }, [routeId]);

    const schema = yup.object().shape({
        id: yup.string().optional(),
        displayName: yup.string().required('Display name is required'),
        fromPlace: locationValidation.required('From place is required'),
        toPlace: locationValidation.required(),
        companyId: yup.string().required('Company ID is required'),
        places: yup.array().of(locationValidation.required()),
        safeInformations: yup.array().of(trSafeInformationValidation.required()),
    });
    const {
        control,
        getValues,
        reset,
        setValue,
        handleSubmit,
        trigger,
        formState: { errors },
    } = useForm<TRRoute>({ resolver: yupResolver(schema), defaultValues: detail ?? {} });

    const getRouteDetail = (id: string) => {
        routeApi
            .getById(id)
            .then((res) => {
                setDetail(res);
                reset(res);
            })
            .catch(() => {});
    };

    const onSubmit = () => {
        var data = getValues();
        if (isCreate) {
            routeApi
                .create(data)
                .then(() => {
                    navigate(Routers.trip.route);
                })
                .catch(() => {});
        } else {
            routeApi
                .update({
                    ...data,
                    id: detail?.id,
                })
                .catch(() => {});
        }
    };
    const onDelete = () => {
        detail?.id &&
            routeApi
                .delete(detail?.id)
                .then(() => {
                    navigate(Routers.trip.route);
                })
                .catch(() => {});
    };
    return (
        <Fragment>
            <div>
                {/* @ts-ignore */}
                <form
                    onSubmit={handleSubmit(onSubmit, (e) => {
                        toast.error(lang.text(Translations.error.requiredAllFields));
                    })}
                >
                    <Card>
                        <Card.Header>
                            <div className="flex justify-between">
                                <h5>{lang.text(Translations.trip.routeInformation)}</h5>
                                <div className="flex ">
                                    {!isReadOnly && <SaveButton type="submit" />}
                                    {<DeleteButton showConfirm onConfirmed={onDelete} isRender={userPms.trip.admin && detail != undefined} className="mx-2" type="button" />}
                                    {<UpdateButton isRender={(userPms.trip.admin || userPms.trip.driver) && isReadOnly} onClick={() => setIsReadOnly(false)} className="mx-2" />}
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <InputField
                                    required={true}
                                    placeholder="Nhập tên"
                                    md={4}
                                    xs={12}
                                    name={'displayName'}
                                    control={control}
                                    defaultValue={''}
                                    label={'Display Name'}
                                    readOnly={isReadOnly}
                                />
                                <CompanySelect md={4} xs={12} name="companyId" control={control} required={true} disable={!isCreate} readOnly={isReadOnly} />
                                <LocationField md={4} xs={12} name="fromPlace" readOnly={isReadOnly} label={lang.text(Translations.trip.from)} control={control} required={true} />
                                <LocationField md={4} xs={12} name="toPlace" readOnly={isReadOnly} label={lang.text(Translations.trip.to)} control={control} required={true} />
                            </Row>
                        </Card.Body>
                    </Card>
                    <RestPlacesComponent trigger={trigger} setValue={setValue} canAdd={!isReadOnly} isReadOnly={isReadOnly} name="places" required={false} control={control} />
                    <SafeInformationPlaceComponent trigger={trigger} setValue={setValue} canAdd={!isReadOnly} isReadOnly={isReadOnly} name="safeInformations" required={false} control={control} />
                </form>
            </div>
        </Fragment>
    );
}
