import { SIReportGroupRequest, SIReportGroupResponse } from 'api/v2/safe-inspection/model/siReport';
import { BaseButton } from 'components/Button/BaseButton';
import UserSelectField from 'components/Common/UserSelect';
import DataListView from 'components/shared/DataListView';
import { Accordion, Card, Col, Row } from 'components/ui-kit';
import Color from 'enum/color';
import { SIReportStatus } from 'enum/safetyInspection';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { AccordionTab } from 'primereact/accordion';
import { Control } from 'react-hook-form';
import SIItemContent from './item.component';

const SIGroupContent = ({
    group,
    readonly,
    showPIC,
    control,
    generageName,
    closeGroupScheduled,
}: {
    group: SIReportGroupRequest | SIReportGroupResponse;
    readonly: boolean;
    showPIC: boolean;
    control: Control<any>;
    generageName: (groupId: string, itemId: string, name: string) => string;
    closeGroupScheduled?: (groupId: string) => void;
}) => {
    const renderButtonClose = () => (
        <div className="flex justify-end">
            {(group as SIReportGroupResponse)?.canClose && (
                <BaseButton size="sm" className="mt-2" color={Color.info} onClick={() => closeGroupScheduled && closeGroupScheduled((group as SIReportGroupResponse).id)}>
                    {lang.text(Translations.button.close)}
                </BaseButton>
            )}
        </div>
    );
    // only render for scheduled report
    const generageClosedInformation = () => {
        var groupResponse = group as SIReportGroupResponse;
        return (
            showPIC &&
            groupResponse.status == SIReportStatus.Closed && (
                <Card>
                    <Card.Body>
                        <div>{renderButtonClose()}</div>
                        <DataListView
                            datas={[
                                {
                                    label: lang.text(Translations.common.closedBy),
                                    value: groupResponse.closedUserFullName,
                                },
                                {
                                    label: lang.text(Translations.common.closedDate),
                                    value: groupResponse.closedDate && formatDateTime(groupResponse.closedDate),
                                },
                                {
                                    label: lang.text(Translations.common.closedBy),
                                    value: groupResponse.closedDescription,
                                },
                            ]}
                        />
                    </Card.Body>
                </Card>
            )
        );
    };
    return (
        <Accordion>
            <AccordionTab header={<span className={group.items.some((n) => !n.isOk) ? 'text-danger' : ''}>{group.groupTemplateName}</span>}>
                <Row>
                    {showPIC && !readonly && (
                        <UserSelectField
                            readOnly={false}
                            label={lang.text(Translations.common.pic)}
                            name={generageName(group.groupTemplateId, '', 'pICId')}
                            control={control}
                            required={true}
                            isMutiple={false}
                            md={12}
                        />
                    )}
                    {generageClosedInformation()}
                    {group.items?.map((item) => (
                        <Col md={6} key={item.itemTemplateId} id={item.itemTemplateId}>
                            <SIItemContent
                                generageName={generageName}
                                groupTemplateId={group.groupTemplateId}
                                showPIC={showPIC}
                                readonly={readonly}
                                key={group.groupTemplateId}
                                item={item}
                                control={control}
                            />
                        </Col>
                    ))}
                </Row>
            </AccordionTab>
        </Accordion>
    );
};
export default SIGroupContent;
