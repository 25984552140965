import { defaultProfilePms } from 'api/v2/user/model';
import { selectTokenProfile, setCustomer, setIsLoggin, setTokenProfile } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import StorageKeys from 'constants/storageKeys';
import { parseTokenJwt } from 'helper/app.helper';
import AuthContext from 'providers/contexts/authContext';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function AuthProvider({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch();
    const profile = useAppSelector(selectTokenProfile);

    useEffect(() => {
        handleAuth();
    }, []); // Ensure handleAuth is updated if dependencies change

    const handleAuth = () => {
        const tokenStored = localStorage.getItem(StorageKeys.token);
        const newProfile = parseTokenJwt(tokenStored ?? '');
        if (newProfile?.userId) {
            dispatch(setTokenProfile(newProfile));
            dispatch(setIsLoggin(true));
        } else {
            dispatch(setIsLoggin(false));
            dispatch(setTokenProfile(newProfile));
        }
    };

    const loginSuccess = (accessToken: string) => {
        localStorage.setItem(StorageKeys.token, accessToken);
        handleAuth();
    };

    const logout = () => {
        localStorage.removeItem(StorageKeys.token);
        dispatch(setIsLoggin(false));
        dispatch(setCustomer());
    };

    return (
        <AuthContext.Provider
            value={{
                tokenProfile: profile ?? undefined,
                handleRefesh: handleAuth,
                loginSuccess: loginSuccess,
                logOut: logout,
                userPms: profile?.pms ?? defaultProfilePms(false),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
