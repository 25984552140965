const CommonConstants = {
    imageAccepts: '.jpg,.png',
    DefaultId: 0,
    UnAuthorizationMessage: 'Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!',
};
export const avatarCompanyDefault = '/placeholder.jpg';
export const avataUserDefault = '/avatar.png';

export const MODAL_BOX_CLASSNAME = 'modal-fit-layout';

export default CommonConstants;
