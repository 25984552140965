import { Col } from 'components/ui-kit';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { SelectFieldProps } from '../SelectField';

export interface SelectSearchFieldProps extends SelectFieldProps {
    onSearch: (value: string) => void;
    maxValue?: number;
}
const SelectSearchField = (props: SelectSearchFieldProps) => {
    const {
        field,
        fieldState: { invalid },
    } = useController({
        name: props.name,
        control: props.control,
    });

    const isMutil = Number(props.maxValue ?? 0) > 1;
    console.log(isMutil);
    const [selected, setSelected] = useState<any>();
    // useEffect(() => {
    //     if (props.onValueChange) props.onValueChange(field.value);
    //     props.onSelectedOptionChange && props.onSelectedOptionChange(props.options!.find((option) => option.value === field.value));
    // }, [field.value]);
    return (
        <Col md={props?.md} sm={props?.xs ?? 6}>
            <div className="flex-auto">
                <span className="block">{props?.label} </span>

                {/* <Dropdown
                    className="w-full"
                    name={props.name}
                    emptyFilterMessage={'jlkfjl'}
                    // autoFocus
                    // selectOnFocus
                    filter
                    clearIcon={props.disable && !props.readOnly}
                    filterBy="label"
                    onFilter={(e) => {
                        props.onSearch && props.onSearch(e.filter);
                    }}
                    options={props.options ?? []}
                    optionLabel="label"
                    value={field.value}
                    // onChange={(e) => {
                    //     setSelected(e.value);
                    //     if (isMutil) {
                    //         field.onChange(e.value === undefined ? [] : e.value);
                    //     } else {
                    //         field.onChange(e.value);
                    //     }
                    // }}
                    placeholder="Enter text to search"
                /> */}
            </div>
        </Col>
    );
};

export default SelectSearchField;
