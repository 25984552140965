import { Col, Row } from 'components/ui-kit';
import useMenu from 'hooks/useMenu';
import { lang } from 'i18n/lang';
import React from 'react';
import { FileText } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';

const BoxMenuPage = () => {
    const { userMenus } = useMenu();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isRoot = pathname === '/';

    const renderMenu = (icon: React.ReactNode, name: string, link: string, isRoot: boolean) => {
        return (
            <Col sm={6} md={6} className="box-menu-item-wrapper">
                <div className="box-menu-item" onClick={() => navigate(link)}>
                    <div style={{ height: 30 }}>{icon ? icon : <FileText />}</div>
                    <span className="box-menu-item-name" style={{ color: isRoot ? 'var(--bs-gray-dark)' : 'var(--bs-gray-600)' }}>
                        {name ? lang.text(name) : 'No title'}
                    </span>
                </div>
            </Col>
        );
    };

    return (
        <div className="pb-5">
            {isRoot ? (
                <Row className="box-layout-menus">
                    {userMenus.map((menu) => {
                        return renderMenu(menu.groupIcon, menu.groupName, menu.path ?? menu.key, true);
                    })}
                </Row>
            ) : (
                <div>
                    <Row className="box-layout-menus">
                        {userMenus
                            ?.find((menu) => menu.key === pathname)
                            ?.items.map((item) => {
                                return renderMenu(item.icon, item.title, item.path, false);
                            })}
                    </Row>
                </div>
            )}
        </div>
    );
};

export default BoxMenuPage;
