import { Fragment, useEffect, useState } from 'react';
import { CheckSquare, ChevronDown, ChevronRight, DashSquare, NodeMinus, PlusSquare, Square } from 'react-bootstrap-icons';
import CheckboxTree, { OnCheckNode } from 'react-checkbox-tree';
import { Control, useController } from 'react-hook-form';

export interface TreeNode {
    value: string;
    label: string;
    children?: TreeNode[];
}
interface CheckboxTreeComponentProps {
    name: string;
    options: TreeNode[];
    onValuesChanged?: (checkedChange: Array<string>) => void;
    control: Control<any>;
    isUserForm: boolean; // if use form == true, checked values is value of form, else value is checked property
    checkeds?: string[];
}
export default function CheckboxTreeComponent(props: CheckboxTreeComponentProps) {
    const [expanded, setExpanded] = useState<string[]>([]);

    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name: props.name,
        control: props.control,
    });
    const onCheckedTempChanged = (checkedChange: Array<string>, node: OnCheckNode) => {
        props.onValuesChanged && props.onValuesChanged(checkedChange);
        onChange(checkedChange);
    };
    useEffect(() => {
        console.log('value nè', props.name, value, props.isUserForm, props.options);
    }, [value]);
    return (
        <Fragment>
            <CheckboxTree
                icons={{
                    check: <CheckSquare className="text-primary bold" />,
                    uncheck: <Square className="text-primary bold" />,
                    halfCheck: <DashSquare className="text-primary bold" />,
                    expandClose: <ChevronRight />,
                    expandOpen: <ChevronDown />,
                    expandAll: <PlusSquare className="text-primary" />,
                    collapseAll: <NodeMinus className="text-primary" />,
                    parentClose: <></>,
                    parentOpen: <></>,
                    leaf: <></>,
                }}
                nodes={props.options}
                checked={props.isUserForm ? value : props.checkeds ?? []}
                expanded={expanded}
                onCheck={onCheckedTempChanged}
                onExpand={setExpanded}
            />
        </Fragment>
    );
}
