import { AddButton, SaveButton } from 'components/Button';
import useModalHook from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { ColumnType } from 'components/shared/PaginationTable';
import { Card } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { Control, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { ResponsiveTable } from 'components/Table/ResponsiveTable';
import { FieldValues } from 'react-hook-form';
import { IIRReportLocalRequest } from './../iirReportCreationPage';

export interface IIRReportItemBaseComponentProps<T = any> {
    title: string;
    datas: T[];
    columns: ColumnType[];
    isReadOnly?: boolean;
    formComponent?: (data: T) => JSX.Element;
    formComponentControl?: UseFormReturn<any, any, undefined>;
    control?: Control<any>;
    isTextField?: boolean;
    arrayForm?: UseFieldArrayReturn<IIRReportLocalRequest, any, 'id'>;
}

export interface IIRListComponentProps<T extends FieldValues = any> {
    arrayForm?: UseFieldArrayReturn<IIRReportLocalRequest, any, 'id'>;
    isReadOnly?: boolean;
    datas: T[];
}

const IIRReportItemBaseComponent = <T,>({ isTextField, columns, datas, title, isReadOnly, formComponent, formComponentControl, arrayForm }: IIRReportItemBaseComponentProps<T>) => {
    const { openModal, Prompt, onCompleted, onClose } = useModalHook();
    const { getValues, trigger, reset } = formComponentControl || {};
    const [datasAction, setDatasAction] = useState<T[]>(datas);

    useEffect(() => {
        let newDt = isReadOnly
            ? datas
            : datas?.map((n, index) => ({
                  ...n,
                  action: (
                      <ActionButtons2
                          id={String(index)}
                          isConfirmDelete={false}
                          deletePms={true}
                          editAction={() => openModalForm({ data: n, index })}
                          editPms={true}
                          onDelete={() => {
                              arrayForm && arrayForm.remove(index);
                              return Promise.resolve();
                          }}
                      />
                  ),
              }));
        setDatasAction(newDt);
    }, [datas]);

    const openModalForm = ({ data, index }: { data?: T; index?: number }) => {
        reset?.(data);
        openModal({
            isFullScreen: '',
            title: lang.text(index ? Translations.common.edit : Translations.common.addNew),
            body: (
                <form>
                    <Card.Body>
                        {formComponent?.(data!)!}
                        <div className="flex justify-end pb-2">
                            <SaveButton type="button" onClick={() => onSubmit(data, index)} />
                        </div>
                    </Card.Body>
                </form>
            ),
        });
    };

    const onSubmit = async (data?: T, index?: number) => {
        const isValid = await trigger?.();
        if (isValid) {
            const formData = getValues?.();
            if (index) {
                arrayForm?.update(index, isTextField ? formData.name : formData);
            } else {
                arrayForm?.append(isTextField ? { name: formData.name } : formData);
            }
            onClose && onClose();
        } else {
            console.log('Validation failed');
        }
    };

    return (
        <Card>
            <Card.Header>
                <div className="flex justify-between">
                    <div className="flex items-center">
                        <h6 className="mr-1 mb-0 text-primary">{title}</h6>
                        <div>
                            <span className="btn btn-sm btn-icon btn-soft-primary text-primary rounded-pill">{datas?.length ?? 0}</span>
                        </div>
                    </div>
                    <AddButton
                        isRender={!isReadOnly}
                        type="button"
                        onClick={() => {
                            reset?.({});
                            openModalForm({});
                        }}
                    />
                </div>
            </Card.Header>
            <Card.Body>
                {/* Table body */}
                <ResponsiveTable isWrapByCard={false} columns={columns} datas={datasAction ?? []} isStriped={true} />
            </Card.Body>
            {Prompt}
        </Card>
    );
};

export default IIRReportItemBaseComponent;
