import { memo, useEffect, useState } from 'react';

//react-bootstrap

//router
import { useLocation, useNavigate } from 'react-router-dom';

//component

//img

// logo

// Redux Selector / Action

// Import selectors & action from setting store

import useMenu from 'hooks/useMenu';
import { findRouteByCurrentPath } from 'router/const/pageInfos';
import { menusBoxGroup } from 'router/menuRouters';

const BoxLayoutHeader = memo(() => {
    const { userMenus } = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const [titlePage, setTitlePage] = useState('');

    // set hide menu vertical sizebar if is mobile
    useEffect(() => {
        let groupMenu = menusBoxGroup.find((menu) => menu.path === location.pathname);
        if (groupMenu) {
            setTitlePage(groupMenu.name);
        } else {
            setTitlePage(findRouteByCurrentPath()?.title ?? '');
        }
    }, [location.pathname]);

    return (
        <>Working</>
        // <Navbar expand="xl" className={`nav iq-navbar header-hover-menu py-0 my-0 left-border rounded-bottom`}>
        //     <Container className="navbar-inner px-2 py-2">
        //         {location.pathname !== '/' ? (
        //             <div className="flex gap-2 items-center">
        //                 <BaseButton isRender text=" " className="" icon={<ArrowLeft />} onClick={() => navigate(-1)} />
        //             </div>
        //         ) : (
        //             <MobileSidebar />
        //         )}

        //         <div className="flex items-center text-white font-bold">{lang.text(titlePage)}</div>
        //         <UserProfileActions />
        //         {/* <div className="flex gap-2 items-center">
        //                 <BaseButton isRender text="Quay lại" icon={<ArrowLeft />} onClick={() => navigate(Routers.root)} />
        //                 <h5 className="menu-group-name">{lang.text(userMenus?.find((menu) => menu.key === pathname)?.groupName || 'No title')}</h5>
        //             </div> */}
        //     </Container>
        // </Navbar>
    );
});

BoxLayoutHeader.displayName = 'Header';
export default BoxLayoutHeader;
