import Card from 'components/ui-kit/primereact/Card';
import { useEffect, useMemo, useState } from 'react';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import kpiTypeApi from 'api/v2/kpi/kpiType';
import { KPITypeCreateRequest, KPITypeResponse } from 'api/v2/kpi/model/kpiType.model';
import { AddButton } from 'components/Button';
import { CompanySelect } from 'components/Common';
import useModalHook from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import KPITypeForm from './formCrud';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const KPITypeListPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);

    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var comid = getValues().companyId;
        toast.error(comid?.hasValue());
        kpiTypeApi
            .getPagination({
                ...pagination,
                companyId: comid?.hasValue() ? comid : null,
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                action: <ActionButtons2 id={n.id} onDelete={onDelete} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        var request: KPITypeCreateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            modalSize: 'sm',
            body: <KPITypeForm name="" companyIds={[]} onClose={onClose} onCompleted={onCompleted} />, // <SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        request &&
            kpiTypeApi
                .create(request)
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };

    const onUpdate = async (data: KPITypeResponse) => {
        var request: KPITypeCreateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <KPITypeForm name={data.name} companyIds={data.companyIds} onClose={onClose} onCompleted={onCompleted} />, // <SORCategoryForm description="" name="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        request &&
            kpiTypeApi
                .update({ ...request, id: data.id })
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };

    const onDelete = async (id: string) => {
        kpiTypeApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };

    const { control, getValues, handleSubmit } = useForm();

    return (
        <Card>
            <Card.Body>
                <Row>
                    <CompanySelect
                        md={4}
                        xs={6}
                        name="companyId"
                        control={control}
                        required={false}
                        disable={false}
                        readOnly={false}
                        onCompanyChange={() => handleFetchData(defaultPaginationRequest)}
                    />
                    <Col md={4} sm={6}>
                        <div className="flex justify-end">
                            <div></div>
                            <AddButton isRender={true} onClick={onAdd} />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
            {/* <KPITypeForm name="" companyIds={[]} onClose={onClose} onCompleted={onCompleted} /> */}
            <PaginationTable
                onSearch={handleFetchData}
                onPaginate={handleFetchData}
                onRowClick={(item) => {}}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={true}
                wrapperClass="mt-5"
            />
            {Prompt}
        </Card>
    );
};

export default KPITypeListPage;
