import * as React from 'react';
import { useController } from 'react-hook-form';
import { BaseFormControlProps } from '../baseFormProps';
import { Col, InputText } from 'components/ui-kit';
import { Messages } from 'primereact/messages';

export interface InputFieldProps extends BaseFormControlProps {
    placeholder?: string;
    type?: string | 'number' | 'text' | 'password' | 'email' | 'date';
    onValueChange?: (value: string) => void;
    min?: number;
    max?: number;
    className?: string;
    prefixText?: string;
    suffixText?: string;
}

export function InputField({
    name,
    control,
    label,
    placeholder,
    type,
    disabled = false,
    defaultValue,
    required = false,
    md,
    xs = 6,
    readOnly,
    min,
    max,
    className,
    onValueChange,
    prefixText,
    suffixText,
}: InputFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
        rules: required ? { required: 'This field is required' } : {},
    });
    React.useEffect(() => {
        if (defaultValue !== value) onChange(defaultValue);
    }, [defaultValue]);

    React.useEffect(() => {
        if (onValueChange) onValueChange(value);
    }, [value]);

    return (
        <Col md={md} sm={xs ?? 6} className={className}>
            <div className="flex-auto">
                <span className="block">{label}</span>
                <InputText
                    prefix={prefixText}
                    required={required}
                    type={type}
                    min={min}
                    max={max}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    disabled={disabled}
                    invalid={invalid}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    className="w-full"
                />
                {invalid && <div className="invalid-feedback  d-block ">{error?.message}</div>}
            </div>
        </Col>
    );
}
