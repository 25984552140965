import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { SettingState } from './interface';
import { defaultState, initialState } from './state';
const DefaultSetting = defaultState.setting

const Choices = {
  SchemeChoice: DefaultSetting.theme_scheme.choices,
  ColorChoice: DefaultSetting.theme_color.choices,
  StyleAppearanceChoice: DefaultSetting.theme_style_appearance.choices,
  CardStyle: DefaultSetting.card_style.choices,
  FSChoice: DefaultSetting.theme_font_size.choices,
  Animation: DefaultSetting.theme_transition.choices,
}

const createSettingObj = (state: SettingState) => {
  return {
    saveLocal: state.saveLocal,
    storeKey: state.storeKey,
    setting: _.cloneDeep(state.setting)
  }
}

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setSetting: (state: SettingState) => {
      
    },
    reset_state: (state, action) => {
      state.setting = defaultState.setting
    },
    saveLocal: (state, action) => {
      if (typeof action.payload !== typeof undefined) {
        state.saveLocal = action.payload
      }
      const settingObj = {
        saveLocal: state.saveLocal,
        storeKey: state.storeKey,
        setting: _.cloneDeep(state.setting)
      }
    },
    app_name: (state, action) => {
      if (typeof action.payload !== typeof undefined) {
        state.setting.app_name.value = action.payload
      }
    },
  },
});

export default settingSlice.reducer;
