import equipmentApi from 'api/v2/equipmentApi';
import { SelectFieldProps, SelectOption } from 'components/FormControl';
import SelectSearchField from 'components/FormControl/SelectSearchField';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

export interface EquipmentSelectFieldProps extends SelectFieldProps {
    companyId?: string;
}

export const EquipmentSelectField: React.FC<EquipmentSelectFieldProps> = (props: EquipmentSelectFieldProps) => {
    const [equipments, setEquipments] = useState<SelectOption[]>([]);
    const loadEquipments = async (keySearch?: string) => {
        equipmentApi
            .getPagination({ rowsPerPage: 300, currentPage: 1, companyId: props?.companyId, keySearch: keySearch })
            .then((e: any) => {
                setEquipments(e?.datas?.map((d: any) => ({ value: d?.id, label: [d?.companyName || '', d?.equipmentCode || ''].join(' - ') })));
            })
            .catch((n) => {});
    };
    const {
        field,
        fieldState: { error, invalid },
    } = useController({
        name: props?.name,
        control: props.control,
    });

    useEffect(() => {
        loadEquipments();
    }, []);

    useEffect(() => {
        loadEquipments();
    }, [props.companyId]);
    return (
        <SelectSearchField
            {...props}
            onSearch={(value) => {
                loadEquipments(value);
            }}
            label={lang.text(Translations.equipment.equipment)}
            options={equipments || []}
            name={props.name}
            displayProp="label"
        />
    );
};
export default EquipmentSelectField;
