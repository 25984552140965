import { PaginationBase } from 'api/models/common';
import { Card, Column, Table, TableColumn } from 'components/ui-kit';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface ResponsiveTableProps {
    isWrapByCard?: boolean;
    emptyLayout?: any;
    columns: TableColumn[];
    isStriped?: boolean;
    wrapperClass?: string;
    onRowClick?: (row: any) => void;
    datas: any[];
    pagination?: PaginationBase<any>; // sỬ DỤNG RENDER STT (#) TRONG TABLE, NẾU CÓ PAGINATION
}

export function ResponsiveTable({ columns, isStriped = false, wrapperClass = '', onRowClick, datas, pagination, emptyLayout, isWrapByCard = true }: ResponsiveTableProps) {
    const [columnStates, setColumnStates] = useState<TableColumn[]>(columns);
    const isDisplayBox = false;

    useEffect(() => {
        var lastColumn = columns[columns.length - 1];
        if (isDisplayBox && lastColumn.field === 'action') {
            let newCols: TableColumn[] = [lastColumn, ...columns.slice(0, columns.length - 1)];
            setColumnStates(newCols);
        }
    }, [columns]);

    const loadMobileLayout = () => {
        // return datas?.map((item, index) => {
        //     return <BoxCardItem data={item} fields={columnStates.map((x) => ({ propertyName: x.field, displayName: x.title }))} />;
        // });
        return <>Mobile table</>;
    };

    const loadTableLayout = () => {
        return (
            <div className={`fancy-table table-responsive rounded ${wrapperClass}`}>
                <Table value={datas} stripedRows={isStriped} onRowClick={onRowClick}>
                    {columnStates.map((col, colIndex) => (
                        <Column key={colIndex} field={col.field} header={col?.title} />
                    ))}
                </Table>
            </div>
        );
    };
    const renderWrapByCardPc = () =>
        isWrapByCard ? (
            <Card>
                <Card.Body>{loadTableLayout()}</Card.Body>
            </Card>
        ) : (
            loadTableLayout()
        );

    toast.success(isWrapByCard);
    const renderMainContent = () => <div>{datas && isDisplayBox ? loadMobileLayout() : renderWrapByCardPc()}</div>;
    if (datas && datas.length > 0) return renderMainContent();
    else return <Fragment>{emptyLayout && emptyLayout}</Fragment>;
}
