import { Fragment, useState } from 'react';

import { InputField, TextAreaField } from 'components/FormControl';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { RAControlMeasuresCreateRequest, RACreateRequest } from 'api/v2/risk-assessment/model/raRequest.model';
import raApi from 'api/v2/risk-assessment/raApi';
import { AddButton, SaveButton } from 'components/Button';
import { AreaSelect, CompanySelect } from 'components/Common';
import UserSelectField from 'components/Common/UserSelect';
import RiskLevelSelect from 'components/RiskLevelSelect';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { Accordion, Col, Row } from 'components/ui-kit';
import { EntityConst } from 'constants/EntityConst';
import { lang } from 'i18n/lang';
import { AccordionTab } from 'primereact/accordion';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import RATagSelect from './tagSelect';

const RACreatePage = () => {
    const { t } = useTranslation();

    const [companyId, setCompanyId] = useState<string>('');
    const [steps, setSteps] = useState<RAControlMeasuresCreateRequest[]>([]);

    const schema = yup.object().shape({
        areaId: yup.string().notRequired().nullable(),
        companyId: yup.string().required(),
        tagIdsRequest: yup.array().of(yup.string().required()),
        rAName: yup.string().required().max(EntityConst.shortString),
        steps: yup.array().notRequired(),
    });
    const {
        control,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const schemaStep = yup.object().shape({
        stepNumber: yup.number().required(),
        name: yup.string().required().max(EntityConst.short),
        content: yup.string().required().max(EntityConst.descriptionLong),
        pICId: yup.string().required(),
        levelId: yup.string().required(),
        hazards: yup.string().required().max(EntityConst.descriptionLong),
        tagIds: yup.array().of(yup.string().required()),
    });
    const {
        reset: resetStep,
        control: controlStep,
        getValues: getValuesStep,
        handleSubmit: handleSubmitStep,
        formState: { errors: errorsStep },
    } = useForm<RAControlMeasuresCreateRequest>({ resolver: yupResolver(schemaStep) });

    const SaveChangeAddStep = () => {
        if (steps.find((item) => item.stepNumber === getValuesStep().stepNumber)) {
            toast.error('Step number is exist');
            return;
        }
        var form = getValuesStep();
        var item: RAControlMeasuresCreateRequest = {
            ...form,
        };
        let newSteps = [...steps, item];
        setSteps(newSteps);
        resetStep({ stepNumber: parseInt(item.stepNumber.toString()) + 1, name: '', content: '', pICId: '', levelId: '', hazards: '' });
    };
    const SaveChange = () => {
        if (!steps.length || steps.length === 0) {
            toast.error('Nhập ít nhất một biện pháp kiểm soát');
            return;
        }
        // if ( confirm('Bạn có chắc chắn muốn tạo báo cáo?') === false) return;
        var form = getValues();
        var payload: RACreateRequest = {
            ...form,
            steps: steps,
        };
        raApi
            .create(payload)
            .then((res) => {
                console.log(res);
            })
            .catch(() => {});
    };
    const resetFormStep = (obj: RAControlMeasuresCreateRequest) => {
        resetStep(obj);
    };
    const editCurrentStep = (obj: RAControlMeasuresCreateRequest) => {
        var newSteps = steps.filter((item) => item.stepNumber !== obj.stepNumber);
        resetStep(obj);
        setSteps(newSteps);
    };

    const removeStep = (obj: RAControlMeasuresCreateRequest) => {
        var newSteps = steps.filter((item) => item.stepNumber !== obj.stepNumber);
        setSteps(newSteps);
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(SaveChange)}>
                <Card>
                    <Card.Header className="flex justify-between">
                        <h4 className="p-card-title  ">
                            <span>Tạo báo cáo</span>
                        </h4>
                        <SaveButton />
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <InputField md={4} xs={12} name={'rAName'} control={control} defaultValue={''} label={lang.text(Translations.common.name)} readOnly={false} />
                            <CompanySelect md={4} xs={12} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <AreaSelect md={4} xs={12} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                            <RATagSelect isCanCreate isMulti={true} control={control} name="tagIds" xs={12} md={4} />
                        </Row>
                    </Card.Body>
                </Card>
            </form>

            <Row>
                <Col md={6}>
                    <Card>
                        <Card.Header className="flex justify-between">
                            <h5 className="p-card-title  ">
                                {lang.text(Translations.common.addNew)} {lang.text(Translations.ra.controlMeasure).toLocaleLowerCase()}
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            <form onSubmit={handleSubmitStep(SaveChangeAddStep)}>
                                <Row>
                                    <InputField label="#" md={2} min={1} max={100} name="stepNumber" type="number" control={controlStep} required defaultValue={0} />
                                    <InputField md={10} xs={12} label={lang.text(Translations.ra.actionName)} name="name" control={controlStep} required />
                                    <TextAreaField md={12} xs={12} label={lang.text(Translations.ra.hazard)} name="hazards" control={controlStep} required />
                                    <RiskLevelSelect md={4} xs={12} name="levelId" control={controlStep} required={true} readOnly={false} />
                                    <UserSelectField readOnly={false} label={t(Translations.common.pic)} name="pICId" control={controlStep} required={true} md={4} xs={12} />
                                    <TextAreaField md={12} xs={12} label={lang.text(Translations.ra.controlMeasure)} name="content" required control={controlStep} />
                                    <div className="flex justify-end">
                                        <AddButton isRender={true} type="submit" text={lang.text(Translations.common.addNew)} />
                                    </div>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header className="flex justify-between">
                            <h5 className="p-card-title  ">{lang.text(Translations.ra.controlMeasure)}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Accordion>
                                    {steps.map((item, index) => (
                                        <AccordionTab
                                            header={
                                                <div className="flex w-full justify-between px-2">
                                                    <span>
                                                        # {item.stepNumber} {item.name}
                                                    </span>
                                                    <ActionButtons2
                                                        id={item.stepNumber.toString()}
                                                        onDelete={() => {
                                                            removeStep(item);
                                                            return Promise.resolve();
                                                        }}
                                                        editAction={() => editCurrentStep(item)}
                                                    />
                                                </div>
                                            }
                                        >
                                            {[
                                                { label: lang.text(Translations.common.pic), value: item.pICId },
                                                { label: lang.text(Translations.common.riskLevel), value: item.levelId },
                                                { label: lang.text(Translations.ra.hazard), value: item.hazards },
                                                { label: lang.text(Translations.ra.controlMeasure), value: item.content },
                                            ].map((item, index) => (
                                                <div key={index} className="flex">
                                                    <b style={{ minWidth: '25%' }}>{item.label} </b>
                                                    <span style={{ minWidth: '75%' }}>{item.value}</span>
                                                    <hr />
                                                </div>
                                            ))}
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RACreatePage;
