import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { RAFilterRequest } from 'api/v2/risk-assessment/model/raRequest.model';
import { RAResponse } from 'api/v2/risk-assessment/model/rs.Response.model';
import raApi from 'api/v2/risk-assessment/raApi';
import { FilterButton } from 'components/Button/FilterButton';
import { AreaSelect, CompanySelect } from 'components/Common';
import UserSelectField from 'components/Common/UserSelect';
import DateTimeField from 'components/FormControl/DateTimeField';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import RiskLevelSelect from 'components/RiskLevelSelect';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row } from 'components/ui-kit';
import { formatDateTime } from 'helper';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RADetailPage from './raDetailtPage';
import RATagSelect from './tagSelect';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const RAListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'rAName',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'companyName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'areaName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    // const reportStatus = useMemo(() => SORReportHelper.getReportStatusOptions(), []);
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);

    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<RAResponse>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const { control, handleSubmit, getValues } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        setCurrentDetailId(id);
    }, []);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as RAFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        raApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                // status: <Badge color={SORReportHelper.getStatusColor(n.status)}>{n.status}</Badge>,
                                // reportType: <Badge color={SORReportHelper.getReportTypeColor(n.isSafe)}>{SORReportHelper.getReportTypeText(n.isSafe)}</Badge>,
                                action: <ActionButtons2 id={n.id} onViewDetail={() => setCurrentDetailId(n.id)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <form className="w-full" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <Row>
                                <CompanySelect md={4} xs={12} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                                <AreaSelect md={4} xs={12} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                                <RiskLevelSelect name="riskLevelId" md={4} xs={12} control={control} required={false} readOnly={false} />
                                <UserSelectField readOnly={false} label={t(Translations.common.pic)} name="pICId" control={control} required={false} md={4} xs={12} />
                                <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} xs={12} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                                <DateTimeField label={lang.text(Translations.common.toDate)} md={4} xs={12} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />{' '}
                                <RATagSelect md={4} xs={12} isMulti={true} control={control} name="tagIds" />
                                <Col md={12}>
                                    <div className="flex justify-end">
                                        <FilterButton type="submit" className="mx-2 mt-3" />
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Card.Body>
                </Card>
                <PaginationTable
                    // onSearch={handleFetchData}
                    onPaginate={handleFetchData}
                    columns={cols}
                    pagination={paginationData}
                    isStriped={true}
                    disableSearch={true}
                    wrapperClass="mt-5"
                />
                <ModalFullScreen
                    title={lang.text(Translations.common.reportDetail)}
                    bodyModal={
                        <RADetailPage
                            id={currentDetailId}
                            onDeleted={() => {
                                setCurrentDetailId(undefined);
                                handleFetchData(defaultPaginationRequest);
                            }}
                        />
                    }
                    showModal={currentDetailId?.hasValue() ?? false}
                    handleCloseModal={() => {
                        setCurrentDetailId(undefined);
                    }}
                />
            </div>
        </Fragment>
    );
};

export default RAListPage;
