import { memo, useEffect, useState } from 'react';

//router
import { Link } from 'react-router-dom';

//component

//img

// logo
import { Logo } from 'components/shared';

// Redux Selector / Action

// Import selectors & action from setting store

import { ArrowRight } from 'assets/icons/outlined';
import { hidenMiniSideBar, minisidebar } from 'components/builder/actions';
import { isMobileDevice } from 'helper';
import { lang } from 'i18n/lang';
import UserProfileActions from 'layouts/component/userProfileActions';
import { findRouteByCurrentPath } from 'router/const/pageInfos';
import Routers from 'router/const/routers';
import { Button } from 'components/ui-kit';

const DefaultLayoutHeader = memo(() => {
    useEffect(() => {
        if (isMobileDevice()) {
            hidenMiniSideBar();
        }
    }, []);

    useEffect(() => {
        //offcanvase code
        const result = window.matchMedia('(max-width: 1200px)');
        window.addEventListener('resize', () => {
            if (result.matches === true) {
                if (show1 === true) {
                    document.documentElement.style.setProperty('overflow', 'hidden');
                } else {
                    document.documentElement.style.removeProperty('overflow');
                }
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        });
        if (window.innerWidth <= 1200) {
            if (show1 === true) {
                document.documentElement.style.setProperty('overflow', 'hidden');
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        } else {
            document.documentElement.style.removeProperty('overflow');
        }
    });
    const [show1, setShow1] = useState(false);

    // set current page name
    const pageInfo = findRouteByCurrentPath();

    return (
        <div className="flex justify-between fixed top-0 w-full md:w-[calc(100vw_-_280px)] right-0 px-4 bg-white py-2 md:py-4">
            <div className="breadcrumb-title border-end mr-1 pr-1 hidden md:block">
                <small className="mb-0 text-capitalize text-primary">
                    {lang.text(pageInfo?.groupName ?? '')} {pageInfo?.groupName ? ' > ' : ''}
                </small>
                <Link to={pageInfo?.path ?? '#'}>
                    <small className="mb-0 text-capitalize">{lang.text(pageInfo?.title ?? '')}</small>
                </Link>
                <div className={`offcanvas-backdrop fade  ${show1 === true ? 'show d-block' : 'hidden'}`} onClick={() => setShow1(false)}></div>
            </div>
            <div className="block md:hidden"></div>
            <div className="flex gap-4 justify-center items-center">
                <UserProfileActions />
            </div>
        </div>
    );
});

export default DefaultLayoutHeader;
