import { QRBaseResponse } from 'api/v2/common/model/qrBaseResponse';
import { SECRegisterDetailResponse, SECReportRequest } from 'api/v2/sec/model/register.model';
import secRegisterApi from 'api/v2/sec/secRegisterApi';
import secReportApi from 'api/v2/sec/secReportApi';
import { BaseButton } from 'components/Button/BaseButton';
import useModalHook from 'components/ModalHook';
import { QRType } from 'enum/qrType';
import { SECCheckInOutType } from 'enum/sec';
import { decodeBase64, isMobileDevice } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import React, { Fragment } from 'react';
import { QrCodeScan } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import CheckInOutModalModal from './checkInOrOut.component';
import ScanQRComponent from './scanQR.component';

interface ScanQRButtonComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    //extends ModalHookComponentProps
    rootRegister?: SECRegisterDetailResponse | null | undefined;
    // code?: string | null | undefined;
    registerId?: string | null | undefined;
    type: SECCheckInOutType;
}
const ScanQRButtonComponent = (props: ScanQRButtonComponentProps) => {
    // const [register, setRegister] = useState<SECRegisterDetailResponse | null>(null);
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    const isValidQR = (qr: QRBaseResponse) => {
        if (qr && qr.code && (qr.qrType == QRType.EQUIPMENT || qr.qrType == QRType.USER)) {
            return true;
        }
        return false;
    };
    const onScanQRCheckInOut = async (type: SECCheckInOutType) => {
        try {
            if (!isMobileDevice()) {
                toast.error('Chức năng chỉ hỗ trợ trên thiết bị di động');
                return;
            }
            var base64 = await openModal({
                title: type,
                body: <ScanQRComponent onClose={onClose} onCompleted={onCompleted} />,
            });
            //  let base64 = 'eyJpZCI6ImFiYTRiMGRhLWIxMTktNGU4NS01MTZiLTA4ZGM5ZTVmNDJjZiIsInFyVHlwZSI6IlVTRVIiLCJjb2RlIjoiMjQ1Mjc4MzM5In0=';
            if (base64) {
                let json = decodeBase64(base64);
                var data: QRBaseResponse = JSON.parse(json);
                if (isValidQR(data)) {
                    loadRegister(data.code)
                        .then((res) => {
                            // setRegister(res);
                            if (data.code == res.objectCode) {
                                checkInOutCheckOut(type, res);
                            } else {
                                toast.error('QR code khác với đối tượng hiện tại' + data.code + ' - ' + res.objectCode);
                            }
                        })
                        .catch((n) => {});
                } else toast.error('QR code không hợp lệ');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkInOutCheckOut = async (type: SECCheckInOutType, currentRegister: SECRegisterDetailResponse) => {
        if (currentRegister == null) return;
        var rs = await openModal({
            title: type,
            body: <CheckInOutModalModal organizationId={currentRegister.organizationId} onClose={onClose} onCompleted={onCompleted} />,
        });
        if (rs) {
            let payload: SECReportRequest = {
                registerId: currentRegister.id,
                isApproved: rs.isApproved ? true : false,
                gateId: rs.gateId,
                reason: rs.reason,
                imagePath: rs.imagePath,
                type: type,
                timeOn: new Date(),
            };
            secReportApi
                .create(payload)
                .then((res) => {
                    // loadRegister(currentRegister.objectCode);
                })
                .catch((n) => {});
        }
    };

    const loadRegister = (code: string) => {
        if (props.rootRegister) {
            return Promise.resolve(props.rootRegister);
        } else if (props.registerId) {
            return secRegisterApi.getById(props.registerId);
        } else if (code) {
            return secRegisterApi.findByCode(code, new Date());
        }

        // console.log('vao load 111 ', register);
        // if (!register) {
        //     if (props.registerId) {
        //         console.log('vao load 1 ', register);
        //         return secRegisterApi.getById(props.registerId);
        //     } else if (code) {
        //         console.log('vao load 2 ', register);
        //         return secRegisterApi.findByCode(code, new Date());
        //     }
        // } else {
        //     return Promise.resolve(reg);
        // }

        return Promise.reject('Không tìm thấy thông tin đăng ký');
    };
    return (
        <Fragment>
            <BaseButton
                size="sm"
                color={props.type == SECCheckInOutType.CHECK_IN ? 'success' : 'warning'}
                onClick={() => {
                    onScanQRCheckInOut(props.type);
                }}
                className={props.className}
            >
                <QrCodeScan /> {props.type == SECCheckInOutType.CHECK_IN ? lang.text(Translations.sec.scanQRCodeCheckIn) : lang.text(Translations.sec.scanQRCodeCheckOut)}
            </BaseButton>
            {Prompt}
        </Fragment>
    );
};
export default ScanQRButtonComponent;
