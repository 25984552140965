import { Col, MultiSelect } from 'components/ui-kit';
import { SelectFieldProps } from '../SelectField';
import { useController } from 'react-hook-form';
import { useEffect } from 'react';
export interface MutilSelectFieldProps extends SelectFieldProps {}
const MutilSelectField = (props: MutilSelectFieldProps) => {
    const {
        field,
        fieldState: { invalid, error },
    } = useController({
        name: props.name,
        control: props.control,
    });

    useEffect(() => {
        if (props.onValueChange) props.onValueChange(field.value);
        props.onSelectedOptionChange && props.onSelectedOptionChange(props.options!.find((option) => option.value == field.value));
    }, [field.value]);
    return (
        <Col md={props.md} sm={props.xs ?? 6}>
            <div className="flex-auto">
                <label className="block">
                    {props.label} {props.required && <span className="text-danger">*</span>}
                </label>
                <MultiSelect
                    showClear={!props.readOnly && !props.disable}
                    value={field.value}
                    onChange={(e) => {
                        field.onChange(e.value === undefined ? [] : e.value);
                    }}
                    options={props.options ?? []}
                    optionLabel="label"
                    placeholder={'-- select --'}
                    maxSelectedLabels={100}
                    className="w-full "
                    display="chip"
                    invalid={invalid}
                />
                {invalid && <div className="invalid-feedback  d-block ">{error?.message}</div>}
            </div>
        </Col>
    );
};

export default MutilSelectField;
