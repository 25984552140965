import Translations from 'i18n/translation';
import React, { useState } from 'react';
import { PencilSquare } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseButton } from 'components/Button/BaseButton';
import { Button } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import { confirmDialog } from 'primereact/confirmdialog';
import { useAuth } from 'providers/contexts';
// import { ThreeDots } from 'assets/icons/solid';
type ActionType = 'delete' | 'edit';

export const ActionButtons2 = ({
    id,
    editAction,
    onDelete,
    onDeleteSuccess,
    // viewAction,
    customActions,
    editPms,
    deletePms,
    viewPms,
    onViewDetail,
    isConfirmDelete = true,
}: {
    id: string;
    editPms?: boolean;
    viewPms?: boolean;
    deletePms?: boolean;
    editAction?: (id: string) => void;
    // viewAction?: (id: string) => void;
    onDeleteSuccess?: (id: string) => void;
    onViewDetail?: (id: string) => void;
    onDelete?: (id: string) => Promise<any>;
    isConfirmDelete?: boolean;
    customActions?: React.ReactNode;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { tokenProfile } = useAuth();
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleSelectAction = async (type: ActionType, id: string) => {
        if (type == 'edit' && editAction) {
            editAction(id);
        }
    };

    const deleteItem = async () => {
        if (onDelete) {
            setOpenConfirm(false);
            await onDelete(id).then((n) => {
                if (onDeleteSuccess) {
                    onDeleteSuccess(id);
                } else {
                    // setTimeout(() => {
                    //     navigate(0);
                    // }, 2000);
                }
            });
        }
    };

    return (
        <div className="flex justify-end items-center gap-1">
            {customActions}
            {/* {viewAction && viewAction && (
                <Button variant="icon btn-sm btn-info rounded" onClick={() => viewAction(id)}>
                    <span className="btn-inner">
                        <Eye size={20} />
                    </span>
                </Button>
            )} */}
            {editAction && editPms && (
                <Button color="info" icon={'pi pi-pencil'} onClick={() => handleSelectAction('edit', id)}>
                    <PencilSquare />
                </Button>
            )}

            {onDelete && deletePms && (
                <Button
                    icon="pi pi-Trash"
                    onClick={() => {
                        if (!isConfirmDelete) {
                            deleteItem();
                        } else {
                            confirmDialog({
                                message: lang.text(Translations.modal.confirm.confirmDelete),
                                header: 'Delete Confirmation',
                                icon: 'pi pi-info-circle',
                                defaultFocus: 'reject',
                                acceptClassName: 'p-button-danger',
                                accept: deleteItem,
                                reject: () => {},
                            });
                        }
                    }}
                    color="danger"
                    size="small"
                ></Button>
            )}
            {onViewDetail && <BaseButton icon="pi pi-eye" onClick={() => onViewDetail(id)}></BaseButton>}
        </div>
    );
};
