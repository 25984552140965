import { AddButton, DeleteButton } from 'components/Button';
import { BaseFormValidationProps } from 'components/Common/BaseFormValidationProps';
import { LocationField } from 'components/FormControl/LocationField';
import { Card, Col, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { FieldValues, Path, useController, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
interface RestPlacesComponentProps<T extends FieldValues> extends BaseFormValidationProps<T> {
    required: boolean;
    canAdd: boolean;
}

export default function RestPlacesComponent<T extends FieldValues>(props: RestPlacesComponentProps<T>) {
    const {
        fields: places,
        append: appendPlaces,
        remove: removePlaces,
    } = useFieldArray({
        control: props.control,
        name: props.name,
        rules: { required: 'This field is required' },
    });

    const {
        field: { value, onChange },
        fieldState: { invalid, error },
        formState: { errors },
    } = useController({
        name: props.name,
        control: props.control,
        rules: props.required ? { required: 'This field is required' } : {},
    });

    return (
        <Card>
            <Card.Header>
                <div className="flex justify-between">
                    <h5>{lang.text(Translations.trip.stop)}</h5>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    {places?.map((checkPoint, index) => {
                        return (
                            <Col key={index} md={12} className="flex justify-between items-center">
                                <LocationField
                                    index={index}
                                    md={10}
                                    name={`${props.name}.${index}`}
                                    readOnly={props.isReadOnly}
                                    label={`${lang.text(Translations.trip.stopIndex)} ${index + 1}`}
                                    control={props.control}
                                    required={true}
                                />
                                {!props.isReadOnly && (
                                    <div style={{ height: 30 }} className="mt-5">
                                        <DeleteButton type="button" onClick={() => removePlaces(index)}></DeleteButton>
                                    </div>
                                )}
                            </Col>
                        );
                    })}
                    <div className="flex justify-end w-full mt-4">
                        <AddButton
                            onClick={async () => {
                                const isValid = await props.trigger(props.name as Path<T>); // Validate 'name' field
                                if (!isValid) {
                                    toast.error('Vui lòng điền thông tin điểm dừng trước khi thêm mới');
                                    return;
                                }
                                appendPlaces(
                                    {},
                                    {
                                        shouldFocus: true,
                                    }
                                );
                            }}
                            type="button"
                            isRender={props.canAdd}
                            text={lang.text(Translations.trip.addStop)}
                        />
                    </div>
                </Row>
            </Card.Body>
        </Card>
    );
}
