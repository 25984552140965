import { Col, InputText } from 'components/ui-kit';
interface InputFieldSimpleProps {
    md?: 3 | 6 | 9 | 12 | 4;
    xs?: 3 | 6 | 9 | 12 | 4;
    label: string;
    name?: string;
    placeholderProp?: string;
    readOnly?: boolean;
    required?: boolean;
    disabled?: boolean;
    value: string;
}
export default function InputSimpleField({ md = 4, xs = 12, label, name, readOnly, required, disabled, value, placeholderProp }: InputFieldSimpleProps) {
    return (
        <Col md={md} sm={xs ?? 6}>
            <div className="flex-auto">
                <span className="block">{label}</span>
                <InputText required={required} readOnly={readOnly} disabled={disabled} value={value} className="w-full" />
            </div>
        </Col>
    );
}
