import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { TNCourseDetailResponse } from 'api/v2/training/models/tnCourse.model';
import { TNCourseSubmissionFilterRequest, TNSubmissionDetailResponse } from 'api/v2/training/models/tnCourseSubmission.model';
import tnCourseSubmissionAPI from 'api/v2/training/tnCourseSubmission';
import { FilterButton } from 'components/Button/FilterButton';
import { SelectField } from 'components/FormControl';
import useModalHook from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row } from 'components/ui-kit';
import { getTrainingStatus } from 'constants/training';
import { formatDateTime } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Routers from 'router/const/routers';
import { tnRenderBagdeStatus } from 'views/training/trainee/tnMyCoursesPage';

export interface TNCourseSubmissionsComponentProps {
    course: TNCourseDetailResponse;
}

const TNCourseSubmissionsComponent = ({ course }: TNCourseSubmissionsComponentProps) => {
    const { query, setQuery } = useQuery();

    const cols = useMemo(
        () => [
            {
                field: 'fullName',
                title: lang.text(Translations.training.student),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'lastPoint',
                title: lang.text(Translations.training.grade),
            },
            {
                field: 'completedDate',
                title: lang.text(Translations.training.completedTime),
            },
            {
                field: 'comment',
                title: lang.text(Translations.training.comment),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    const statuses = useMemo(getTrainingStatus, []);
    const [paginationData, setPaginationData] = useState<PaginationBase<TNSubmissionDetailResponse>>();
    const { control, handleSubmit, getValues, reset, formState, setValue } = useForm();
    const { openModal, Prompt } = useModalHook();
    const navigate = useNavigate();
    useEffect(() => {
        handleFetchData({ ...defaultPaginationRequest, ...query });
    }, [query]);

    const showDetail = async (courseId: string, userId: string) => {
        // await openModal({
        //     isFullScreen: true,
        //     title: [course?.title, userSubmission?.fullName].join(' - '),
        //     isResetRoute: true,
        //     body: <TNCourseSubmissionDetailComponent userSubmission={userSubmission} course={course} onSuccess={() => {}} />,
        // });
        navigate(Routers.trainingOnline.setPoint + `?courseId=${courseId}&userId=${userId}`);
    };

    const handleFetchData = async (paginate: PaginationBaseRequest) => {
        const filter: TNCourseSubmissionFilterRequest = {
            ...paginate,
            courseId: course?.id,
        };
        tnCourseSubmissionAPI
            .getByIndex(filter)
            .then((n) => {
                const data: PaginationBase = {
                    ...n,
                    datas:
                        n?.datas?.map((userSubmission) => ({
                            ...userSubmission,
                            status: tnRenderBagdeStatus(userSubmission.status),
                            completedDate: formatDateTime(userSubmission.completedDate),
                            action: <ActionButtons2 id={userSubmission.userId} viewPms={true} onViewDetail={() => showDetail(course.id, userSubmission.userId)} />,
                        })) ?? [],
                };
                setPaginationData(data);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Header>
                        <form
                            className="w-full"
                            onSubmit={handleSubmit((data) => {
                                setQuery({
                                    ...data,
                                    currentPage: 1,
                                });
                            })}
                        >
                            <Row>
                                <SelectField md={4} label={lang.text(Translations.common.status)} options={statuses} name={'status'} control={control} />
                                <Col className="flex justify-end">
                                    <FilterButton type="submit" />
                                </Col>
                            </Row>
                        </form>
                    </Card.Header>
                    <Card.Body>
                        <PaginationTable columns={cols} pagination={paginationData} isStriped={true} disableSearch={true} wrapperClass="mt-5" />
                    </Card.Body>
                    {Prompt}
                </Card>
            </div>
        </Fragment>
    );
};

export default TNCourseSubmissionsComponent;
