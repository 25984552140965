import { Modal } from 'components/ui-kit';
import { ReactNode } from 'react';

interface ModalComponentProps {
    showModal: boolean;
    handleCloseModal: () => void;
    titleModal: String;
    bodyModal: ReactNode;
    footerModal: ReactNode;
    closeButton?: boolean;
    scrollable?: boolean;
}
export function ModalComponent({ showModal, handleCloseModal, titleModal, bodyModal, footerModal, closeButton = true, scrollable = false }: ModalComponentProps) {
    return (
        <Modal closable={closeButton} header={titleModal} visible={showModal} footer={footerModal} style={{ width: '50vw' }} onHide={() => handleCloseModal()} draggable={false}>
            {bodyModal}
        </Modal>
    );
}
