//react-bootstrap

import { Row } from 'components/ui-kit/index';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import { GoldenRuleCreateRequest } from 'api/models';
import configGoldenRuleApi from 'api/v2/configGoldenRuleApi';
import { SaveButton } from 'components/Button';
import { InputField, TextAreaField } from 'components/FormControl';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const ConfigGoldenRulesCreatePage = memo(() => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        content: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        reset,
    } = useForm<{ name: string; content?: string }>({
        defaultValues: {
            name: '',
            content: '',
        },
        resolver: yupResolver(schema),
    });

    const onSubmitAdd = async () => {
        const values = getValues();
        const _addData: GoldenRuleCreateRequest = {
            name: values.name,
            content: values.content,
        };
        await configGoldenRuleApi.create(_addData);
        reset({
            name: '',
            content: '',
        });
    };
    return (
        <Card>
            <Card.Header></Card.Header>
            <Card.Body>
                <form className=" justify-center" onSubmit={handleSubmit(onSubmitAdd)}>
                    <Row>
                        <InputField md={12} name="name" control={control} label={t(Translations.form.name)} placeholder="" />
                        <TextAreaField md={12} name="content" control={control} label={t(Translations.form.content)} placeholder="" />

                        <div className="flex justify-end">
                            <SaveButton type="submit" />
                        </div>
                    </Row>
                </form>
            </Card.Body>
        </Card>
    );
});

export default ConfigGoldenRulesCreatePage;
