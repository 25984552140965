import { ApexOptions } from 'apexcharts';
import { ColorHex } from 'enum/color';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
interface BardChartProps {
    labels: string[];
    series: number[];
}
export const PieChart = (props: BardChartProps) => {
    var colors = useMemo(() => [ColorHex.primary, ColorHex.danger, ColorHex.success, ColorHex.warning, ColorHex.info], []);
    colors = colors.slice(0, props.labels.length);
    let option: ApexOptions = {
        colors: colors,
        series: props.series,
        labels: props.labels,
    };

    return <div>{<ReactApexChart options={option} series={option.series} type="pie" width={380} />}</div>;
};
