import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import iirCategoryApi from 'api/v2/iir/iirCategoryApi';
import { IIRCategoryType } from 'api/enums/IIR';
import { lang } from 'i18n/lang';

export interface IIRCategoryBaseSelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    control: Control<any>;
    companyId?: string;
    type?: IIRCategoryType;
}
const IIRCategoryIncidentLevelSelect = (props: IIRCategoryBaseSelectProps) => {
    return <IIRBaseCategorySelect {...props} type={IIRCategoryType.IncidentLevel} />;
};
export function IIRBaseCategorySelect(props: IIRCategoryBaseSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadDatas();
    }, [props.companyId]);
    useEffect(() => {
        loadDatas();
    }, []);

    const loadDatas = () => {
        if (!props.companyId) {
            setOptions([]);
            return;
        } else {
            iirCategoryApi
                .getDropdown(props.type!, props.companyId)
                .then((res) => {
                    setOptions(res);
                })
                .catch(() => {});
        }
    };

    return (
        <SelectField
            disable={props.disable}
            readOnly={props.disable}
            label={lang.text(props.type === IIRCategoryType.IncidentType ? Translations.iir.incidentType : Translations.iir.incidentLevel)}
            options={options}
            name={props.name}
            control={props.control}
            required={props.required}
           
            md={props.md}
            xs={props.xs}
            onValueChange={(e) => {
                props.onValueChange && props.onValueChange(e);
            }}
        />
    );
}
export default IIRCategoryIncidentLevelSelect;
