// import TNCourseSubmissionDetailComponent from "../component/submissions/tnCourseSubmissionDetail.component"

//  const TNSetpointPage = () => {
//     return<></>
//     // return <TNCourseSubmissionDetailComponent />
// }
import { TNCourseDetailResponse } from 'api/v2/training/models/tnCourse.model';
import { TNCourseSubmissionSetPointRequest, TNSubmissionDetailResponse, TNSubmissionItemResponse } from 'api/v2/training/models/tnCourseSubmission.model';
import tnCourseAPI from 'api/v2/training/tnCourseApi';
import tnCourseSubmissionAPI from 'api/v2/training/tnCourseSubmission';
import useModalHook from 'components/ModalHook';
import { Empty } from 'components/shared';
import { Row } from 'components/ui-kit/index';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TNConfirmSetPointComponentModal, { TNConfirmSetpoinModel } from '../component/submissions/tnConfirmSetPoint.component';

// interface TNCourseSubmissionDetailComponentProps {
//     userSubmission: TNUserSubmissionDetailResponse;
//     course: TNCourseDetailResponse;
//     onSuccess: () => void;
// }

const TNSetpointPage = () => {
    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        setValue,
        formState: { isSubmitting, errors },
    } = useForm<TNSubmissionItemResponse>({});
    const [userSubmission, setUserSubmission] = useState<TNSubmissionDetailResponse | undefined>();
    const { getQueryParam } = useQueryParams();
    const [course, setCourse] = useState<TNCourseDetailResponse>();
    useEffect(() => {
        loadSubmission();
    }, []);
    const loadSubmission = () => {
        const courseId = getQueryParam('courseId');
        const userId = getQueryParam('userId');
        courseId &&
            tnCourseAPI
                .getById(courseId)
                .then((course) => {
                    setCourse(course);
                    userId &&
                        tnCourseSubmissionAPI
                            .getByCourseAndUser(courseId, userId)
                            .then((res) => {
                                setUserSubmission(res);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                })
                .catch((err) => console.log(err));
    };
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    const handleSetPoint = async (id: string, isRemake: boolean) => {
        const commentAndPoint: TNConfirmSetpoinModel = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <TNConfirmSetPointComponentModal onClose={onClose} onCompleted={onCompleted} />,
        });

        if (!commentAndPoint) return;

        const formValues = getValues();
        const data: TNCourseSubmissionSetPointRequest = {
            answerComments: formValues.answers.map((answer) => {
                return {
                    questionId: answer.questionId,
                    comment: answer.comment,
                };
            }),
            isRemake: isRemake,
            point: commentAndPoint.point,
            submissionItemId: id,
            comment: commentAndPoint.comment,
        };
        tnCourseSubmissionAPI
            .setPoint(data)
            .then(() => {
                loadSubmission();
            })
            .catch(() => {});
    };

    return userSubmission == undefined || course == undefined ? (
        <Empty />
    ) : (
        <>
            {/* <Row>
                <Col sm={2}>
                    <Card className="h-100">
                        <Card.Body>
                            <Nav variant="pills" className="flex-column">
                                {userSubmission.items.map((submissionItem, index) => {
                                    return (
                                        <Nav.Item key={index}>
                                            <Nav.Link className="nav-timeline" eventKey={submissionItem.id}>
                                                <div>
                                                    {formatDateTime(submissionItem.createdTime)} <br />
                                                    {submissionItem?.status}
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={10} style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
                    <Tab.Content>
                        {userSubmission.items?.map((submissionItem) => {
                            return (
                                <Tab.Pane eventKey={submissionItem?.id}>
                                    <form onSubmit={() => {}}>
                                        <Card>
                                            <div className="w-full text-center">
                                                <h4>Kết quả bài làm</h4>
                                            </div>
                                            <Card.Body>
                                                <div>
                                                    {course.questions?.map((question, index) => {
                                                        const userAnswer = submissionItem?.answers?.find((sa) => sa?.questionId === question?.id);
                                                        return (
                                                            <div>
                                                                <div key={index} className="mb-3">
                                                                    <b> {index + 1} </b>: <b> {question.question} </b>
                                                                    {question.quizType === TNQuestionType.Foreword && (
                                                                        <p>{userAnswer?.answerContent}</p>
                                                                        // <TextAreaField disabled control={control} name={`answers.${index}.userAnswer`} defaultValue={userAnswer?.answerContent} />
                                                                    )}
                                                                    {question.quizType === TNQuestionType.Foreword && (
                                                                        <div>
                                                                            <label className="pr-2">{lang.text(Translations.training.correctAnswer)}: </label>
                                                                            <i> {question.correctAnswer}</i>
                                                                        </div>
                                                                    )}
                                                                    {question.answers && question.answers.length > 0 && (
                                                                        <div>
                                                                            {question?.answers?.map((answer, idx) => {
                                                                                const isUserAnswer = userAnswer?.answerIds.includes(answer?.id!);
                                                                                return (
                                                                                    <div key={idx} className="flex items-center">
                                                                                        <Form.Check inline type={'checkbox'} checked={isUserAnswer} />
                                                                                        <div
                                                                                            className={
                                                                                                answer.isCorrect
                                                                                                    ? 'correct-answer'
                                                                                                    : answer.isCorrect || (!answer.isCorrect && isUserAnswer)
                                                                                                    ? 'wrong-answer'
                                                                                                    : ''
                                                                                            }
                                                                                        >
                                                                                            {answer.content}
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <InputField control={control} name={`answers.${index}.comment`} label={'Nhận xét'} />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div>
                                                    {userSubmission.status === TNSubmissionStatus.UserCompleted && (
                                                        <div className="flex justify-center">
                                                            <Button size="sm" onClick={() => handleSetPoint(submissionItem.id, false)}>
                                                                Chấm điểm
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                className="mx-2"
                                                                variant="warning"
                                                                onClick={() => {
                                                                    handleSetPoint(submissionItem.id, true);
                                                                }}
                                                            >
                                                                Yêu cầu làm lại
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </form>
                                </Tab.Pane>
                            );
                        })}
                    </Tab.Content>
                </Col>
            </Row> */}
            <Row>{Prompt}</Row>
        </>
    );
};

export default TNSetpointPage;
