import { RADetailResponse } from 'api/v2/risk-assessment/model/rs.Response.model';
import raApi from 'api/v2/risk-assessment/raApi';
import { DeleteButton, DownloadButton } from 'components/Button';
import InputFieldSimple from 'components/FormControl/InputFieldSimple';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { Empty } from 'components/shared';
import { Accordion, Badge, Col, Row } from 'components/ui-kit';
import Card from 'components/ui-kit/primereact/Card';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { ModalDetailProps } from 'models/modalDetailProps';
import { AccordionTab } from 'primereact/accordion';
import { Fragment, useEffect, useState } from 'react';

const RADetailPage = (props: ModalDetailProps) => {
    const [ra, setRa] = useState<RADetailResponse | undefined>(undefined);
    useEffect(() => {
        if (props.id) {
            raApi
                .getById(props.id)
                .then((res: RADetailResponse) => {
                    setRa(res);
                })
                .catch(() => {});
        }
    }, [props.id]);
    const onDelete = () => {
        if (ra?.id) {
            raApi
                .delete(ra.id)
                .then(() => props.onDeleted && props.onDeleted())
                .catch(() => {});
        }
    };
    const onDownload = () => {
        if (ra?.id) {
            raApi
                .downloadReport(ra.id)
                .then((n) => {})
                .catch((n) => {});
        }
    };
    return (
        <Fragment>
            {!ra && <Empty />}
            {ra && (
                <div>
                    <Card>
                        <Card.Body>
                            <Fragment>
                                <Row>
                                    <div className="flex justify-end">
                                        {ra.isCanDelete && (
                                            <ToastConfirm message={lang.text(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                                <DeleteButton />
                                            </ToastConfirm>
                                        )}
                                        <DownloadButton onClick={onDownload} isRender={ra != undefined} />
                                    </div>
                                </Row>
                                <Row>
                                    <InputFieldSimple md={4} xs={12} value={ra.areaName} label={lang.text(Translations.common.name)} readOnly={false} />
                                    <InputFieldSimple md={4} xs={12} value={ra.companyName} label={lang.text(Translations.common.name)} readOnly={false} />
                                    <InputFieldSimple md={4} xs={12} value={ra.areaName} label={lang.text(Translations.common.name)} readOnly={false} />
                                    <div>
                                        <ul>
                                            <li>
                                                {lang.text(Translations.common.createdBy)} {ra.createdUserName}
                                            </li>
                                            <li>
                                                {lang.text(Translations.common.createdTime)} {formatDateTime(ra.createdTime)}
                                            </li>
                                            <li>
                                                <label className="me-2">{lang.text(Translations.ra.tag)} : </label>
                                                {ra.tags?.map((item, index) => (
                                                    <Badge key={index} color="primary" className="me-1">
                                                        {item.name}
                                                    </Badge>
                                                ))}
                                            </li>
                                        </ul>
                                    </div>
                                </Row>
                            </Fragment>
                        </Card.Body>
                    </Card>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header className="flex justify-between">
                                    <h5 className="p-card-title  ">{lang.text(Translations.ra.controlMeasure)}</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Accordion>
                                            {ra?.steps?.map((item, index) => (
                                                <AccordionTab
                                                    header={
                                                        <div className="flex w-full justify-between px-2">
                                                            <span>
                                                                # {item.stepNumber} {item.name}
                                                            </span>
                                                        </div>
                                                    }
                                                >
                                                    {[
                                                        { label: lang.text(Translations.common.pic), value: item.id },
                                                        { label: lang.text(Translations.common.riskLevel), value: item.levelName },
                                                        { label: lang.text(Translations.ra.hazard), value: item.hazards },
                                                        { label: lang.text(Translations.ra.controlMeasure), value: item.content },
                                                    ].map((item, index) => (
                                                        <div key={index} className="flex">
                                                            <b style={{ minWidth: '25%' }}>{item.label} </b>
                                                            <span style={{ minWidth: '75%' }}>{item.value}</span>
                                                            <hr />
                                                        </div>
                                                    ))}
                                                </AccordionTab>
                                            ))}
                                        </Accordion>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </Fragment>
    );
};

export default RADetailPage;
