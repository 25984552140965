import { Badge } from 'components/ui-kit';
import { CourseStatus } from 'enum/training';

export const TNCourseStatusBadge = ({ status }: { status: CourseStatus }) => {
    switch (status) {
        case CourseStatus.Waiting:
            return <Badge color="warning">Pending</Badge>;

        case CourseStatus.Completed:
            return <Badge color="success">Completed</Badge>;
        default:
            return <></>;
    }
};
