import { yupResolver } from '@hookform/resolvers/yup';
import { UserDetailResponse } from 'api/v2/user/model';
import profileApi from 'api/v2/user/profileApi';
import { SaveButton } from 'components/Button';
import { InputField } from 'components/FormControl';
import DataListView from 'components/shared/DataListView';
import { Avatar, Card, Col, Row, Tab } from 'components/ui-kit/index';
import { EntityConst } from 'constants/EntityConst';
import Images from 'constants/images';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { TabPanel } from 'primereact/tabview';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CertificationComponent from './component/certification.component';
import CourseComponent from './component/course.component';
import IncentiveDisciplinaryComponent from './component/incentiveDisciplinary.component';

export default function UserProfilePage() {
    const [userProfile, setUserProfile] = useState<UserDetailResponse>();
    useEffect(() => {
        profileApi
            .getProfile()
            .then((n) => {
                setUserProfile(n);
            })
            .catch((n) => {});
    }, []);
    const schema = yup.object().shape({
        height: yup.string().max(EntityConst.short),
        weight: yup.string().max(EntityConst.short),
        bloodType: yup.string().max(EntityConst.short),
        healthStatus: yup.string().max(EntityConst.longString),
        healthCheckResultDate: yup.string().max(EntityConst.longString),
    });
    const {
        control: controlMedical,
        handleSubmit: handleSubmitMedical,
        getValues: getValuesMedical,
    } = useForm({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const SaveMedical = () => {
        let form = getValuesMedical();
        profileApi
            .updateMedical(form)
            .then((n) => {})
            .catch((n) => {});
    };

    return (
        <>
            {userProfile && (
                <Fragment>
                    {/* QR và avater */}
                    <Card>
                        <Row>
                            <Col md={6}>
                                <div className="flex flex-wrap items-center justify-between">
                                    <div className="flex flex-wrap items-center">
                                        <div className=" position-relative mr-1 mb-3 mb-lg-0 profile-logo profile-logo1">
                                            <Avatar size="xlarge" image={Images.avatar} />
                                        </div>
                                        <div className="flex flex-wrap items-center mb-3 mb-sm-0">
                                            <h4 className="me-2 h4">{userProfile.fullName}</h4>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className="flex justify-end">
                                <div
                                    style={{
                                        width: '100px',
                                    }}
                                    dangerouslySetInnerHTML={{ __html: userProfile.qRCode ?? '' }}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col>
                            {/* // Thông tin cá nhân */}
                            <Card>
                                <Card.Body>
                                    <DataListView
                                        header={lang.text(Translations.pageName.userProfile)}
                                        datas={[
                                            {
                                                label: lang.text(Translations.form.fullName.name),
                                                value: userProfile.fullName,
                                            },
                                            {
                                                label: lang.text(Translations.form.title.name),
                                                value: userProfile.title,
                                            },
                                            {
                                                label: lang.text(Translations.form.username.name),
                                                value: userProfile.userName,
                                            },
                                            {
                                                label: lang.text(Translations.common.role),
                                                value: userProfile.roleName,
                                            },
                                            {
                                                label: lang.text(Translations.form.phone.name),
                                                value: userProfile.phoneNumber,
                                            },
                                            {
                                                label: lang.text(Translations.form.email.name),
                                                value: userProfile.email,
                                            },
                                        ]}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            {/* // Thông tin tổ chức */}
                            <Card>
                                <Card.Body>
                                    <DataListView
                                        header={'Thông tin tổ chức'}
                                        datas={
                                            (userProfile.organizationUnits &&
                                                userProfile.organizationUnits
                                                    .filter((n) => !n.parentId)
                                                    ?.map((n) => ({
                                                        label: n.name,
                                                        value: userProfile.organizationUnits?.filter((x) => x.parentId == n.id).map((x) => <li>{x.name}</li>),
                                                    }))) ??
                                            []
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* Hồ sơ y tế */}
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <b className="text-primary mb-2">{lang.text(Translations.userProfile.medicalRecord)}</b>
                                    <form onSubmit={handleSubmitMedical(SaveMedical)}>
                                        <Row>
                                            <InputField
                                                md={4}
                                                defaultValue={userProfile.height}
                                                required={false}
                                                name="height"
                                                control={controlMedical}
                                                label={lang.text(Translations.userProfile.height)}
                                                placeholder="m"
                                            />
                                            <InputField
                                                md={4}
                                                defaultValue={userProfile.weight}
                                                required={false}
                                                name="weight"
                                                control={controlMedical}
                                                label={lang.text(Translations.userProfile.weight)}
                                                placeholder="kg"
                                            />
                                            <InputField
                                                md={4}
                                                defaultValue={userProfile.bloodType}
                                                required={false}
                                                name="bloodType"
                                                control={controlMedical}
                                                label={lang.text(Translations.userProfile.bloodType)}
                                                placeholder=""
                                            />
                                            <InputField
                                                md={4}
                                                defaultValue={userProfile.healthStatus}
                                                required={false}
                                                name="healthStatus"
                                                control={controlMedical}
                                                label={lang.text(Translations.userProfile.healthStatus)}
                                                placeholder=""
                                            />
                                            <InputField
                                                md={4}
                                                defaultValue={userProfile.healthCheckResultDate}
                                                required={false}
                                                name="healthCheckResultDate"
                                                control={controlMedical}
                                                label={lang.text(Translations.userProfile.healthCheckResultDate)}
                                                placeholder="dd-MM-yyyy"
                                            />

                                            <Col className="flex justify-end">
                                                <SaveButton text={lang.text(Translations.button.update)} type="submit" color="info" />
                                            </Col>
                                        </Row>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* // Hồ sơ đào tạo */}
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Tab className="mb-3">
                                                <TabPanel header={lang.text(Translations.userProfile.certificate)}>
                                                    <CertificationComponent />
                                                </TabPanel>
                                                <TabPanel header={lang.text(Translations.userProfile.course)}>
                                                    <CourseComponent />
                                                </TabPanel>
                                            </Tab>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/* // Hồ sơ đào tạo */}
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <b className="text-primary mb-2">{lang.text(Translations.userProfile.profileIncentiveDisciplinary)}</b>

                                    <Row>
                                        <Col>
                                            <IncentiveDisciplinaryComponent />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </>
    );
}
