import { SelectField, SelectFieldProps, SelectOption } from 'components/FormControl';
import MutilSelectField from 'components/FormControl/MutilSelectField';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useCustomer } from 'providers/contexts';
import { useEffect, useState } from 'react';

export interface AreaSelectProps extends SelectFieldProps {
    isAddAll?: boolean;
    companyId: string | null | undefined;
    isMutiple?: boolean;
}

export function AreaSelect({ name, onSelectedOptionChange, onValueChange, isMutiple, control, required, md = 4, xs = 12, companyId, readOnly }: AreaSelectProps) {
    const { currentCustomer } = useCustomer();
    const [areaOptions, setAreaOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        const options = currentCustomer?.companies.find((n) => n.id === companyId)?.areas.toSelectOptions('name', 'id') ?? [];
        setAreaOptions(options);
    }, [companyId]);
    if (isMutiple === true)
        return (
            <MutilSelectField
                control={control}
                options={areaOptions}
                name={name ?? 'areaIds'}
                required={required}
                md={md}
                onValueChange={(ids) => {
                    onValueChange && onValueChange(ids);
                }}
            />
        );
    return (
        <SelectField
            onValueChange={onValueChange}
            onSelectedOptionChange={onSelectedOptionChange}
            readOnly={readOnly}
            label={t(Translations.form.area.name)}
            options={areaOptions}
            name={name ?? 'areaId'}
            control={control}
            required={required}
            md={md}
            xs={xs}
        />
    );
}
