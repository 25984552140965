import siTemplateApi from 'api/v2/safe-inspection/siTemplateApi';
import { SelectField, SelectOption } from 'components/FormControl';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { SIReportType } from 'enum/safetyInspection';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';

export interface SITemplateSelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
    reportType: SIReportType | null;
    companyId: string | null;
}
export function SITemplateSelect({ name = 'contractorId', control, required, md = 4, xs = 12, onValueChange, isAddAll, readOnly, reportType, companyId }: SITemplateSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadDatas();
    }, [reportType, companyId]);

    const loadDatas = () => {
        if (companyId && reportType) {
            siTemplateApi
                .getTemplateDropdown(reportType, companyId, false)
                .then((n) => {
                    setOptions(n ?? []);
                })
                .catch((n) => {});
        } else setOptions([]);
    };

    return (
        <SelectField
            readOnly={readOnly}
            label={t(Translations.equipment.templateCategories)}
            options={options}
            name={name ?? 'contractorId'}
            control={control}
            required={required}
            md={md}
            xs={xs}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default SITemplateSelect;
