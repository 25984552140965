import { PaginationBase } from 'api/models/common';
import { LDTagResponse } from 'api/v2/legal-document/legalDocument.models';
import legaldocumentTagApi from 'api/v2/legal-document/legalDocumentTagApi';
import { SelectField, SelectFieldProps, SelectOption } from 'components/FormControl';
import MutilSelectField from 'components/FormControl/MutilSelectField';
import usePromptInputText from 'components/Modal/PromptInputText';
import { Col, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { useEffect, useState } from 'react';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useController } from 'react-hook-form';

export interface LegalDocumentTagSelectProps extends SelectFieldProps {
    isMulti: boolean | undefined;
    defaultValue?: any;
    isCanCreate: boolean;
}

export const LegalDocumentTagSelect = (props: LegalDocumentTagSelectProps) => {
    const { tokenProfile } = useAuth();
    const isCanCreateTag = tokenProfile?.pms.ldTagCreate;
    const [options, setOptions] = useState<SelectOption[]>([]);
    const {
        field,
        fieldState: { error },
    } = useController({
        name: props.name,
        control: props.control,
        defaultValue: props.defaultValue,
    });
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const onAddTag = async () => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.name),
            defaultValue: '',
        });
        if (text)
            legaldocumentTagApi
                .create({ label: text, id: null })
                .then((id: string) => {
                    setOptions([...options, { value: id, label: text }]);
                })
                .catch((res) => {});
    };

    useEffect(() => {
        legaldocumentTagApi
            .getAll()
            .then((tags: PaginationBase<LDTagResponse>) => {
                setOptions(tags.datas?.toSelectOptions('label', 'id') ?? []);
            })
            .catch((e) => {});
    }, []);
    return (
        <Col md={props.md} sm={props.xs}>
            <Row>
                <Col md={12}>
                    <label>
                        {lang.text(Translations.ra.tag)}
                        {props.required && <span className="text-danger">*</span>} &nbsp;
                        {isCanCreateTag && props.isCanCreate && (
                            <span className={`btn-inner hover:cursor-pointer`}>
                                <PlusCircleFill onClick={onAddTag} />
                            </span>
                        )}
                    </label>
                </Col>
                {props.isMulti ? (
                    <MutilSelectField {...props} md={12} xs={12} />
                ) : (
                    <SelectField
                        {...props}
                        md={12}
                        onSelectedOptionChange={(n: any) => {
                            if (Array.isArray(n)) {
                                field.onChange(n?.map((x: any) => x.value) ?? []);
                            } else {
                                field.onChange(n.value);
                            }
                        }}
                        options={options}
                    />
                )}
            </Row>
            {PromptInputText}
        </Col>
    );
};

export default LegalDocumentTagSelect;
