import { Col, InputTextArea } from 'components/ui-kit';
import React, { useEffect } from 'react';
import { Control, useController } from 'react-hook-form';

export interface TextAreaFieldProps {
    onValueChange?: (value: string) => void;
    defaultValue?: string | number | null | undefined;
    placeholder?: string;
    control: Control<any>;
    name: string;
    defaultValues?: any;
    errorMessage?: string;
    required?: boolean;
    md?: 3 | 4 | 12 | 6 | 10;
    xs?: 3 | 4 | 12 | 6 | 10;
    disable?: boolean;
    readOnly?: boolean;
    formGroupClasses?: string;
    formControlClasses?: string;
    disabled?: boolean;
    label?: string;
    rows?: number;
}

{
    /* <TextAreaField
name="note"
control={control}
label={t(Translations.form.riskLevel.note)}
placeholder={t(Translations.form.riskLevel.note)}
/> */
}
export function TextAreaField({ name, control, label, placeholder, disabled = false, required = false, defaultValue = undefined, onValueChange, xs = 12, md, readOnly, rows = 4 }: TextAreaFieldProps) {
    const placeholderProp = placeholder ? placeholder : '';
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });

    const [isFirstTime, setIsFirstTime] = React.useState<boolean>(true);
    // const [curentValue, setCurrentValue] = React.useState();
    useEffect(() => {
        if (isFirstTime && defaultValue) {
            onChange(defaultValue);
            setIsFirstTime(false);
        }
    }, [defaultValue]);
    React.useEffect(() => {
        if (onValueChange) onValueChange(value);
    }, [value]);

    const inValidClass = error?.message ? 'is-invalid' : '';

    return (
        <Col md={md} sm={xs ?? 6}>
            <div className="flex-auto">
                <label className="block">
                    {label} {required && <span className="text-danger">*</span>}
                </label>
                <InputTextArea
                    // sub={suffixText}
                    required={required}
                    rows={rows}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    disabled={disabled}
                    invalid={invalid}
                    value={value ?? ''}
                    onChange={(e) => onChange(e.target.value)}
                    className="w-full"
                />
                {invalid && <div className="invalid-feedback  d-block ">{error?.message}</div>}
            </div>
        </Col>
        // <Col md={md} sm={xs ?? 6}>
        //     <Form.Group className={`mb-3 ${formGroupClasses}`}>
        //         {label && (
        //             <Form.Label htmlFor={name} className="">
        //                 {label} {required && <span className="text-danger">*</span>}
        //             </Form.Label>
        //         )}
        //         <Form.Control
        //             readOnly={readOnly}
        //             name={name}
        //             as="textarea"
        //             rows={rows || 3}
        //             type={'text'}
        //             placeholder={placeholderProp}
        //             value={value}
        //             onChange={(e) => {
        //                 onChange(e.target.value);
        //             }}
        //             onBlur={onBlur}
        //             className={`${inValidClass} ${formControlClasses}`}
        //             disabled={disabled || readOnly}
        //         />
        //         <div className="invalid-feedback">{error?.message}</div>
        //     </Form.Group>
        // </Col>
    );
}
