import { injectStore } from 'api/v2/client';
import { injectStoreFileClient } from 'api/v2/clientFile';
import { PrimeReactProvider } from 'primereact/api';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './App';
import { store } from './app/store';
import './i18n/i18n';
import './index.scss';
import 'primereact/resources/primereact.min.css';
import './themes/theme.css';
import 'primeicons/primeicons.css';
// PrimeReact theme (choose one from the available themes)
// import 'primereact/resources/themes/saga-blue/theme.css'; // Example: Saga Blue theme

// // PrimeReact core CSS
// import 'primereact/resources/primereact.min.css';

// // PrimeIcons for icons
// import 'primeicons/primeicons.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

injectStore(store);
injectStoreFileClient(store);

root.render(
    <PrimeReactProvider>
        <Provider store={store}>
            <App />
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Provider>
    </PrimeReactProvider>
);
