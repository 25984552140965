import { useEffect } from 'react';

import { RadioButton } from 'components/ui-kit/index';
import { Control, Controller, useController } from 'react-hook-form';

export interface RadioOption {
    label?: string;
    value: number | string;
    defaultChecked?: boolean;
    className?: string;
}

export interface RadioFieldProps {
    name: string;
    control: Control<any>;
    label?: string;
    options?: RadioOption[];
    formGroupClasses?: string;
    readonly?: boolean;
    disabled?: boolean;
    defaultValue?: any;
    required?: boolean;
    inline: boolean;
    orientation?: 'horizontal' | 'vertical';
    onValueChange?: (value: string | number) => void;
}

export function RadioField({ orientation, inline, required, name, control, label, options, formGroupClasses, readonly = false, disabled = false, onValueChange, defaultValue }: RadioFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });

    const handleChangeOption = (e: any) => {
        if (onValueChange) {
            onValueChange(e);
        }
    };
    useEffect(() => {
        defaultValue && onChange(defaultValue);
    }, [defaultValue]);

    return (
        <>
            <label>
                {label && (
                   <span className="block">{label}</span>
                )}
            </label>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => (
                    <div className={(orientation ?? 'horizontal') == 'vertical' ? 'flex flex-column' : 'flex'}>
                        {options?.map((option, index) => (
                            <div className="flex align-items-center">
                                <RadioButton
                                    inputId={String(option.value)}
                                    name={name}
                                    value={option.value}
                                    onChange={(e) => {
                                        if (readonly) return;
                                        field.onChange(option.value);
                                        handleChangeOption(option.value);
                                    }}
                                    checked={field.value === option.value}
                                />
                                <label htmlFor={String(option.value)} className="ml-2 mr-2">
                                    {option.label}
                                </label>
                            </div>
                        )) ?? []}
                    </div>
                )}
            />
        </>
    );
}
