import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { BaseButton } from './BaseButton';
import { BaseButtonProps } from './BaseButton.props';

// export interface DownloadButtonProps {
//     onClick?: MouseEventHandler;
//     disable?: boolean;
// }

export function DownloadButton(props: BaseButtonProps) {
    return (
        <BaseButton
            icon={'pi pi-download'}
            onClick={props.onClick}
            text={lang.text(Translations.common.download)}
            color={props.color ?? 'success'}
            className={'rounded  ' + (props.className ?? '')}
            size={'sm'}
        />
    );
}
