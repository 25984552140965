import { yupResolver } from '@hookform/resolvers/yup';
import { KPITypeCreateRequest } from 'api/v2/kpi/model/kpiType.model';
import { BaseButton } from 'components/Button/BaseButton';
import { CompanySelect } from 'components/Common';
import { InputField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { Modal } from 'components/ui-kit';
import Color from 'enum/color';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface KPITypeFormProps extends KPITypeCreateRequest, ModalHookComponentProps {
    companyIds: string[];
}
const KPITypeForm = ({ name, onClose, companyIds }: KPITypeFormProps) => {
    useEffect(() => {
        reset({ name: name, companyIds: companyIds });
    }, []);

    const { control, handleSubmit, getValues, reset } = useForm<{
        name: string;
        companyIds: string[];
    }>({
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required(),
                companyIds: yup.array().required(),
            })
        ),
    });

    const onSubmit = () => {
        const form = getValues();
        let data: KPITypeCreateRequest = {
            ...form,
        };
        console.log(data);
        //  onCompleted(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <CompanySelect isMutiple={true} md={12} xs={12} name="companyIds" control={control} required={true} />
            <InputField md={12} name={name} control={control} defaultValue={name} label={lang.text(Translations.common.name)} required={true} />
            <Modal.Footer>
                <BaseButton isRender={true} color={Color.danger} onClick={onClose} text={lang.text(Translations.modal.confirm.cancel)} />
                <BaseButton isRender={true} color={Color.info} type="submit" text={lang.text(Translations.modal.confirm.ok)} />
            </Modal.Footer>
        </form>
    );
};
export default KPITypeForm;
