//router
import IndexRouters from 'router';

import LoadingPage from 'components/LoadingPage';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from 'react-redux';
import { setSetting } from './app/setting/actions';

function App() {
    const dispatch = useDispatch();
    dispatch(setSetting());

    return (
        <div className="App">
            <IndexRouters />
            {/* Loading page */}
            <ConfirmDialog acceptIcon="pi pi-check" rejectIcon="pi pi-times" />
            <LoadingPage />
        </div>
    );
}

export default App;
