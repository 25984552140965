import { ImageEmpty } from 'assets/images';
import { Image } from 'components/ui-kit';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

const Empty = ({ maxHeight = 360 }: { maxHeight?: number }) => {
    const { t } = useTranslation();

    return (
        <div className="text-center mt-5" style={{ maxHeight: maxHeight }}>
            <Image src={ImageEmpty} alt="empty image" className="w-full " style={{ maxWidth: '500px', height: maxHeight, width: '500px', maxHeight: maxHeight }} />
            <h4>{t(Translations.noData)}</h4>
        </div>
    );
};

export default Empty;
