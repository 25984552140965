import { yupResolver } from '@hookform/resolvers/yup';
import { IIRActionFollowUpModel } from 'api/v2/iir/model/iirReportComponent.model';
import { InputField, TextAreaField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import IIRReportItemBaseComponent, { IIRListComponentProps } from './iirBaseItem.component';
import { formatDateTime } from 'helper';
import { Row } from 'components/ui-kit';

const schema = yup.object().shape({
    action: yup.string().required('Hành động không được để trống'),
    performUserName: yup.string().required('Người thực hiện không được để trống'),
    // startDate: yup.string().required('Start date is required'),
    // completedDate: yup.string().required('Finish date is required'),
});

export default function IIRActionFollowUpViewComponent({ datas, arrayForm, isReadOnly }: IIRListComponentProps<IIRActionFollowUpModel>) {
    const itemForm = useForm({
        resolver: yupResolver(schema),
    });

    const FormComponent = ({ data }: { data: IIRActionFollowUpModel }) => {
        useEffect(() => {
            itemForm.reset(data);
        }, [data]);

        return (
            <Row>
                <DateTimeField label={lang.text(Translations.iir.actionFollowUp.startDate)} md={4} name={'startDate'} control={itemForm.control} readOnly={isReadOnly} isShowTimePicker={true} />
                <DateTimeField
                    label={lang.text(Translations.iir.actionFollowUp.completedDate)}
                    md={4}
                    name={'completedDate'}
                    control={itemForm.control}
                    readOnly={isReadOnly}
                    isShowTimePicker={true}
                />
                <InputField md={12} xs={12} control={itemForm.control} required name="performUserName" label={lang.text(Translations.iir.actionFollowUp.performUserName)} />
                <TextAreaField md={12} xs={12} control={itemForm.control} required name="action" label={lang.text(Translations.iir.actionFollowUp.action)} />
            </Row>
        );
    };

    return (
        <IIRReportItemBaseComponent
            isReadOnly={isReadOnly}
            title={lang.text(Translations.iir.actionFollowUps)}
            datas={datas?.map((n) => ({ ...n, _action: n.action, startDate: formatDateTime(n?.startDate), completedDate: formatDateTime(n?.completedDate) })) ?? []}
            columns={[
                {
                    field: '_action',
                    title: lang.text(Translations.iir.actionFollowUp.action),
                },
                {
                    field: 'performUserName',
                    title: lang.text(Translations.iir.actionFollowUp.performUserName),
                },
                {
                    field: 'startDate',
                    title: lang.text(Translations.iir.actionFollowUp.startDate),
                },
                {
                    field: 'completedDate',
                    title: lang.text(Translations.iir.actionFollowUp.completedDate),
                },
                {
                    field: 'action',
                    title: '',
                },
            ]}
            //@ts-ignore
            formComponent={(data) => <FormComponent data={data} />}
            formComponentControl={itemForm}
            arrayForm={arrayForm}
        />
    );
}
