import { IIREvidencesModel } from 'api/v2/iir/model/iirReportComponent.model';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import IIRReportItemBaseComponent, { IIRListComponentProps } from './iirBaseItem.component';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputField } from 'components/FormControl';
import UploadFileField, { UploadFileList } from 'components/FormControl/UploadFileField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileResponse } from 'api/models';
import { Row } from 'components/ui-kit';

const schema = yup.object().shape({
    description: yup.string().required('Tên chứng cứ không được để trống'),
    // age: yup.number().required('Age is required'),
    // gender: yup.string().required('Gender is required'),
    // injuryStatus: yup.string().required('Injury status is required'),
    // experiences: yup.string().required('Experiences is required'),
    // shift: yup.string().required('Shift is required'),
    // statusOfContract: yup.string().required('Status of contract is required'),
});
export default function IIREvidentViewComponent(props: IIRListComponentProps) {
    const itemForm = useForm<IIREvidencesModel>({
        resolver: yupResolver(schema),
    });
    const FormComponent = ({ data }: { data: IIREvidencesModel }) => {
        // const [files, setFiles] = useState<FileResponse[]>([]);

        useEffect(() => {
            itemForm.reset(data);
        }, [data]);

        return (
            <Row>
                <InputField md={12} control={itemForm.control} required name="description" label={lang.text(Translations.iir.actionFollowUp.performUserName)} />
                <UploadFileList control={itemForm.control} name="evidenceFiles" />
            </Row>
        );
    };

    return (
        <IIRReportItemBaseComponent
            title={lang.text(Translations.iir.evidences.name)}
            formComponent={(data) => <FormComponent data={data} />}
            isReadOnly={props.isReadOnly}
            datas={props.datas}
            formComponentControl={itemForm}
            arrayForm={props.arrayForm}
            columns={[
                {
                    field: 'description',
                    title: lang.text(Translations.iir.evidences.description),
                },
                {
                    field: 'evidenceFiles',
                    title: lang.text(Translations.iir.evidences.evidenceFiles),
                },
                {
                    field: 'action',
                    title: '',
                },
            ]}
        />
    );
}
