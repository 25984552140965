import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, defaultPaginationRequest } from 'api/models/common';
import { TRRoute } from 'api/v2/trip/model/route.model';
import routeApi from 'api/v2/trip/routeApi';
import { AddButton } from 'components/Button';
import { FilterButton } from 'components/Button/FilterButton';
import { CompanySelect } from 'components/Common/CompanySelect';
import useModalHook from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { useQuery } from 'hooks/useQuery';
import { useForm } from 'react-hook-form';
import Routers from 'router/const/routers';

// eslint-disable-next-line react-hooks/exhaustive-deps
const RouteTemplatePage = () => {
    const { query } = useQuery();
    const cols = [
        {
            field: 'displayName',
            title: 'Tên',
        },
        {
            field: 'companyName',
            title: 'Nhà máy',
        },
        {
            field: 'fromPlaceName',
            title: 'Xuất phát',
        },
        {
            field: 'toPlaceName',
            title: 'Điểm đến',
        },
        {
            field: 'action',
            title: '',
        },
    ];
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    var navigate = useNavigate();
    const [paginationData, setPaginationData] = useState<PaginationBase<TRRoute[]>>();
    const { control, handleSubmit, getValues, reset, formState } = useForm();

    useEffect(() => {
        //@ts-ignore
        handleFetchData(query);
    }, [query]);

    const handleFetchData = async (query: any) => {
        routeApi
            .getByIndex(query)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((data) => {
                            return {
                                ...data,
                                fromPlaceName: data?.fromPlace?.displayName,
                                toPlaceName: data?.toPlace?.displayName,
                                action: <ActionButtons2 id={data?.id!} onDelete={onDelete} onViewDetail={() => navigate(`${Routers.trip.route}/${data?.id}`)} viewPms={true} />,
                            };
                        }) ?? [],
                    rowsPerPage: 0,
                    totalItems: 0,
                    totalPage: 0,
                    currentPage: 0,
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    const onDelete = async (id: string) => {
        routeApi.delete(id).then(() => {
            handleFetchData(query);
        });
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <form className="w-full" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <div className="flex justify-between items-center">
                                <CompanySelect
                                    readOnly={false}
                                    disabled={false}
                                    name="companyId"
                                    control={control}
                                    onCompanyChange={(c) => {
                                        defaultPaginationRequest.currentPage = 1;
                                        handleFetchData({
                                            ...defaultPaginationRequest,
                                            companyId: c,
                                        });
                                    }}
                                />
                                <div className="flex justify-end gap-2">
                                    <AddButton isRender={true} onClick={() => navigate(`${Routers.trip.route}/create`)} />
                                    <FilterButton type="submit" />
                                </div>
                            </div>
                        </form>
                    </Card.Body>
                    <PaginationTable
                        onSearch={(pagi) => {
                            defaultPaginationRequest.keySearch = pagi.keySearch;
                            defaultPaginationRequest.currentPage = 1;
                        }}
                        onPaginate={handleFetchData}
                        columns={cols}
                        pagination={paginationData}
                        isStriped={true}
                        disableSearch={false}
                        wrapperClass="mt-5"
                    />
                </Card>
            </div>
            {Prompt}
        </Fragment>
    );
};

export default RouteTemplatePage;
