import { useEffect } from 'react';

import { Checkbox, Col } from 'components/ui-kit/index';
import { Control, Controller, useController } from 'react-hook-form';

export interface CheckBoxOption {
    label?: string;
    value: number | string;
    defaultChecked?: boolean;
    className?: string;
}

export interface CheckBoxFieldProps {
    name: string;
    control: Control<any>;
    label?: string;
    options?: CheckBoxOption[];
    formGroupClasses?: string;
    readonly?: boolean;
    disabled?: boolean;
    defaultValue?: any;
    required?: boolean;
    inline: boolean;
    md: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    orientation?: 'horizontal' | 'vertical';
    onValueChange?: (value: string | number) => void;
}

export function CheckBoxField({
    md,
    xs,
    orientation,
    inline,
    required,
    name,
    control,
    label,
    options,
    formGroupClasses,
    readonly = false,
    disabled = false,
    onValueChange,
    defaultValue,
}: CheckBoxFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });

    const handleChangeOption = (e: any) => {
        if (onValueChange) {
            onValueChange(e);
        }
    };
    useEffect(() => {
        defaultValue && onChange(defaultValue);
    }, [defaultValue]);

    return (
        <Col md={md} sm={xs} className={formGroupClasses}>
            <label>{label && <span className="block">{label}</span>}</label>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required }}
                render={({ field }) => (
                    <div className="flex flex-wrap justify-content-center gap-3">
                        {options?.map((option, index) => (
                            // <div className="flex align-items-center">
                            //     <RadioButton
                            //         inputId={String(option.value)}
                            //         name={name}
                            //         value={option.value}
                            //         onChange={(e) => {
                            //             if (readonly) return;
                            //             field.onChange(option.value);
                            //             handleChangeOption(option.value);
                            //         }}
                            //         checked={field.value === option.value}
                            //     />
                            //     <label htmlFor={String(option.value)} className="ml-2 mr-2">
                            //         {option.label}
                            //     </label>
                            // </div>
                            <div className="flex align-items-center">
                                <Checkbox
                                    readOnly={readonly}
                                    inputId={String(option.value)}
                                    name={name}
                                    value={option.value}
                                    onChange={() => {
                                        field.onChange(option.value);
                                        handleChangeOption(option.value);
                                    }}
                                    checked={field.value.includes(option.value)}
                                />
                                <label htmlFor={String(option.value)} className="ml-2">
                                    {option.value}
                                </label>
                            </div>
                        )) ?? []}
                    </div>
                )}
            />
        </Col>
    );
}
