import { SelectField, SelectOption } from 'components/FormControl';
import { Col, Row } from 'components/ui-kit';
import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SIReportGroupRequest, SIReportItemRequest, SIReportRequest } from 'api/v2/safe-inspection/model/siReport';
import { SITemplateGroupModel } from 'api/v2/safe-inspection/model/siTemplate';
import siReportApi from 'api/v2/safe-inspection/siReportApi';
import siTemplateApi from 'api/v2/safe-inspection/siTemplateApi';
import { SaveButton } from 'components/Button';
import { FilterButton } from 'components/Button/FilterButton';
import { AreaSelect, CompanySelect } from 'components/Common';
import UserSelectField from 'components/Common/UserSelect';
import useModalHook from 'components/ModalHook';
import { SIReportType } from 'enum/safetyInspection';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import SITemplateSelect from '../templates/siTemplateSelect';
import SIGroupContent from './group.component';

const SICreatePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [templateId, setTemplateId] = useState<string | null>(null);
    const [pICId, setPicId] = useState<string | null>(null);
    const [reportType, setReportType] = useState<SIReportType>(SIReportType.Daily);
    const [groups, setGroups] = useState<SIReportGroupRequest[] | null>(null);
    const reportTypes: SelectOption[] = useMemo(
        () => [
            { label: t(Translations.common.dailyReport), value: SIReportType.Daily },
            { label: t(Translations.common.scheduleReport), value: SIReportType.Scheduled },
        ],
        []
    );

    const formCreateRef = useRef<HTMLFormElement>(null);
    const schemaFilter = yup.object().shape({
        areaId: yup.string().required(),
        reportType: yup.string().required(),
        templateId: yup.string().required(),
    });
    const {
        formState: { errors: errrorFilter },
        reset: resetFilter,
        control: controlFilter,
        handleSubmit: handleSubmitFillter,
        getValues: getValuesFilter,
    } = useForm({
        resolver: yupResolver(schemaFilter),
    });

    const { handleSubmit, control, getValues } = useForm({});

    useEffect(() => setGroups([]), [companyId, templateId, reportType]);

    const onLoadTemplate = () => {
        var form = getValuesFilter();
        siTemplateApi
            .getTemplateById(form.templateId)
            .then((temp) => {
                let newGroups: SIReportGroupRequest[] =
                    temp.groups.map((t: SITemplateGroupModel) => {
                        let gr: SIReportGroupRequest = {
                            groupTemplateId: t.id ?? '',
                            groupTemplateName: t.groupName,
                            items: t.items.map((i) => ({
                                isOk: true,
                                itemTemplateId: i.id ?? '',
                                itemTemplateName: i.itemName,
                                imagePathOne: '',
                                imagePathTwo: '',
                                description: '',
                                deadline: null,
                            })),
                        };
                        return gr;
                    }) ?? [];
                setGroups(newGroups);
            })
            .catch((n) => {});
    };
    const SaveChange = () => {
        var filterForm = getValuesFilter();
        if (filterForm.reportType == SIReportType.Daily && !pICId?.hasValue()) {
            toast.error('Nhập người phụ trách');
            return;
        } else if (!companyId || !filterForm.areaId?.hasValue()) {
            toast.error(lang.text(Translations.error.requireCompany));
            return;
        } else if (groups && groups.length > 0) {
            let form = getValues() as any;
            if (!groups) return;
            let newGroups = [...groups];
            for (let i = 0; i < newGroups.length; i++) {
                const group = newGroups[i];
                let pICId = form[generageName(group.groupTemplateId, '', 'pICId')];
                if (reportType == SIReportType.Scheduled && !pICId) {
                    toast.error('Nhập người phụ trách ' + group.groupTemplateName);
                    return;
                }
                group.pICId = pICId;
                for (let j = 0; j < group.items.length; j++) {
                    const item = group.items[j];
                    item.isOk = form[generageName(group.groupTemplateId, item.itemTemplateId, 'isOk')] == 1 ? true : false;
                    if (item.isOk) {
                        item.imagePathOne = undefined;
                        item.imagePathTwo = undefined;
                        item.description = undefined;
                        item.deadline = null;
                    } else {
                        item.imagePathOne = form[generageName(group.groupTemplateId, item.itemTemplateId, 'imagePathOne')];
                        item.imagePathTwo = form[generageName(group.groupTemplateId, item.itemTemplateId, 'imagePathTwo')];
                        item.description = form[generageName(group.groupTemplateId, item.itemTemplateId, 'description')];
                        item.deadline = form[generageName(group.groupTemplateId, item.itemTemplateId, 'deadline')];
                    }
                }
            }

            var requestPayload: SIReportRequest = {
                areaId: filterForm.areaId,
                companyId: companyId ?? '',
                pICId: pICId,
                reportType: filterForm.reportType as SIReportType,
                templateId: filterForm.templateId,
                groups: newGroups.map((g) => {
                    let gr: SIReportGroupRequest = {
                        ...g,
                        // groupTemplateId: g.groupTemplateId,
                        // groupTemplateName: g.groupTemplateName,
                        // closedDescription: g.closedDescription,
                        // pICId: g.pICId,
                        items: g.items.map((i) => {
                            let it: SIReportItemRequest = {
                                ...i,
                                // isOk: i.isOk,
                                // itemTemplateId: i.itemTemplateId,
                                // itemTemplateName: i.itemTemplateName,
                                // imagePathOne: i.imagePathOne,
                                // imagePathTwo: i.imagePathTwo,
                                // description: i.description,
                                // deadline: i.deadline,
                            };
                            return it;
                        }),
                    };

                    return gr;
                }),
            };

            siReportApi
                .create(requestPayload)
                .then((n) => {
                    resetFilter({});
                    setGroups([]);
                })
                .catch((n) => {});
        }
    };
    const generageName = (groupTemplateId: string, itemTemplateId: string, name: string) => {
        return `gr-${groupTemplateId}-item-${itemTemplateId}-${name}`;
    };
    // const getName = (groupTemplateId: string, itemTemplateId: string, name: string) => {
    //     return `gr-${groupTemplateId}-item-${itemTemplateId}-${name}`;
    // };
    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <form onSubmit={handleSubmitFillter(onLoadTemplate)}>
                                <Row>
                                    <SelectField
                                        label={t(Translations.common.reportType)}
                                        control={controlFilter}
                                        name="reportType"
                                        options={reportTypes}
                                        md={4}
                                        xs={12}
                                        onValueChange={(value) => {
                                            setReportType(value);
                                        }}
                                    />

                                    <CompanySelect name="companyId" control={controlFilter} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                                    <AreaSelect name="areaId" control={controlFilter} required={true} companyId={companyId} readOnly={false} />

                                    <SITemplateSelect companyId={companyId} control={controlFilter} name="templateId" reportType={reportType} md={4} onValueChange={setTemplateId} />

                                    {reportType && reportType.toString() === SIReportType.Daily && (
                                        <UserSelectField
                                            readOnly={false}
                                            label={t(Translations.common.pic)}
                                            name="pICId"
                                            control={controlFilter}
                                            required={true}
                                            onValueChange={(n) => {
                                                setPicId(n);
                                            }}
                                            md={4}
                                            xs={12}
                                        />
                                    )}
                                </Row>
                                <div className="flex justify-end">
                                    <FilterButton type="submit" text={lang.text(Translations.form.safetyInspections.loadTemplate)} />
                                    &nbsp;
                                    <SaveButton
                                        type="button"
                                        onClick={() => {
                                            SaveChange();
                                        }}
                                    />
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <form onSubmit={handleSubmit(SaveChange)} ref={formCreateRef}>
                        <Card>
                            <Card.Body>
                                {groups?.map((group) => (
                                    <Row>
                                        <Col>
                                            <SIGroupContent
                                                generageName={generageName}
                                                control={control}
                                                readonly={false}
                                                key={group.groupTemplateId}
                                                group={group}
                                                showPIC={reportType == SIReportType.Scheduled}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </Card.Body>
                        </Card>
                    </form>
                </Col>
            </Row>
            {Prompt}
        </Fragment>
    );
};
export default SICreatePage;
