import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import kpiTypeApi from 'api/v2/kpi/kpiType';

export interface KPITypeSelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    control: Control<any>;
    companyId?: string | null;
    isRequiredCompany?: boolean;
}
export function KPITypeSelect({ name = 'kpiType', companyId, control, required, disable, isRequiredCompany = false, md = 4, xs = 12, onValueChange, readOnly }: KPITypeSelectProps) {
    const { currentCustomer } = useCustomer();
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadDatas();
    }, [currentCustomer, companyId]);
    useEffect(() => {
        loadDatas();
    }, []);
    const loadDatas = () => {
        if (isRequiredCompany && !companyId) {
            setOptions([]);
            return;
        }
        kpiTypeApi
            .getDropdown(companyId ?? '')
            .then((res) => {
                setOptions(res);
            })
            .catch(() => {});
    };

    return (
        <SelectField
            disable={disable}
            readOnly={disable}
            label={t(Translations.kpis.type)}
            options={options}
            name={name}
            control={control}
            required={required}
           
            md={md}
            xs={xs}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default KPITypeSelect;
