//react-bootstrap
import { Badge } from 'components/ui-kit';

//components
import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';
import { defaultPaginationRequest, PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { OrganizationUnitResponse } from 'api/models/organization';
import orgUnitApi from 'api/v2/organizationUnitApi';
import { AddButtonNavigate } from 'components/Button';
import { BaseButton } from 'components/Button/BaseButton';
import ImageWithDefault from 'components/bootstrap/ImageWithDefault';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { TableColumn } from 'components/ui-kit';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Routers from 'router/const/routers';

type ActionType = 'delete' | 'update';

const ConfigCompanyPage = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userPms } = useAuth();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const [selectedItem, setSelectedItem] = useState<{
        type: ActionType | '';
        id: string;
    } | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [updateName, setUpdateName] = useState('');

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);

    const handleFetchData = (paginate?: PaginationBaseRequest) => {
        if (!paginate) paginate = defaultPaginationRequest;
        orgUnitApi
            .getByCurrentCustomer(paginate, OrganizationUnitType.Company)
            .then((result: PaginationBase<OrganizationUnitResponse>) => {
                var dt = {
                    ...result,
                    datas: result.datas?.map((item) => ({
                        ...item,
                        isActive: item.isActive ? <Badge color="success">{t(Translations.common.show)}</Badge> : <Badge color="warning">{t(Translations.common.hide)}</Badge>,
                        logo: <ImageWithDefault className=" avatar-40" src={item.logo ?? undefined} />, // <ImageDisplay src={item.logo} />,
                        action: (
                            <ActionButtons2
                                id={item.id}
                                editAction={onEdit}
                                editPms={userPms.orgAdmin}
                                deletePms={userPms.orgAdmin}
                                onDelete={(id: string) => onDelete(id)}
                                customActions={<BaseButton icon={'pi pi-lock'} onClick={() => onActiveToggle(item.id, !item.isActive)} color="warning" size="sm"></BaseButton>}
                            />
                        ),
                    })),
                };
                setPaginationData(dt);
            })
            .catch(() => {});
    };

    const handleAccept = () => {
        if (selectedItem && selectedItem.type === 'delete') {
        }

        if (selectedItem && selectedItem.type === 'update') {
            orgUnitApi
                .update({ id: selectedItem.id, name: updateName })
                .then(() => handleFetchData())
                .catch((n) => {})
                .finally(() => setOpenModal(false));
        }
    };

    const cols: TableColumn[] = useMemo(
        () => [
            {
                field: 'logo',
                header: 'Logo',
                style: {
                    width: '50px',
                },
            },
            {
                field: 'name',
                header: t(Translations.form.site.name),
            },
            {
                field: 'isActive',
                header: t(Translations.common.status),
            },
            {
                field: 'action',
                header: '',
            },
        ],
        []
    );
    const onEdit = (id: string) => {
        navigate(Routers.config.companyEdit.replace(':id', id));
    };

    const onDelete = async (id: string) => {
        return await orgUnitApi
            .delete(id)
            .then(() => handleFetchData())
            .catch((n) => {})
            .finally(() => setOpenModal(false));
    };
    const onActiveToggle = (id: string, isActive: boolean) => {
        orgUnitApi.toggleActive(id, isActive).then(() => handleFetchData(defaultPaginationRequest));
    };

    return (
        <Card>
            <Card.Body>
                <div className="text-end w-full flex justify-end items-center">
                    <AddButtonNavigate url={Routers.config.companyCreate} isRender={userPms.orgAdmin} />
                </div>
            </Card.Body>
            <PaginationTable disableSearch={true} onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} wrapperClass="mt-5" />
        </Card>
    );
});

export default ConfigCompanyPage;
