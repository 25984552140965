import { Modal } from 'components/ui-kit';
import { MODAL_BOX_CLASSNAME } from 'constants/common';
import { getCurrentLayout, LAYOUT_TYPE } from 'hooks/useLayout';
import { ReactNode } from 'react';

interface ModalFullScreenProps {
    title?: string;
    showModal: boolean;
    handleCloseModal: () => void;
    bodyModal: ReactNode;
    footerModal?: ReactNode;
    scrollable?: boolean;
}
export function ModalFullScreen({ showModal, handleCloseModal, bodyModal, footerModal, title }: ModalFullScreenProps) {
    const layout = getCurrentLayout();
    console.log(layout);
    return (
        <Modal header={title} className={layout === LAYOUT_TYPE.BOX ? MODAL_BOX_CLASSNAME : ''} maximized={true} visible={showModal} onHide={handleCloseModal}>
            <Modal.Body>{bodyModal}</Modal.Body>
            <Modal.Footer>{footerModal}</Modal.Footer>
        </Modal>
    );
}
