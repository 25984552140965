import fileApi from 'api/fileApi';
import { FileResponse } from 'api/models';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import DataListView, { DataListViewItem } from 'components/shared/DataListView';
import { Col } from 'components/ui-kit';
import { FileType } from 'enum/file';
import { generateGUID, mappingFilePath } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import React, { CSSProperties, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';

export type UploadFileFieldProps = {
    defaultFilePath?: string;
    defaultFileName?: string;
    name: string;
    text?: string;
    control: Control<any>;
    className?: string;
    altText?: string;
    btnText?: string;
    src?: string | null;
    readOnly?: boolean;
    onUploaded?: (value: FileResponse) => void;
    onValueChange?: (value: string) => void;
    accepts?: string;
    md?: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    xs?: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    style?: CSSProperties;
};

export function UploadFileField({
    defaultFileName,
    defaultFilePath,
    name,
    control,
    onValueChange,
    className = '',
    md = 4,
    xs = 12,
    btnText,
    text,
    src,
    readOnly = false,
    onUploaded,
    accepts = '.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .jpg, .png, .heic',
}: UploadFileFieldProps) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (defaultFilePath != value) {
            onChange(defaultFilePath);
            onValueChange && onValueChange(defaultFilePath ?? value);
            onUploaded &&
                onUploaded({
                    fileName: defaultFileName ?? defaultFileName ?? '',
                    filePath: defaultFilePath ?? '',
                    size: 0,
                    extension: '',
                    fileType: FileType.None,
                });
        }
        if (defaultFilePath != value) {
            onChange(defaultFilePath);
        }
    }, [defaultFileName, defaultFilePath]);

    const inValidClass = error?.message ? 'is-invalid' : '';
    const id = useMemo(() => generateGUID(), []);
    const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            fileApi
                .uploadFile(formData)
                .then((res) => {
                    if (res) {
                        onChange(res.filePath);
                        onUploaded && onUploaded(res);
                        onValueChange && onValueChange(res.filePath);
                    }
                })
                .catch((err) => {});
            // Use the formData object for further processing
            // Perform upload logic here
        }
    };

    return (
        <Col sm={xs} md={md} className={`${className} text-start`}>
            {text && <label>{text ?? lang.text(Translations.common.upload)}</label>}
            <input ref={fileInputRef} name={name} accept={accepts} onChange={handleUploadFile} type="file" id={id} style={{ maxWidth: '266px' }} className={` ${inValidClass}`} />
            <div className="invalid-feedback">{error?.message}</div>
        </Col>
    );
}

export default UploadFileField;

const UploadFileList = (props: { control: Control<any>; name: string }) => {
    const [files, setFiles] = useState<FileResponse[]>([]);
    const [displayItems, setDisplayItems] = useState<DataListViewItem[]>([]);
    const {
        field: { value, onChange, onBlur },
    } = useController({
        control: props.control,
        name: props.name,
    });
    useEffect(() => {
        const items = files.map((file, i) => {
            return {
                label: (
                    <a href={mappingFilePath(file.filePath)} target="_blank">
                        {file.fileName}
                    </a>
                ),
                value: (
                    <ActionButtons2
                        id=""
                        isConfirmDelete={false}
                        deletePms={true}
                        onDelete={(id) => {
                            setFiles(files.filter((f) => f.filePath != file.filePath));
                            return Promise.resolve();
                        }}
                    />
                ),
            };
        });
        onChange(files.map((f) => f.filePath));
        setDisplayItems(items);
    }, [files]);

    return (
        <Fragment>
            <UploadFileField
                control={props.control}
                name={props.name}
                onUploaded={(file) => {
                    if (file) setFiles([...files, file]);
                }}
            />
            <hr />
            <DataListView
                firstColumnStyle={{
                    width: '90%',
                }}
                datas={displayItems}
            />
        </Fragment>
    );
};
export { UploadFileList };
