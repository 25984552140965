import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';
import { Col, TreeNodeItem, TreeSelect } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import _ from 'lodash';
import { useCustomer } from 'providers/contexts';
import React, { useEffect, useState } from 'react';
import {
    Control,
    useController
} from 'react-hook-form';

interface OrganizationOption {
    id: string;
    groupCompany: string;
    name: string;
    parentId: string;
    type: OrganizationUnitType;
}

interface OrganizationMultiSelectProps {
    onSelected?: (orgIds: string[]) => void;
    control?: Control<any>;
    name: string;
    required?: boolean;
    readonly?: boolean;
    disable?: boolean;
    md?: 3 | 4 | 6 | 12;
    xs?: 3 | 4 | 6 | 12;
}
const OrganizationUnitMultiSelect: React.FC<OrganizationMultiSelectProps> = ({ name, control, onSelected, required = false, readonly = false, disable = false, md = 3, xs = 12 }) => {
    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
        formState: { errors },
    } = useController({
        name,
        control,
        defaultValue: [],
    });
    const [nodes, setNodes] = useState<TreeNodeItem[]>([]);
    const [selectedValues, setSelectedValues] = useState<any[]>([]);
    const { currentCustomer } = useCustomer();

    useEffect(() => {
        let keys = Object.keys(selectedValues ?? {}) ?? [];
        let ids = _.uniq(keys);
        onSelected && onSelected(ids);
    }, [selectedValues]);

    useEffect(() => {
        let currentNodes: TreeNodeItem[] =
            currentCustomer?.companies.map((n) => {
                return {
                    id: n.id,
                    key: n.id,
                    label: n.name,
                    data: n.id,
                    children: n.areas.map((m) => {
                        return {
                            id: m.id,
                            key: m.id,
                            label: m.name,
                            data: m.id,
                        };
                    }),
                };
            }) ?? [];
        setNodes(currentNodes);
    }, []);

    useEffect(() => {}, [value]);

    return (
        <Col md={md} sm={xs ?? 6}>
            <div className="flex-auto">
                <label className="block">
                    {lang.text(Translations.common.organization)} {required && <span className="text-danger">*</span>}
                </label>
                <TreeSelect
                    invalid={invalid}
                    showClear={!readonly && !disable}
                    required={required}
                    readOnly={readonly}
                    disabled={disable}
                    value={selectedValues}
                    onChange={(e) => {
                        setSelectedValues(e.value as any[]);
                    }}
                    options={nodes}
                    metaKeySelection={true}
                    selectionMode="checkbox"
                    display="chip"
                    color="primary"
                    className="w-full"
                    placeholder="-- select --"
                    filter={false}
                ></TreeSelect>
                {invalid && <div className="invalid-feedback  d-block ">{error?.message}</div>}
            </div>
        </Col>
    );
};

export default OrganizationUnitMultiSelect;
