import { LocationModel } from 'api/models/common/locationModel';
import locationApi from 'api/v2/location';
import { Col } from 'components/ui-kit';
import { generateGUID } from 'helper';
import { debounce } from 'helper/debounce';
import useMyLocation from 'hooks/useMyLocation';
import * as React from 'react';
import { useController } from 'react-hook-form';
import { SelectOption } from '../SelectField';
import SelectSearchField from '../SelectSearchField';
import { BaseFormControlProps } from '../baseFormProps';

export interface LocationModelFieldProps extends BaseFormControlProps {
    placeholder?: string;
    onValueChange?: (value?: LocationModel) => void;
    index?: number; // use for error message is array
}

export function LocationField({ name, control, label, index, required = false, md = 12, onValueChange, disable, readOnly }: LocationModelFieldProps) {
    const [locationOptions, setLocationsOptions] = React.useState<LocationModel[]>([]);
    const [options, setOptions] = React.useState<SelectOption[]>([]);
    const [loading, setLoading] = React.useState(false);
    const selectedRef = React.useRef(null);
    const myLocation = useMyLocation();

    const {
        field: { value, onChange },
        fieldState: { invalid, error },
        formState: { errors },
    } = useController({
        name,
        control,
        rules: required ? { required: 'This field is required' } : {},
    });

    const searchOptions = (search: string) => {
        if (search) {
            setLoading(true);
            locationApi
                .search(search)
                .then((res: LocationModel[]) => {
                    const locations = res.map((n) => ({ ...n, id: generateGUID() }));
                    setLocationsOptions(locations);
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setLocationsOptions([]);
        }
    };

    React.useEffect(() => {
        const newOptions: SelectOption[] = locationOptions.map((l) => ({
            label: l.displayName,
            value: l.id ?? '',
        }));
        setOptions(newOptions);
    }, [locationOptions]);

    React.useEffect(() => {
        if (!value?.displayName) return;
        //@ts-ignore
        selectedRef.current.selected = value?.displayName ? [{ label: value?.displayName, value: value?.displayName }] : [];
        //@ts-ignore
        selectedRef.current.state.selected = value?.displayName ? [{ label: value?.displayName, value: value?.displayName }] : [];
        //@ts-ignore
        selectedRef.current.inputNode.value = value?.displayName;
    }, [value, selectedRef.current]);

    const handleSearch = React.useCallback(debounce(searchOptions, 1000), []);

    return (
        <Col md={md} className={`mt-1`}>
            <label className="block">{label}</label>
            <SelectSearchField
                control={control}
                name={name}
                onSearch={(inputValue) => {
                    if (!inputValue) {
                        setLoading(false);
                        handleSearch('');
                    } else {
                        setLoading(true);
                        handleSearch(inputValue);
                    }
                }}
                options={options}
            />

            {/* <div className={`flex `}>
                <Typeahead
                    // onSearch={() => {}}
                    ref={selectedRef}
                    filterBy={() => true} // Show all options
                    id={name}
                    isLoading={loading}
                    labelKey="label"
                    onInputChange={(inputValue) => {
                       
                    }}
                    onChange={(selected) => {
                        //@ts-ignore
                        const selectedLocation = locationOptions.find((l) => l.id === selected?.[0]?.value);
                        onValueChange?.(selectedLocation);
                        onChange(selectedLocation);
                    }}
                    options={options}
                    placeholder="Search location"
                    disabled={disable || readOnly}
                    inputProps={{
                        className: `${errors[name]?.message && 'is-invalid'} form-control`,
                    }}
                    className="my-location-select"
                    style={{ width: '100%' }}
                />
                <BaseBu
                    disabled={!myLocation || readOnly}
                    onClick={() => {
                        onValueChange?.(myLocation);
                        onChange(myLocation);
                    }}
                    variant="link"
                    className={classNames('my-location-btn')}
                >
                    <GeoAltFill />
                </BaseBu>
            </div> */}
            {error?.message && <div className="invalid-feedback d-block">{String(error?.message)}</div>}
        </Col>
    );
}
