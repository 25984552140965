import { TripDetailResponse, TripStatus } from 'api/v2/trip/model/trip.model';
import tripApi from 'api/v2/trip/tripApi';
import TimelineComponent, { TimelineItem } from 'components/Common/TimeLine';
import InputFieldSimple from 'components/FormControl/InputFieldSimple';
import usePromptInputText from 'components/Modal/PromptInputText';
import useModalHook from 'components/ModalHook';
import { Card, Tab } from 'components/ui-kit';
import { Row } from 'components/ui-kit/index';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { TabPanel } from 'primereact/tabview';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TripButton from './components/actionButton.component';
import TripRequestChangeRoute from './requestChangeRoute';
import TripCreatePage from './tripCreatePage';

const TripDetailPage = () => {
    const { id } = useParams();
    const [detail, setDetail] = useState<TripDetailResponse | null>(null);
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    //'trip' | 'tdtt' | 'history'
    const [activeKey, setActiveKey] = useState<number>(0);
    const [histories, setHistories] = useState<TimelineItem[]>([]);
    const onRequestChangeRoute = async () => {
        var result = await openModal({
            title: lang.text(Translations.trip.changeRouteRequest),
            body: <TripRequestChangeRoute detail={detail!.requestChangeRoute!} onClose={onClose} onCompleted={onCompleted} />,
        });
        if (result === true) await getTripDetail(id!);
    };
    const onRejectChangeRoute = async () => {
        detail?.requestChangeRoute?.id &&
            tripApi
                .rejectChangeRoute(detail?.requestChangeRoute.id)
                .then(() => getTripDetail(id!))
                .catch(() => {});
    };
    const onApproveChangeRoute = async () => {
        detail?.requestChangeRoute?.id &&
            tripApi
                .approveChangeRoute(detail?.requestChangeRoute.id)
                .then(() => getTripDetail(id!))
                .catch(() => {});
    };
    const onChangeStatus = async (status: TripStatus) => {
        const text: string = await openPromptInputText({
            title: lang.text(Translations.common.description),
            defaultValue: '',
        });

        text != null &&
            tripApi
                .updateStatus({
                    description: text,
                    status: status,
                    tripId: id!,
                })
                .then(() => getTripDetail(id!))
                .catch(() => {});
    };
    useEffect(() => {
        id && getTripDetail(id);
    }, []);

    useEffect(() => {
        detail?.histories &&
            setHistories(
                detail.histories.map((item) => {
                    var rs: TimelineItem = {
                        date: item.createDate,
                        title: item.crudActionType,
                        description: item.content,
                        createdFulName: item.createUserName,
                    };
                    return rs;
                })
            );
    }, [detail]);
    // export interface TimelineItem {
    //     title: string;
    //     description?: any;
    //     date: Date;
    //     createdFulName?: string;
    // }
    const getTripDetail = (id: string) => {
        tripApi
            .getById(id)
            .then((res: TripDetailResponse) => {
                setDetail(res);
            })
            .catch(() => {});
    };
    return !detail ? (
        <div></div>
    ) : (
        <Fragment>
            <Tab activeIndex={activeKey} onTabChange={(k) => setActiveKey(k.index)} className="mb-3">
                <TabPanel header={lang.text(Translations.common.generalInfo)}>
                    <Card>
                        <Card.Header>
                            <div className="flex justify-end">
                                <TripButton
                                    onApproveChangeRoute={onApproveChangeRoute}
                                    onRejectChangeRoute={onRejectChangeRoute}
                                    onRequestChangeRoute={onRequestChangeRoute}
                                    onChangeStatus={onChangeStatus}
                                    trip={{ ...detail, id: detail.id }}
                                />
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <InputFieldSimple label={lang.text(Translations.common.status)} md={4} xs={12} disabled value={detail.status} />
                                <InputFieldSimple label={lang.text(Translations.common.createdBy)} md={4} xs={12} disabled value={detail.createdUserName} />
                                <InputFieldSimple label={lang.text(Translations.common.createdTime)} md={4} xs={12} disabled value={formatDateTime(detail.createdTime)} />
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row>
                        <TripCreatePage detail={detail} />
                    </Row>
                </TabPanel>
                <TabPanel header={lang.text(Translations.trip.changeRouteRequest)}>
                    <Card>
                        <Card.Body>
                            <TripRequestChangeRoute isReadOnly={true} detail={detail!.requestChangeRoute!} onClose={onClose} onCompleted={onCompleted} />
                        </Card.Body>
                    </Card>
                </TabPanel>
                <TabPanel header={lang.text(Translations.common.history)}>
                    <Card>
                        <Card.Body>
                            <TimelineComponent title={lang.text(Translations.common.history)} items={histories} />
                        </Card.Body>
                    </Card>
                </TabPanel>
            </Tab>
            {Prompt}   {PromptInputText}
        </Fragment>
    );
};
export default TripDetailPage;
