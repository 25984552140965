import Color from 'enum/color';
import Translations from 'i18n/translation';
import { MouseEventHandler } from 'react';
import { CheckAll } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { BaseButton } from './BaseButton';
import { BaseButtonProps } from './BaseButton.props';

export interface CloseButtonProps {
    onClick?: MouseEventHandler;
    disable?: boolean;
    text?: string;
}

export function CloseButton(props: BaseButtonProps) {
    const { t } = useTranslation();

    var isRender = props.isRender ?? true;
    if (props.isRender === undefined) isRender = true;
    return <BaseButton {...props} isRender={isRender} icon={<CheckAll />} color={Color.primary} text={props.text ? props.text : t(Translations.button.closeReport)} />;
}
