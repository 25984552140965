import useMenu from 'hooks/useMenu';
import { PanelMenu } from 'primereact/panelmenu';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PrimeMenu = () => {
    const { userMenus } = useMenu();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [expandedKey, setExpandedKey] = useState('');

    useEffect(() => {
        setExpandedKey(userMenus.find((group) => group.items.some((item) => window.location.pathname.startsWith(item.path)))?.key!);
    }, [userMenus]);

    const handleSelect = (item: any) => {
        if (item.url) {
            navigate(item.url);
            return;
        }
        setExpandedKey(item?.key);
    };

    const renderMenu = (item: any, options: any) => {
        const isLeaf = options.leaf;
        const isActive = window.location.pathname === item.url;
        return (
            <div
                className={classNames(isLeaf && 'pl-5', isActive && 'bg-green-100/80', 'px-2 flex items-center justify-between gap-2 cursor-pointer h-10 prime-menu-custom')}
                onClick={() => {
                    handleSelect(item);
                }}
            >
                <div className={classNames('flex items-center gap-2 w-full')}>
                    <div className="w-5 flex justify-center h-5 items-center">{item.icon}</div>
                    {t(item.title || item.label)}
                </div>
                <div className={classNames(isLeaf ? 'hidden' : 'block')}>{isActive ? <ChevronDown className="!w-3" /> : <ChevronRight className="!w-3" />}</div>
            </div>
        );
    };

    const formattedMenu = userMenus.map((group) => {
        return {
            key: group.key,
            label: t(group.groupName),
            template: renderMenu,
            icon: group.groupIcon,
            items: group.items.map((item) => {
                return {
                    icon: item.icon,
                    title: item.title,
                    template: renderMenu,
                    url: item.path,
                };
            }),
        };
    });

    return (
        <div className="h-[calc(100vh_-_90px)] overflow-y-auto px-2 pr-1">
            <PanelMenu expandedKeys={{ [expandedKey]: true }} model={formattedMenu}></PanelMenu>
        </div>
    );
};

export default PrimeMenu;
