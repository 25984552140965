// Redux Selector / Action

import { ModalComponent } from 'components/Modal';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';

import { CustomerInfraResponse } from 'api/models/organization';
import { Button, ListBox } from 'components/ui-kit';
import { lang } from 'i18n/lang';

const ModalSelectCustomer = (props: { currentCustomerId: string | null; onSelectCustomer: (cus: CustomerInfraResponse) => void; customers: CustomerInfraResponse[] }) => {
    const [activeCustomerId, setActiveCustomerId] = useState<string | null>(null);
    useEffect(() => {
        setActiveCustomerId(props.currentCustomerId);
    }, []);

    const handleChooseCustomer = () => {
        if (activeCustomerId) {
            let selectedCustomer = props.customers.find((n) => n.id === activeCustomerId);
            selectedCustomer && props.onSelectCustomer(selectedCustomer);
        }
    };

    return (
        <ModalComponent
            titleModal={lang.text(Translations.admin.selectCustomer)}
            showModal={true}
            closeButton={false}
            handleCloseModal={() => {}}
            scrollable={true}
            bodyModal={
                <ListBox
                    options={props.customers?.map((option) => ({
                        label: option.name,
                        value: option.id,
                    }))}
                    value={activeCustomerId}
                    onChange={(e) => setActiveCustomerId(e.value)}
                ></ListBox>
            }
            footerModal={
                <>
                    <Button onClick={handleChooseCustomer}>Chọn</Button>
                </>
            }
        />
    );
};

export default ModalSelectCustomer;
