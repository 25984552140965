import { SITemplateGroupModel, SITemplateItemModel } from 'api/v2/safe-inspection/model/siTemplate';
import { AddButton, DeleteButton } from 'components/Button';
import { BaseButton } from 'components/Button/BaseButton';
import { InputField } from 'components/FormControl';
import { Table } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { AccordionTab } from 'primereact/accordion';
import { Fragment, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { Control } from 'react-hook-form';

const SiGroupTemplateComponent = ({
    group,
    control,
    onDeleteGroup,
    onAddItem,
    index,
    onDeleteItem,
}: {
    group: SITemplateGroupModel;
    control: Control<any>;
    index: any;
    onDeleteGroup: (group: SITemplateGroupModel) => void;
    onAddItem: (group: SITemplateGroupModel) => void;
    onDeleteItem: (group: SITemplateGroupModel, item: SITemplateItemModel) => void;
}) => {
    const [groupName, setGroupName] = useState<string>('');
    return (
        <AccordionTab header={groupName?.hasValue() ? groupName : 'Nhóm danh mục'} key={group.id} className="">
            <Fragment>
                <Table className="table-striped table-sm">
                    <tbody>
                        <tr>
                            <td className="w-full">
                                <InputField
                                    md={12}
                                    name={`group-${group.id}`}
                                    control={control}
                                    value={group.groupName}
                                    defaultValue={group.groupName}
                                    placeholder={lang.text(Translations.common.name)}
                                    required={true}
                                    onValueChange={setGroupName}
                                    label={lang.text(Translations.form.safetyInspections.categoryGroup)}
                                />
                            </td>
                            <td className="flex">
                                <DeleteButton text="" onClick={() => onDeleteGroup(group)} />
                                <AddButton isRender={true} onClick={() => onAddItem(group)} />
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <Table className="table-striped table-sm">
                    <tbody>
                        {group.items &&
                            group.items.map((item) => (
                                <tr key={item.id}>
                                    <td className="w-full">
                                        <InputField md={12} name={`group-${group.id}-item-${item.id}`} control={control} defaultValue={item.itemName} required={true} value={item.itemName} />
                                    </td>
                                    <td>
                                        <div>
                                            <BaseButton
                                                onClick={() => {
                                                    onDeleteItem(group, item);
                                                }}
                                                className="icon btn-sm btn-danger rounded"
                                            >
                                                <Trash />
                                            </BaseButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Fragment>
        </AccordionTab>
    );
};
export default SiGroupTemplateComponent;
