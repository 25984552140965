import { TNCourseCreateRequest, TNCourseQuestion, TNCourseQuestionAnswer } from 'api/v2/training/models/tnCourse.model';
import { AddButton, DeleteButton } from 'components/Button';
import { InputField, SelectField, TextAreaField } from 'components/FormControl';
import { Row } from 'components/ui-kit';
import { TNQuestionType } from 'enum/training';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment } from 'react';
import { Control, useFieldArray, UseFormWatch } from 'react-hook-form';

interface TNCourseQuestionProps {
    control: Control<TNCourseCreateRequest>;
    isReadOnly?: boolean;
    watch: UseFormWatch<TNCourseCreateRequest>;
}

export const QUESTION_TYPES = [
    {
        label: 'Trắc nghiệm',
        value: TNQuestionType.Experiment,
    },
    {
        label: 'Tự luận',
        value: TNQuestionType.Foreword,
    },
    {
        label: 'Trắc nghiệm nhiều đáp án',
        value: TNQuestionType.Mutiple,
    },
];

const QUESTION_TEMPLATE: TNCourseQuestion = {
    quizType: TNQuestionType.Foreword,
    question: '',
    correctAnswer: '',
    answers: [],
};

export const TNCourseQuestionComponent = ({ control, isReadOnly, watch }: TNCourseQuestionProps) => {
    const {
        fields: fieldQuestions,
        append: appendQuestions,
        remove: removeQuestions,
        update: updateQuestions,
    } = useFieldArray({
        control,
        name: 'questions',
    });

    const questions = watch('questions');

    return (
        <Fragment>
            {fieldQuestions.map((question, index) => {
                return (
                    <div key={index} style={{ borderTop: index !== 0 ? '1px solid #ddd' : '' }} className="mb-4 pt-4">
                        <Row>
                            <SelectField
                                readOnly={isReadOnly}
                                md={4}
                                xs={12}
                                label={lang.text(Translations.training.questionType)}
                                control={control}
                                name={`questions.${index}.quizType`}
                                options={QUESTION_TYPES}
                                disabled={isReadOnly}
                                onValueChange={(e) => {
                                    updateQuestions(index, { ...question, quizType: e as TNQuestionType });
                                }}
                            />
                            <InputField md={12} xs={12} name={`questions.${index}.question`} control={control} label={`Câu hỏi #${index + 1}`} readOnly={isReadOnly} />

                            <TextAreaField rows={2} md={12} xs={12} name={`questions.${index}.description`} control={control} label={'Mô tả'} readOnly={isReadOnly} />
                            {questions?.[index]?.quizType === TNQuestionType.Foreword && (
                                <TextAreaField rows={2} md={12} xs={12} name={`questions.${index}.correctAnswer`} control={control} label={'Câu trả lời đúng'} readOnly={isReadOnly} />
                            )}
                            {questions?.[index]?.quizType === TNQuestionType.Experiment && <TNCourseTNAnswer watch={watch} control={control} index={index} isReadOnly={isReadOnly} />}
                            {questions?.[index]?.quizType === TNQuestionType.Mutiple && <TNCourseTNMultiAnswer watch={watch} control={control} index={index} isReadOnly={isReadOnly} />}
                            <div className="flex justify-end mt-3" style={{ marginRight: '-0.5rem' }}>
                                <DeleteButton onClick={() => removeQuestions(index)} text="Xóa câu hỏi" isRender={!isReadOnly} />
                            </div>
                        </Row>
                    </div>
                );
            })}
            <AddButton isRender={!isReadOnly} type="button" text="Thêm câu hỏi" onClick={() => appendQuestions(QUESTION_TEMPLATE)} />
            {/* <Button
                onClick={() => {
                    appendQuestions(QUESTION_TEMPLATE);
                }}
                size="sm"
            >
                Thêm câu hỏi
            </Button> */}
        </Fragment>
    );
};

interface TNCourseTNAnswerProps {
    control: Control<TNCourseCreateRequest>;
    index: number;
    isReadOnly?: boolean;
    watch: UseFormWatch<TNCourseCreateRequest>;
}

const TN_ANSWER_TEMPLATE: TNCourseQuestionAnswer = {
    isCorrect: false,
    content: '',
};

/**Câu hỏi nhiều đáp án */
const TNCourseTNAnswer = ({ control, index, isReadOnly, watch }: TNCourseTNAnswerProps) => {
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: `questions.${index}.answers`,
    });

    const answers = watch(`questions.${index}.answers`);

    const handleChange = (e: boolean, answerIndex: number) => {
        answers?.forEach((answer: any, _idx: any) => {
            update(_idx, {
                ...answer,
                isCorrect: _idx === answerIndex ? e : false,
            });
        });
    };

    return (
        <div>
            <div className="flex">
                <label>Đáp án (Chọn 1 đáp án đúng) </label>
                <AddButton onClick={() => append(TN_ANSWER_TEMPLATE)} type="button" isRender={!isReadOnly} />
            </div>
            {fields.map((_, answerIndex) => {
                return (
                    <div className="flex items-center">
                        <input
                            defaultChecked={answers?.[answerIndex]?.isCorrect}
                            disabled={isReadOnly}
                            name={`questions.${index}.answers`}
                            onChange={(e) => handleChange(e.target.checked, answerIndex)}
                            type={'radio'}
                            required
                        />
                        <InputField className="w-full" formGroupClasses={'mt-3'} name={`questions.${index}.answers.${answerIndex}.content`} control={control} readOnly={isReadOnly} />
                        <DeleteButton onClick={() => remove(answerIndex)} isRender={!isReadOnly}></DeleteButton>
                    </div>
                );
            })}
            <div className="flex justify-end"></div>
        </div>
    );
};

/**Câu hỏi nhiều đáp án */
const TNCourseTNMultiAnswer = ({ control, index, isReadOnly, watch }: TNCourseTNAnswerProps) => {
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: `questions.${index}.answers`,
    });

    const answers = watch(`questions.${index}.answers`);

    const handleChange = (e: boolean, answerIndex: number) => {
        update(answerIndex, {
            ...(answers || [])[answerIndex],
            isCorrect: e,
        });
    };

    return (
        <div>
            <div className="flex">
                <label>Đáp án (Chọn ít nhất 1 đáp án đúng) </label>
                <AddButton onClick={() => append(TN_ANSWER_TEMPLATE)} type="button" isRender={!isReadOnly} />
            </div>

            {fields.map((_, answerIndex) => {
                return (
                    <div className="flex items-center">
                        <input
                            name={`questions.${index}.answers.${answerIndex}.isCorrect`}
                            onChange={(e) => handleChange(e.target.checked, answerIndex)}
                            type={'checkbox'}
                            disabled={isReadOnly}
                            defaultChecked={answers?.[answerIndex]?.isCorrect}
                        />
                        <InputField className="w-full" formGroupClasses={'mt-3'} name={`questions.${index}.answers.${answerIndex}.content`} control={control} readOnly={isReadOnly} />
                        <DeleteButton isRender={!isReadOnly} onClick={() => remove(answerIndex)}></DeleteButton>
                    </div>
                );
            })}
        </div>
    );
};
