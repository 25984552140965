import { RATagResponse } from 'api/v2/risk-assessment/model/rs.Response.model';
import raTagApi from 'api/v2/risk-assessment/raTagApi';
import { SelectOption } from 'components/FormControl';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import MutilSelectField from 'components/FormControl/MutilSelectField';
import usePromptInputText from 'components/Modal/PromptInputText';
import { Col } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useController } from 'react-hook-form';

export interface RATagSelectProps extends BaseFormControlProps {
    isMulti: boolean | undefined;
    defaultValue?: any;
    isCanCreate?: boolean;
}

export function RATagSelect({ isCanCreate = false, name, control, required, md = 4, xs, readOnly, isMulti, defaultValue }: RATagSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    });
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const onAddTag = async () => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        if (text)
            raTagApi
                .create({ name: text })
                .then((id: string) => {
                    setOptions([...options, { value: id, label: text }]);
                })
                .catch((res) => {});
    };

    useEffect(() => {
        raTagApi
            .getTag()
            .then((tags: RATagResponse[]) => {
                setOptions(tags.toSelectOptions('name', 'id'));
            })
            .catch((e) => {});
    }, []);
    return (
        <Col sm={xs} md={md}>
            <label className="flex flex-wrap">
                {lang.text(Translations.ra.tag)}
                {required && <span className="text-danger">*</span>} &nbsp;
                {isCanCreate && (
                    <span className={`btn-inner hover:cursor-pointer text-primary`}>
                        <PlusCircleFill onClick={onAddTag} />
                    </span>
                )}
            </label>
            <MutilSelectField md={12} xs={12} name="tagIds" required={required} control={control} options={options} />
            {PromptInputText}
        </Col>
    );
}

export default RATagSelect;
