import { yupResolver } from '@hookform/resolvers/yup';
import { TNCourseCreateRequest, TNCourseDetailResponse, TNCourseDocument, TNCourseQuestion, TNCourseQuestionAnswer } from 'api/v2/training/models/tnCourse.model';
import tnCourseAPI from 'api/v2/training/tnCourseApi';
import { DeleteButton, SaveButton } from 'components/Button';
import { Empty } from 'components/shared';
import DataListView from 'components/shared/DataListView';
import { Card, Tab, TabPanel } from 'components/ui-kit';
import { CourseLimitType, TNDocumentType, TNQuestionType } from 'enum/training';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { Fragment, useEffect, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import TNCourseSubmissionsComponent from '../component/submissions/tnCouresSubmission.component';
import { TNCourseDocumentComponent } from '../component/tnCourseDocuments.component';
import { TNCourseQuestionComponent } from '../component/tnCourseQuestions.component';
import { TNCourseUsersComponent } from '../component/tnCourseUsers.component';
import TNGeneralInfoComponent from '../component/tnGeneralInfo.component';

// const defaultToDate = new Date();
// const defaultFromDate = new Date();
// defaultToDate.setDate(defaultFromDate.getDate() + 365);
export const TNCourseSchema = yup.object().shape({
    title: yup.string().required('Tiêu đề là bắt buộc'),
    summary: yup.string().required('Tóm tắt là bắt buộc'),
    topicId: yup.string().required('ID chủ đề là bắt buộc'),
    content: yup.string().required('Nội dung là bắt buộc'),
    require: yup.string(),
    quizStartDate: yup.date().nullable(),
    quizEndDate: yup.date().nullable(),
    courseLimitType: yup
        .mixed<CourseLimitType>()
        .oneOf(Object.values(CourseLimitType) as CourseLimitType[])
        .required('Loại khóa học là bắt buộc'),
    questions: yup.array().of(
        yup.object<TNCourseQuestion>().shape({
            question: yup.string().required('Câu hỏi là bắt buộc'),
            correctAnswer: yup.string().when('quizType', (quizType: TNQuestionType[]) => {
                if (quizType[0] !== TNQuestionType.Foreword) {
                    return yup.string().notRequired();
                } else {
                    return yup.string().required('Câu trả lời là bắt buộc');
                }
            }),
            quizType: yup
                .mixed<TNQuestionType>()
                .oneOf(Object.values(TNQuestionType) as readonly TNQuestionType[])
                .required('Loại câu hỏi là bắt buộc'),
            answers: yup
                .array()
                .of(
                    yup.object<TNCourseQuestionAnswer>().shape({
                        isCorrect: yup.boolean().required('Đúng/Sai là bắt buộc'),
                        content: yup.string().required('Nội dung là bắt buộc'),
                    })
                )
                .when('quizType', (quizType: TNQuestionType[]) => {
                    if (quizType[0] == TNQuestionType.Foreword) {
                        return yup.array().notRequired();
                    } else {
                        return yup.array().required();
                    }
                }),
        })
    ),
    documents: yup.array().of(
        yup.object<TNCourseDocument>().shape({
            name: yup.string().required('Tên tài liệu là bắt buộc'),
            link: yup.string().when('type', (types: TNDocumentType[]) => {
                if (types[0] !== TNDocumentType.Link) {
                    return yup.string().required();
                } else {
                    return yup.string().notRequired();
                }
            }),
            type: yup
                .mixed<TNDocumentType>()
                .oneOf(Object.values(TNDocumentType) as readonly TNDocumentType[])
                .required('Loại tài liệu là bắt buộc'),
        })
    ),
    userIds: yup.array().of(yup.string().required('Học viên là bắt buộc')),
});

export default function TNCourseDetailPage() {
    const [isReadOnly, seIsReadOnly] = useState<boolean>(true);
    const [userIds, setUserIds] = useState<string[]>([]);
    const { userPms } = useAuth();
    const { id } = useParams();
    const [detail, setDetail] = useState<TNCourseDetailResponse | null>(null);
    const navigate = useNavigate();

    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        setValue,
        formState: { isSubmitting, errors },
    } = useForm<TNCourseCreateRequest>({
        resolver: yupResolver(TNCourseSchema) as Resolver<TNCourseCreateRequest>,
        defaultValues: {
            courseLimitType: CourseLimitType.Unlimit,
            questions: [],
            documents: [],
        },
    });

    useEffect(() => {
        if (!id) return;
        getCourseDetail(id);
    }, []);
    const getCourseDetail = (id: string) => {
        tnCourseAPI
            .getById(id)
            .then((data) => {
                setDetail(data);
                setUserIds(data?.courseUsers.map((x) => x.id));
                reset(data);
            })
            .catch((e) => {});
    };

    function onDelete(id: string) {
        tnCourseAPI
            .delete(id)
            .then(() => {
                navigate(-1);
            })
            .catch((e) => {});
    }
    const generateUserReadonlyView = () => {
        return (
            <DataListView
                firstColumnStyle={{
                    width: '50px',
                }}
                datas={
                    detail?.courseUsers?.map((n, i) => ({
                        label: String(i + 1),
                        value: n.fullName,
                    })) ?? []
                }
            />
            // <ul>
            //    { detail?.courseUsers?.map((x) => <li>{x.fullName}</li>)}
            // </ul>
        );
    };
    return !detail ? (
        <Empty />
    ) : (
        <Fragment>
            <Card>
                <Card.Header>
                    <div className="flex justify-between ">
                        <h4 className="text-primary">{detail?.title}</h4>
                        <div className="flex ">
                            {!isReadOnly && <SaveButton type="submit" />}
                            <DeleteButton showConfirm={true} onConfirmed={() => onDelete(detail?.id!)} isRender={detail?.canDelete} />
                            {/* <CancelButton
                                onClick={() => {
                                    reset(detail!);
                                    setUserIds(detail!.courseUsers.map((x) => x.id));
                                    seIsReadOnly(true);
                                }}
                                isRender={!isReadOnly}
                            />
                            <UpdateButton onClick={() => seIsReadOnly(false)} isRender={detail?.canUpdate === true && !isReadOnly} /> */}
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Tab className="mb-3 border-bottom border-primary rounded-0 course-tabs">
                        <TabPanel className="rounded-0" title={lang.text(Translations.common.generalInfo)}>
                            <TNGeneralInfoComponent isShowChart={true} control={control as any} isReadOnly={isReadOnly} watch={watch as any} detail={detail} />
                        </TabPanel>
                        <TabPanel className="rounded-0" title={lang.text(Translations.training.question)}>
                            <TNCourseQuestionComponent control={control as any} isReadOnly={isReadOnly} watch={watch as any} />
                        </TabPanel>
                        <TabPanel className="rounded-0" title={lang.text(Translations.training.document)}>
                            <TNCourseDocumentComponent control={control as any} isReadOnly={isReadOnly} watch={watch as any} />
                        </TabPanel>
                        <TabPanel className="rounded-0" title={lang.text(Translations.training.student)}>
                            {isReadOnly ? generateUserReadonlyView() : <TNCourseUsersComponent isReadOnly={isReadOnly} checked={userIds} onValueChanged={setUserIds} />}
                        </TabPanel>
                        {isReadOnly && (
                            <Tab className="rounded-0" title="Bài nộp">
                                <TNCourseSubmissionsComponent course={detail!} />
                            </Tab>
                        )}
                        {/* <Tab className="rounded-0" eventKey={TNInfoTab.SUBMISSION} title="Bài nộp">
                            <TNCourseSubmissionsComponent course={detail!} />
                        </Tab> */}
                    </Tab>
                </Card.Body>
            </Card>
        </Fragment>
    );
}

// ['0aa649c5-1346-4dbd-827d-08dcba03d794', '1874c784-ef33-4b52-bd1c-08dcb49de674']
