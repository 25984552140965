import { formatDateTime } from 'helper';
import React, { memo, Fragment, CSSProperties } from 'react';

//react-bootstrap
import { Row, Col } from 'components/ui-kit/index';
import { Card } from 'components/shared';

//router
import { Link } from 'react-router-dom';

export interface TimelineItem {
    title: string;
    description?: any;
    date: Date;
    createdFulName?: string;
}
interface TimelineProps {
    items: TimelineItem[];
    title?: any;
    bodyStyle?: CSSProperties | undefined;
}
const TimelineComponent = ({ bodyStyle, items, title }: TimelineProps) => {
    const [isShowAll, setIsShowAll] = React.useState(false);
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <Card>
                        {title && <Card.Header>{title}</Card.Header>}

                        <Card.Body style={bodyStyle}>
                            <div className="iq-timeline0 m-0 flex items-center justify-between position-relative">
                                <ul className="list-inline p-0 m-0">
                                    {items &&
                                        items.map((item, index: number) => (
                                            <li key={index}>
                                                <div className={`timeline-dots timeline-dot1 ${index % 2 == 0 ? 'border-warning' : 'border-info'} text-primary`}></div>
                                                <h6 className="float-left mb-1">{item.title} </h6>
                                                <small className="float-right mt-1">
                                                    {formatDateTime(item.date)} {item.createdFulName && ' - '}
                                                    {item.createdFulName}
                                                </small>
                                                <div className="inline-block w-full">{item.description}</div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </Card.Body>
                        <Card.Footer></Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default TimelineComponent;
