import { AccordionItemChildProps, MenuGroupProps } from 'components/builder/verticalNavGroup';
import Translations from 'i18n/translation';
import Roles from 'router/const/roles';
import Routers from './routers';
import { AppIcon } from 'components/ui-kit';
import { App } from 'react-bootstrap-icons';

const Icons = {
    create: <AppIcon name="pi-plus-circle" />,
    detail: <AppIcon name="pi-file" />,
    report: <AppIcon name="pi-file-export" />,
    category: <AppIcon name="pi-list" />,
};

export const appMenus: MenuGroupProps[] = [
    {
        groupIcon: <AppIcon name="pi-file" />,
        active: 'false',
        groupName: '',
        key: 'root',
        items: [
            {
                roles: [],
                ignorePms: true,
                path: '/',
                title: Translations.pageName.home,
                icon: <AppIcon name="pi-file" />,
                isDisplayVerticalNav: false,
            },
        ],
    },
    // Profile
    {
        groupIcon: <AppIcon name="pi-file" />,
        active: 'false',
        groupName: 'profile',
        key: Routers.user.menu,
        items: [
            {
                roles: [],
                ignorePms: true,
                path: Routers.dashboard.root,
                title: 'dashboard',
                icon: <AppIcon name="pi-file" />,
                isDisplayVerticalNav: false,
            },
            {
                roles: [],
                ignorePms: true,
                path: Routers.auth.changePassword,
                title: Translations.common.changePassword,
                icon: <AppIcon name="pi-file" />,
                isDisplayVerticalNav: false,
            },
            {
                ignorePms: true,
                roles: [],
                path: Routers.user.profile,
                title: 'profile',
                isDisplayVerticalNav: false,
            },
        ],
    },
    // SOR
    {
        groupIcon: <AppIcon name="pi-file" />,
        active: 'false',
        groupName: Translations.sidebar.safetyReport.name,
        key: Routers.sor.menu,
        items: [
            {
                roles: Roles.sor.create,
                path: Routers.sor.create,
                title: Translations.sidebar.safetyReport.create,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sor.view,
                path: Routers.sor.root,
                title: Translations.sidebar.safetyReport.detail,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sor.synthesis_view,
                path: Routers.sor.dashboard,
                title: Translations.sidebar.safetyReport.synthesis,
                icon: Icons.report,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sor.view,
                path: Routers.sor.category,
                title: Translations.sidebar.safetyReport.topic,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
        ],
    },
    //SEC -  Kiểm tra ra vào
    {
        groupIcon: <AppIcon name="pi-objects-column" />,
        active: 'false',
        groupName: Translations.sec.menuBar,
        key: Routers.sec.menu,
        items: [
            {
                roles: Roles.sec.security,
                path: Routers.sec.registerDetail,
                title: Translations.sec.gate,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.sec.admin,
                path: Routers.sec.registerCreate,
                title: Translations.sec.register,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.sec.admin,
                path: Routers.sec.register,
                title: Translations.sec.register,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },

            {
                roles: Roles.sec.admin,
                path: Routers.sec.report,
                title: Translations.sec.report,
                icon: Icons.report,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.sec.admin,
                path: Routers.sec.gate,
                title: Translations.sec.gate,
                icon: <AppIcon name="pi-building-columns" />,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* Safety Inspections */
    {
        groupIcon: <AppIcon name="pi-shield" />, // <SearchCircleOutline onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />,
        active: 'false',
        groupName: Translations.sidebar.safetyInspections.name,
        key: Routers.safetyInspections.menu,
        items: [
            {
                roles: Roles.si.create,
                path: Routers.safetyInspections.report.create,
                title: Translations.sidebar.safetyInspections.daily.create,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.safety_periodically_report.view,
                path: Routers.safetyInspections.report.root,
                title: Translations.sidebar.safetyInspections.daily.detail,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.si.view,
                path: Routers.safetyInspections.report.dashboard,
                title: Translations.sidebar.safetyInspections.daily.synthesis,
                icon: Icons.report,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.si.crud_template,
                path: Routers.safetyInspections.template.root,
                title: Translations.sidebar.safetyInspections.daily.group,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* Equipment  */
    {
        groupIcon: <AppIcon name="pi-car" />,
        active: 'false',
        groupName: Translations.sidebar.equipment.name,
        key: Routers.equipment.menu,
        items: [
            {
                roles: Roles.equipment.crud,
                path: Routers.equipment.modify,
                title: Translations.common.edit,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.detail,
                title: '',
                isDisplayVerticalNav: false,
            },
            {
                roles: [...Roles.equipment_report.view_daily, ...Roles.equipment_report.view_periodically],
                path: Routers.equipment.reportDetail,
                title: '',
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.equipment_report.templateView,
                path: Routers.equipment.checkListDetail,
                title: '',
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.equipment.crud,
                path: Routers.equipment.reportCreate,
                title: Translations.sidebar.equipment.create,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.checkList,
                title: Translations.sidebar.equipment.checkList,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.list,
                title: Translations.sidebar.equipment.list,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.view,
                path: Routers.equipment.report,
                title: Translations.sidebar.equipment.report,
                icon: Icons.report,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.crud,
                path: Routers.equipment.type,
                title: Translations.sidebar.equipment.type,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.equipment.crud,
                path: Routers.equipment.create,
                title: Translations.common.addNew,
                isDisplayVerticalNav: false,
            },
        ],
    },
    // Trip -  Hanh Trình
    {
        groupIcon: <AppIcon name="pi-map" />,
        active: 'false',
        groupName: Translations.sidebar.trip.name,
        key: Routers.trip.menu,
        items: [
            {
                roles: Roles.trip.view,
                path: `${Routers.trip.tripChangeRouteDetail}`,
                title: Translations.trip.changeRouteRequest,
                isDisplayVerticalNav: false,
                icon: Icons.detail,
                ignorePms: true,
            },
            {
                roles: Roles.trip.create,
                path: `${Routers.trip.create}`,
                title: Translations.sidebar.trip.create,
                isDisplayVerticalNav: false,
                ignorePms: true,
            },
            {
                roles: [],
                ignorePms: true,
                path: `${Routers.trip.tripDetail}`,
                title: Translations.common.reportDetail,
                isDisplayVerticalNav: false,
            },
            {
                roles: [],
                ignorePms: true,
                path: `${Routers.trip.update}`,
                title: Translations.sidebar.trip.create,
                isDisplayVerticalNav: false,
            },
            {
                ignorePms: true,
                roles: Roles.trip.view,
                path: Routers.trip.trip,
                title: Translations.sidebar.trip.trip,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
            {
                ignorePms: true,
                roles: Roles.trip.report,
                path: Routers.trip.report,
                title: Translations.sidebar.trip.report,
                icon: Icons.report,
                isDisplayVerticalNav: true,
            },
            {
                ignorePms: true,
                roles: Roles.trip.create,
                path: `${Routers.trip.create}`,
                title: Translations.sidebar.trip.routeCreate,
                isDisplayVerticalNav: false,
            },
            {
                ignorePms: true,
                roles: Roles.trip.view,
                path: Routers.trip.route,
                title: Translations.sidebar.trip.route,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                ignorePms: true,
                roles: [],
                path: Routers.trip.routeDetail,
                title: Translations.sidebar.trip.route,
                isDisplayVerticalNav: false,
            },
        ],
    },
    /* Kpis  */
    {
        groupIcon: <AppIcon name="pi-chart-scatter" />,
        active: 'false',
        groupName: Translations.sidebar.kpi.name,
        key: Routers.kpi.menu,
        items: [
            {
                roles: Roles.kpi_report.crud_report,
                path: Routers.kpi.create,
                title: Translations.sidebar.kpi.create,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.kpi_report.crud_template,
                path: Routers.kpi.assignment,
                title: Translations.sidebar.kpi.assignment,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.kpi_report.view_report,
                path: Routers.kpi.report,
                title: Translations.sidebar.kpi.report,
                icon: Icons.report,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.kpi_report.crud_template,
                path: Routers.kpi.type,
                title: Translations.sidebar.kpi.type,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* work performance */
    {
        groupIcon: <AppIcon name="pi-chart-bar" />,
        active: 'false',
        groupName: Translations.sidebar.wpr.name,
        key: Routers.wpr.menu,
        items: [
            {
                roles: Roles.ppr_report.crud_report,
                path: Routers.wpr.pprCreate,
                title: Translations.sidebar.wpr.pprCreate,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.ppr_report.view_report,
                path: Routers.wpr.root,
                title: Translations.sidebar.wpr.pprDetail,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.ppr_report.view_category,
                path: Routers.wpr.levelPPR,
                title: Translations.sidebar.wpr.levelPPR,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.skpi.create,
                path: Routers.wpr.skpiCreate,
                title: Translations.sidebar.wpr.skpiCreate,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.skpi.view,
                path: Routers.wpr.skpiReview,
                title: Translations.sidebar.wpr.skpiReview,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
        ],
    },
    /* training online */
    {
        groupIcon: <AppIcon name="pi-video" />,
        active: 'false',
        groupName: Translations.sidebar.trainingOnline.name,
        key: Routers.trainingOnline.menu,
        items: [
            {
                roles: Roles.training.admin,
                path: Routers.trainingOnline.course,
                title: Translations.sidebar.trainingOnline.course,
                icon: <AppIcon name="pi-sitemap" />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.training.admin,
                path: Routers.trainingOnline.courseDetail,
                title: Translations.training.detail,
                isDisplayVerticalNav: false,
                ignorePms: false,
            },
            {
                roles: Roles.training.admin,
                path: Routers.trainingOnline.courseCreate,
                title: '',
                isDisplayVerticalNav: false,
                ignorePms: false,
            },
            {
                roles: Roles.training.admin,
                path: Routers.trainingOnline.category,
                title: Translations.sidebar.trainingOnline.category,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: [],
                path: Routers.trainingOnline.myCourse,
                title: Translations.sidebar.trainingOnline.myCourse,
                isDisplayVerticalNav: true,
                icon: Icons.detail,
                ignorePms: true,
            },
            {
                roles: Roles.training.admin,
                path: Routers.trainingOnline.setPoint,
                title: Translations.training.setPoint,
                isDisplayVerticalNav: false,
                ignorePms: false,
            },
            // student

            {
                roles: [],
                path: Routers.trainingOnline.myCourseTakeQuiz,
                title: Translations.sidebar.trainingOnline.myCourse,
                isDisplayVerticalNav: false,
                icon: <AppIcon name="pi-pen-to-square" />,
                ignorePms: true,
            },
        ],
    },
    //risk assessment
    {
        groupIcon: <AppIcon name="pi-exclamation-triangle" />,
        active: 'false',
        groupName: Translations.sidebar.riskAssessment.name,
        key: Routers.riskAssessment.menu,
        items: [
            {
                roles: Roles.risk_assessment.crud,
                path: Routers.riskAssessment.register,
                title: Translations.sidebar.riskAssessment.register,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.risk_assessment.view,
                path: Routers.riskAssessment.onlineDataBase,
                title: Translations.sidebar.riskAssessment.onlineDataBase,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
        ],
    },
    ///IIR -Report
    {
        groupIcon: <AppIcon name="pi-search" />,
        active: 'false',
        key: Routers.incidentReport.menu,
        groupName: Translations.sidebar.incidentReport.name,
        items: [
            {
                roles: Roles.iir_report.report_modify,
                path: Routers.iir.report.create,
                title: Translations.common.addNew,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.iir_report.report_modify,
                path: Routers.iir.report.update,
                title: Translations.common.edit,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.iir_report.report_view,
                path: Routers.iir.report.root,
                title: Translations.common.reportDetail,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.iir_report.report_view,
                path: Routers.iir.report.detail,
                title: Translations.common.reportDetail,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.iir_report.report_view,
                path: Routers.iir.report.root,
                title: '',
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.iir_report.crud_category,
                path: Routers.iir.incidentType,
                title: Translations.iir.incidentType,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.iir_report.crud_category,
                path: Routers.iir.investigateLevel,
                title: Translations.iir.incidentLevel,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
        ],
    },

    //documentation
    {
        groupIcon: <AppIcon name="pi-folder" />,
        active: 'false',
        groupName: Translations.sidebar.library.name,
        key: Routers.library.menu,
        items: [
            {
                roles: Roles.document.view,
                path: Routers.library.root,
                title: Translations.sidebar.library.name,
                icon: <AppIcon name="pi-folder-open" />,
                isDisplayVerticalNav: true,
            },
        ],
    },
    // Legal document
    {
        groupIcon: <AppIcon name="pi-briefcase" />,
        active: 'false',
        groupName: Translations.legalDocument.name,
        key: Routers.legalDocument.menu,
        items: [
            {
                ignorePms: false,
                roles: Roles.legalDocument.view,
                path: Routers.legalDocument.detail,
                title: Translations.common.addNew,
                isDisplayVerticalNav: false,
            },
            {
                ignorePms: false,
                roles: Roles.legalDocument.admin,
                path: Routers.legalDocument.create,
                title: Translations.common.addNew,
                icon: Icons.create,
                isDisplayVerticalNav: true,
            },
            {
                ignorePms: false,
                roles: Roles.legalDocument.view,
                path: Routers.legalDocument.root,
                title: Translations.legalDocument.name,
                icon: Icons.detail,
                isDisplayVerticalNav: true,
            },
            {
                ignorePms: false,
                roles: Roles.document.view,
                path: Routers.legalDocument.group,
                title: Translations.legalDocument.group,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
        ],
    },
    //configuration
    {
        groupIcon: <AppIcon name="pi-cog" />,
        active: 'false',
        groupName: Translations.sidebar.config.name,
        key: Routers.config.menu,
        items: [
            {
                roles: Roles.customer.view,
                path: Routers.customer.root,
                title: Translations.pageName.customer,
                icon: <AppIcon name="pi-users" />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.golden_rules.view,
                path: Routers.config.goldenRules,
                title: Translations.sidebar.config.goldenRules,
                icon: <AppIcon name="pi-key" />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.golden_rules.crud,
                path: Routers.config.goldenRulesCreate,
                title: Translations.common.addNew,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.golden_rules.crud,
                path: Routers.config.goldenRulesUpdate,
                title: Translations.common.edit,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.editor,
                path: Routers.config.company,
                title: Translations.sidebar.config.site,
                icon: <AppIcon name="pi-truck" />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.org_unit.crud,
                path: Routers.config.companyCreate,
                title: Translations.common.addNew,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.org_unit.crud,
                path: Routers.config.companyEdit,
                title: Translations.common.edit,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.area.view,
                path: Routers.config.area,
                title: Translations.sidebar.config.area,
                icon: <AppIcon name="pi-globe" />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.contractor.view,
                path: Routers.config.contractor,
                title: Translations.sidebar.config.contractor,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.risk_level.view,
                path: Routers.config.riskLevel,
                title: Translations.sidebar.config.riskLevel,
                icon: <AppIcon name="pi-exclamation-triangle" />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.risk_level.crud,
                path: Routers.config.riskLevelCreate,
                title: Translations.sidebar.config.riskLevel,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.risk_level.crud,
                path: Routers.config.riskLevelUpdate,
                title: Translations.sidebar.config.riskLevel,
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.editor,
                path: Routers.config.department,
                title: Translations.sidebar.config.department,
                icon: Icons.category,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.account_management.view,
                path: Routers.config.user,
                title: Translations.sidebar.config.account,
                icon: <AppIcon name="pi-users" />,
                isDisplayVerticalNav: true,
            },
            {
                roles: Roles.account_management.crud,
                path: Routers.user.action,
                title: 'modify',
                isDisplayVerticalNav: false,
            },
            {
                roles: Roles.account_management.view,
                path: Routers.config.role,
                title: Translations.role.role,
                icon: <AppIcon name="pi-eye" />,
                isDisplayVerticalNav: true,
            },
            // {
            //     roles: Roles.account_management.view,
            //     path: Routers.config.dashboard,
            //     title: Translations.sidebar.config.account,
            // },
            // Hot line
        ],
    },
    {
        groupIcon: <AppIcon name="pi-phone" />,
        active: 'false',
        groupName: Translations.sidebar.hotline.name,
        key: Routers.hotline.menu,
        path: Routers.hotline.menu,
        items: [
            {
                ignorePms: true,
                roles: [],
                path: Routers.hotline.root,
                title: Translations.sidebar.hotline.name,
                isDisplayVerticalNav: true,
            },
        ],
    },
];

export function findRouteByCurrentPath(): AccordionItemChildProps | undefined {
    let group = appMenus.find((n) =>
        n.items.some((s) => {
            var rs = isMatch(window.location.pathname, s.path);
            return rs;
        })
    );
    if (group) {
        let item = group.items.find((f) => isMatch(window.location.pathname, f.path));
        if (item) item.groupName = group.groupName;
        return item;
    }
    return undefined;
}
function isMatch(actualPath: string, patternPath: string) {
    // Replace the dynamic part :id with a regular expression pattern to match any value
    const regexPattern = patternPath.replace(/:\w+/g, '([^/]+)');

    // Create a new RegExp from the pattern
    const regex = new RegExp(`^${regexPattern}$`);

    // Test if the actual path matches the pattern
    return regex.test(actualPath);
}
