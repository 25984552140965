import { TabView as PrimeTab, TabPanel as PrimeTabPanel, TabPanelProps } from 'primereact/tabview';

interface TabCustomProps extends Omit<TabPanelProps, 'children'> {
    eventKey?: any; // Custom prop
    children?: React.ReactNode; // Allow children to be properly typed
}

const Tab = (props: Omit<React.ComponentProps<typeof PrimeTab>, 'children'> & { children: React.ReactNode }) => {
    return <PrimeTab {...props} />;
};

const TabPanel = ({ eventKey, ...props }: TabCustomProps) => {
    // Use the eventKey prop if needed here, or simply omit it
    return <PrimeTabPanel {...props} />;
};

export default Tab;
export { TabPanel };
export type { TabCustomProps };
