import { SelectField } from 'components/FormControl';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import { SIReportHelper } from 'helper/Feature/si.help';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';

export interface SIReportTypeSelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
}
export function SIReportTypeSelect({ name = 'reportType', control, required, md = 4, xs = 12, onValueChange, isAddAll, readOnly }: SIReportTypeSelectProps) {
    const options = useMemo(() => SIReportHelper.getReportTypesOptions(), []);

    return (
        <SelectField
            readOnly={readOnly}
            label={t(Translations.common.reportType)}
            options={options}
            name={name ?? 'reportType'}
            control={control}
            required={required}
            md={md}
            xs={xs}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default SIReportTypeSelect;
