import { Outlet } from 'react-router-dom';
import { useCustomer } from 'providers/contexts';

const Content = () => {
    const { currentCustomer } = useCustomer();
    return (
        <div className="w-full md:w-[calc(100vw_-_296px)] ml-2 mt-[68px] h-[calc(100vh_-_116px)] md:h-[calc(100vh_-_132px)] overflow-y-auto ">
            {currentCustomer?.id && <Outlet />}
        </div>
    );
};

export default Content;
