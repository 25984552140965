import * as React from 'react';
import { useController } from 'react-hook-form';
import { BaseFormControlProps } from '../baseFormProps';
import { Col, InputText, Password } from 'components/ui-kit';
import { Messages } from 'primereact/messages';

interface PasswordFieldProps extends BaseFormControlProps {
    placeholder?: string;
    type?: string | 'number' | 'text' | 'password' | 'email' | 'date';
    onValueChange?: (value: string) => void;
    min?: number;
    max?: number;
    className?: string;
    prefixText?: string;
    suffixText?: string;
}

export function PasswordField({ name, control, label, disabled = false, defaultValue, required = false, md, xs = 6, className, onValueChange }: PasswordFieldProps) {
    const [curentValue, setCurrentValue] = React.useState();

    const {
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
        rules: required ? { required: 'This field is required' } : {},
    });
    React.useEffect(() => {
        onChange(defaultValue);
    }, [defaultValue]);

    React.useEffect(() => {
        if (curentValue !== value) setCurrentValue(value);
        if (onValueChange) onValueChange(value);
    }, [value]);

    return (
        <Col md={md} sm={xs ?? 6} className={className}>
            <div className="flex-auto">
                <label className="block">
                    {label} {required && <span className="text-danger">*</span>}
                </label>
                <Password inputClassName="w-full" required={required} disabled={disabled} invalid={invalid} value={value ?? ''} onChange={(e) => onChange(e.target.value)} className="w-full" />
                {invalid && <div className="invalid-feedback  d-block ">{error?.message}</div>}
            </div>
        </Col>
    );
}
