import { EquipmentReportGroupRequest, EquipmentReportItemRequest } from 'api/models';
import { Accordion, Col, Row } from 'components/ui-kit';
import { AccordionTab } from 'primereact/accordion';
import ItemContent from './item.component';

const GroupContent = ({
    group,
    readonly,
    onItemChange,
}: {
    group: EquipmentReportGroupRequest;
    readonly: boolean;
    onItemChange: (group: EquipmentReportGroupRequest, item: EquipmentReportItemRequest) => void;
}) => {
    return (
        <Accordion>
            <AccordionTab header={<span className={group.items.some((n) => !n.isOk) ? 'text-danger' : ''}>{group.groupTemplateName}</span>}>
                <Row>
                    {group.items?.map((item) => (
                        <Col md={6} key={item.itemTemplateId} id={item.itemTemplateId}>
                            <ItemContent onItemChange={onItemChange} group={group} readonly={readonly} key={group.groupTemplateId} item={item} />
                        </Col>
                    ))}
                </Row>
            </AccordionTab>
        </Accordion>
    );
};
export default GroupContent;
