import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import './dateTimeFiels.module.css';
import { Calendar, Col } from 'components/ui-kit';

interface DateTimeFieldProps extends BaseFormControlProps {
    isShowTimePicker?: boolean; // Optional time picker
    onValueChange?: (date: Date) => void;
    defaultDate?: Date;
    maxDate?: Date;
    minDate?: Date;
    placeholder?: string;
    isClearable?: boolean;
}

const timeZoneLocal = new Date().getTimezoneOffset() / -60;

const DateTimeField: React.FC<DateTimeFieldProps> = (props: DateTimeFieldProps) => {
    const {
        field: { value, onChange },
        fieldState: { invalid, error },
    } = useController({
        name: props.name,
        control: props.control,
        rules: props.required ? { required: 'This field is required' } : {},
    });

    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    useEffect(() => {
        if (props.defaultDate) {
            onChange(new Date(props.defaultDate));
        }
    }, []);

    useEffect(() => {
        setCurrentDate(value);
    }, [value]);

    const onChangeDate = (date: Date) => {
        if (!date) {
            onChange(date);
            // return;
        }
        let timeZoneDate = date.getTimezoneOffset() / -60;
        if (timeZoneLocal != timeZoneDate) {
            date = moment(date).add(timeZoneLocal, 'h').toDate();
        }
        if (!props?.isShowTimePicker) {
            date?.setHours(0);
            date?.setMinutes(0);
            date?.setSeconds(0);
            date?.setMilliseconds(0);
        }
        onChange(date);
    };

    return (
        // <Calendar value={datetime24h} onChange={(e) => setDateTime24h(e.value)} showTime hourFormat="24" />
        <Col {...props}>
            <div className="flex-auto">
                <span className="block">{props.label}</span>
                <Calendar
                    className="w-full"
                    value={currentDate}
                    maxDate={props?.maxDate}
                    minDate={props?.minDate}
                    showIcon
                    dateFormat="dd/mm/yy"
                    //                    formatDateTime={props?.isShowTimePicker ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                    onChange={(e) => {
                        console.log(e.value);
                        var date = new Date(String(e.value));
                        onChangeDate(date);
                        props?.onValueChange?.(date);
                    }}
                    showTime={props.isShowTimePicker}
                    hourFormat="24"
                    placeholder="dd/MM/yyyy"
                    clearButtonClassName="ic ic-close"
                    locale="en"
                />
                {invalid && <div className="invalid-feedback  d-block ">{error?.message}</div>}
            </div>
        </Col>
        // <Col md={props.md} sm={props.xs ?? 12} className={props.className}>
        //     <Form.Group className={`mb-3 ${props.formGroupClasses} w-full`}>
        //         {props.label && (
        //             <Form.Label htmlFor={props.name} style={{ marginRight: 3 }}>
        //                 {props.label} {props.required && <span className="text-danger">*</span>}
        //             </Form.Label>
        //         )}
        //         <br />
        //         <DatePicker
        //             className={`form-control w-full ${error?.message ? 'is-invalid' : ''}`}
        //             showTimeSelect={props.isShowTimePicker}
        //             selected={currentDate}
        //             onChange={(date: Date) => {
        //                 onChangeDate(date);
        //                 props?.onValueChange?.(date);
        //             }}
        //             timeFormat="p"
        //             timeIntervals={30}
        //             readOnly={props.readOnly}
        //             // showTimeInput={props.isShowTimePicker}
        //             dateFormat={props.isShowTimePicker ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy'}
        //             name={props.name}
        //             maxDate={props?.maxDate}
        //             minDate={props?.minDate}
        //             placeholderText={props?.placeholder || 'Chọn ngày'}
        //             isClearable={props?.isClearable}
        //         />
        //         <div className="invalid-feedback d-blocksecond d-block ">{error?.message}</div>
        //     </Form.Group>
        // </Col>
    );
};

export default DateTimeField;
