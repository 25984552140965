//react-bootstrap
import { Accordion, Button } from 'components/ui-kit';

//components
// import dashboarApi from 'api/dashboarApi';
import { DashboardItemResponse } from 'api/models/organization';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Pencil } from 'assets/icons/solid';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfigPage = memo(() => {
    const { t } = useTranslation();
    const { customers } = useCustomer();
    const selectedLanguage: string = useAppSelector(selectLanguage) as string;
    const [data, setData] = useState<DashboardItemResponse[]>([]);

    useEffect(() => {
        handleFetchData(customers[0].id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFetchData = (comId: string) => {
        // getData(comId).then(({ result }: { result: DashboardItemResponse[] }) => {
        //     setData(result);
        // });
    };

    const tableData = useMemo(
        () => [
            {
                title: t(Translations.MENUSor),
                reportType: 1,
            },
            {
                title: t(Translations.ReportEquipment),
                reportType: 2,
            },
            {
                title: t(Translations.MENUPpr),
                reportType: 4,
            },
            {
                title: t(Translations.KPIS),
                reportType: 6,
            },
        ],
        [selectedLanguage]
    );

    const handleUpdateStatus = (id: string, active: boolean) => {
        setData((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return { ...item, isActive: active };
                }
                return item;
            })
        );
    };

    const handleUpdateData = () => {
        // updateData(watch().customerId, data);
    };

    return (
        <Card>
            <Card.Header>
                <div className="text-end w-full flex justify-between items-center">
                    <h2 className="mb-0">{t(Translations.sidebar.config.dashboard)}</h2>
                </div>
            </Card.Header>
            <Card.Body>
                <Accordion>
                    {/* {tableData.map((item, index) => (
                        <AccordionTab key={index} header={item.title}>
                            <CheckBoxField
                                inline={false}
                                control={control}
                                name="customerId"
                                options={data.filter((it) => it.dashboardReportType === item.reportType).map((row) => ({ label: row.name, value: row.id }))}
                            />

                            {data
                                .filter((it) => it.dashboardReportType === item.reportType)
                                .map((row, rowIndex) => (
                                    <>working</>
                                    // <Form.Check className="d-block form-group" key={rowIndex}>
                                    //     <Form.Check.Input
                                    //         className="me-2"
                                    //         type="checkbox"
                                    //         id={row.id}
                                    //         onClick={() => handleUpdateStatus(row.id, !row.isActive)}
                                    //         defaultChecked={row.isActive ? true : false}
                                    //     />
                                    //     <Form.Check.Label htmlFor="flexCheckChecked">{row.name}</Form.Check.Label>
                                    // </Form.Check>
                                ))}
                        </AccordionTab>
                    ))} */}
                </Accordion>

                <div className="text-center">
                    <Button className="mt-5" onClick={handleUpdateData}>
                        <Pencil /> {t(Translations.button.update)}
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
});

export default ConfigPage;
