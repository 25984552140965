import orgUnitApi from 'api/v2/organizationUnitApi';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationUnitType } from 'api/enums/OrganizationUnitType';
import { OrganizationUnitCreateRequest } from 'api/models';
import { SaveButton } from 'components/Button';
import { InputField, UploadImageField } from 'components/FormControl';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Col, Row } from 'components/ui-kit';

const ConfigCompanyCreatePage = memo(() => {
    const { t } = useTranslation();

    const { loadInfrastructure } = useCustomer();
    const schema = yup.object().shape({
        name: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        logo: yup.string(),
    });

    const { control, handleSubmit, getValues } = useForm<{ name: string; logo?: string }>({
        defaultValues: {
            name: '',
            logo: '',
        },
        resolver: yupResolver(schema),
    });

    const [openConfirm, setOpenConfirm] = useState(false);
    const [logo, setLogo] = useState('');

    const handleConfirmSubmit = () => {
        setOpenConfirm(true);
    };

    const handleAdd = () => {
        const values = getValues();
        const _addData: OrganizationUnitCreateRequest = {
            name: values.name,
            logo: values.logo,
            unitType: OrganizationUnitType.Company,
        };
        orgUnitApi
            .create(_addData)
            .then(() => {
                setOpenConfirm(false);
                loadInfrastructure();
            })
            .catch(() => {});
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <form onSubmit={handleSubmit(handleConfirmSubmit)}>
                        <Row className="justify-end">
                            <SaveButton type="submit" />
                        </Row>
                        <Row>
                            <InputField md={12} required={true} name="name" control={control} label={t(Translations.form.site.name)} placeholder="" />

                            <Col>
                                <UploadImageField md={12} control={control} src={logo} btnText={t(Translations.common.upload)} name="logo" />
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigCompanyCreatePage;
