import { Content, Footer } from 'components/builder';
import DefaultLayoutHeader from './header';
import PrimeSidebar from './PrimeSidebar';

export default function DefaultLayout() {
    return (
        <div className="flex bg-[#f9f9f9]">
            <PrimeSidebar />
            <DefaultLayoutHeader />
            <Content />
            <Footer />
        </div>
    );
}
