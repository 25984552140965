import { Col, Dropdown } from 'components/ui-kit';
import React, { useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
export interface SelectFieldProps {
    control: Control<any, any>;
    name: string;
    errorMessage?: string;
    required?: boolean;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 10 | 8 | 12 | 9;
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 10 | 8 | 12 | 9;
    disable?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    label?: string;
    options?: SelectOption[];
    onValueChange?: (value: any) => void;
    onSelectedOptionChange?: (value?: SelectOption | undefined | null) => void; //onValueChange for only Id, onValueOptionChange for Id and Label
    displayProp?: string;
    // onSearch?: ((value: string) => void) | undefined;
    unSelectValue?: number | string;
    defaultValue?: number | string;
}

export interface SelectOption {
    label?: string;
    value: number | string;
}

export const SelectField: React.FC<SelectFieldProps> = ({
    unSelectValue,
    required,
    name,
    control,
    label,
    disabled = false,
    options = [],
    onValueChange,
    onSelectedOptionChange,
    md = 4,
    xs = 6,
    readOnly,
}) => {
    const {
        field,
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
    });

    useEffect(() => {
        if (onValueChange) onValueChange(field.value);
        onSelectedOptionChange && onSelectedOptionChange(options.find((option) => option.value == field.value));
    }, [field.value]);

    return (
        <Col md={md} sm={xs ?? 6}>
            <div className="flex-auto">
                <span>
                    {label} {required && <span className="text-danger">*</span>}
                </span>
                <Dropdown
                    defaultValue={unSelectValue}
                    showClear={true}
                    disabled={disabled}
                    readOnly={readOnly}
                    invalid={invalid}
                    inputId={name}
                    value={field.value}
                    onChange={(e) => {
                        field.onChange(e.value);
                    }}
                    options={options}
                    optionLabel="label"
                    placeholder={'-- select --'}
                    name={name}
                    filter
                    className="w-full"
                    // valueTemplate={selectedCountryTemplate}
                    // itemTemplate={countryOptionTemplate}
                />
                {invalid && <div className="invalid-feedback  d-block ">{error?.message}</div>}
            </div>
        </Col>
    );
};
