import { IIRPersonModel } from 'api/v2/iir/model/iirReportComponent.model';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import IIRReportItemBaseComponent, { IIRListComponentProps } from './iirBaseItem.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, SelectField, TextAreaField } from 'components/FormControl';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Gender } from 'api/enums/IIR';
import { Row } from 'components/ui-kit';

const schema = yup.object().shape({
    fullName: yup.string().required('Full name is required'),
    // age: yup.number().required('Age is required'),
    // gender: yup.string().required('Gender is required'),
    // injuryStatus: yup.string().required('Injury status is required'),
    // experiences: yup.string().required('Experiences is required'),
    // shift: yup.string().required('Shift is required'),
    // statusOfContract: yup.string().required('Status of contract is required'),
});
const GENDER_OPTIONS = [
    {
        label: 'Nam',
        value: Gender.Male,
    },
    {
        label: 'Nữ',
        value: Gender.Female,
    },
];
export default function IIRPersonViewComponent(props: IIRListComponentProps) {
    const itemForm = useForm({
        resolver: yupResolver(schema),
    });

    const FormComponent = ({ data }: { data: IIRPersonModel }) => {
        useEffect(() => {
            itemForm.reset(data);
        }, [data]);

        return (
            <Row>
                <InputField required md={8} xs={8} control={itemForm.control} name="fullName" label={lang.text(Translations.iir.person.fullName)} />
                <InputField md={4} xs={4} control={itemForm.control} name="age" label={lang.text(Translations.iir.person.age)} type="number" min={1} max={100} />
                <SelectField md={4} xs={12} control={itemForm.control} name="gender" label={lang.text(Translations.iir.person.gender)} options={GENDER_OPTIONS} />
                <InputField md={4} xs={12} control={itemForm.control} name="timeOfService" label={lang.text(Translations.iir.person.timeOfService)} />
                <InputField md={4} xs={12} control={itemForm.control} name="shift" label={lang.text(Translations.iir.person.shift)} />
                <InputField md={4} xs={12} control={itemForm.control} name="experiences" label={lang.text(Translations.iir.person.experiences)} />
                <InputField md={4} xs={12} control={itemForm.control} name="statusOfContract" label={lang.text(Translations.iir.person.statusOfContract)} />
                <TextAreaField md={12} xs={12} control={itemForm.control} name="injuryStatus" label={lang.text(Translations.iir.person.injuryStatus)} />
            </Row>
        );
    };

    return (
        <IIRReportItemBaseComponent
            formComponent={(data) => <FormComponent data={data} />}
            isReadOnly={props.isReadOnly}
            title={lang.text(Translations.iir.personInfo)}
            datas={props.datas}
            formComponentControl={itemForm}
            arrayForm={props.arrayForm}
            columns={[
                {
                    field: 'fullName',
                    title: lang.text(Translations.form.fullName.name),
                },
                {
                    field: 'gender',
                    title: lang.text(Translations.userProfile.gender),
                },
                {
                    field: 'injuryStatus',
                    title: lang.text(Translations.iir.person.injuryStatus),
                },
                {
                    field: 'experiences',
                    title: lang.text(Translations.iir.person.experiences),
                },
                {
                    field: 'timeOfService',
                    title: lang.text(Translations.iir.person.timeOfService),
                },
                {
                    field: 'shift',
                    title: lang.text(Translations.iir.person.shift),
                },
                {
                    field: 'companyName ',
                    title: lang.text(Translations.form.site.name),
                },
                {
                    field: 'statusOfContract ',
                    title: lang.text(Translations.iir.person.statusOfContract),
                },
                {
                    field: 'action',
                    title: '',
                },
            ]}
        />
    );
}
