import { RiskLevelCreateRequest } from 'api/models/organization';
//react-bootstrap

import { Col, Row } from 'components/ui-kit/index';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import riskLevelApi from 'api/v2/common/riskLevel/riskLevelApi';
import { SaveButton } from 'components/Button';
import { InputField, TextAreaField } from 'components/FormControl';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const ConfigRiskLevelCreatePage = memo(() => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        fieldNameVn: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        note: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        reset,
    } = useForm<{ fieldNameVn: string; note?: string }>({
        defaultValues: {
            fieldNameVn: '',
            note: '',
        },
        resolver: yupResolver(schema),
    });

    const onAdd = () => {
        const values = getValues();

        const _addData: RiskLevelCreateRequest = {
            fieldNameVn: values.fieldNameVn,
            note: values.note,
        };

        riskLevelApi
            .create(_addData)
            .then(() => {
                reset((values) => ({ ...values, fieldNameVn: '', note: '' }));
            })
            .catch((n) => {});
    };

    return (
        <Card>
            <Card.Header></Card.Header>
            <Card.Body>
                <form className=" justify-center" onSubmit={handleSubmit(onAdd)}>
                    <Row>
                        <InputField md={12} xs={12} name="fieldNameVn" control={control} label={t(Translations.form.name)} placeholder="" />
                        <TextAreaField md={12} xs={12} name="note" control={control} label={t(Translations.form.content)} placeholder="" />
                        <Col md={12} sm={12}>
                            <div className="flex justify-end">
                                <SaveButton type="submit" />
                            </div>
                        </Col>
                    </Row>
                </form>
            </Card.Body>
        </Card>
    );
});

export default ConfigRiskLevelCreatePage;
