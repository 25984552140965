import { SKPIActionRequest } from 'api/v2/skpi/model/skpiRequest.model';
import { SKPIActionResponse } from 'api/v2/skpi/model/skpiResponse.model';
import { Column, Table, TableColumn } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useMemo } from 'react';

interface SkpiActionComponentProps {
    onDelete?: (action: SKPIActionRequest | SKPIActionResponse) => void;
    onEdit?: (action: SKPIActionRequest | SKPIActionResponse) => void;
    actions: (SKPIActionRequest | SKPIActionResponse)[];
}

const SkpiActionComponent = (props: SkpiActionComponentProps) => {
    const columns: TableColumn[] = useMemo(() => {
        return [
            {
                field: 'pICFullName',
                header: lang.text(Translations.common.pic),
            },
            {
                field: 'name',
                header: lang.text(Translations.wpr.skpi.actions),
            },
        ];
    }, []);
    return (
        <Table value={props.actions} stripedRows>
            {columns.map((col, colIndex) => (
                <Column key={colIndex} field={col.field} header={col.header ?? col.title} />
            ))}
        </Table>
        // <div className="table-responsive p-datatable ">
        //     <table className="p-datatable-table">
        //         <thead className="p-datatable-thead">
        //             <tr>
        //                 <th>{lang.text(Translations.common.pic)}</th>
        //                 <th>{lang.text(Translations.wpr.skpi.actions)}</th>
        //             </tr>
        //         </thead>
        //         <tbody className="p-datatable-tbody">
        //             {props.actions.map((item, i) => (
        //                 <tr key={i}>
        //                     <td>
        //                         <span style={{ minWidth: '25%' }}>{item.pICFullName} </span>
        //                     </td>
        //                     <td>
        //                         <span style={{ minWidth: '75%' }}>{item.name}</span>
        //                     </td>
        //                     {(props.onDelete || props.onEdit) && (
        //                         <td>
        //                             <ActionButtons2
        //                                 id={item.uiId ?? ''}
        //                                 onDelete={
        //                                     props.onDelete
        //                                         ? () => {
        //                                               props.onDelete && props.onDelete(item);
        //                                               return Promise.resolve();
        //                                           }
        //                                         : undefined
        //                                 }
        //                                 editAction={
        //                                     props.onEdit
        //                                         ? () => {
        //                                               props.onEdit && props.onEdit(item);
        //                                           }
        //                                         : undefined
        //                                 }
        //                             />
        //                         </td>
        //                     )}
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table>
        // </div>
    );
};
export default SkpiActionComponent;
