import { ProfileCourseRequest } from 'api/v2/user/model';
import profileCourseApi from 'api/v2/user/profileCourseApi';
import { AddButton } from 'components/Button';
import LinkFileDownload from 'components/Common/LinkFile';
import useModalHook from 'components/ModalHook';
import { Empty } from 'components/shared';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { ResponsiveTable } from 'components/Table/ResponsiveTable';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useMemo, useState } from 'react';
import ProfileCourseForm from './course.add.component';

interface CourseComponentProps {}

export default function CourseComponent(props: CourseComponentProps) {
    const [datas, setDatas] = useState<any[]>([]);
    useEffect(() => {
        fethData();
    }, []);
    const fethData = () => {
        profileCourseApi.getPagination().then((paginate) => {
            const items =
                paginate?.datas?.map((n) => ({
                    ...n,
                    file: n.filePath && <LinkFileDownload path={n.filePath} fileName={n.fileName} />,
                    action: (
                        <ActionButtons2
                            id={n.id ?? ''}
                            onDelete={onDelete}
                            editAction={() => {
                                onUpdate(n);
                            }}
                        />
                    ),
                })) ?? [];
            setDatas(items);
        });
    };

    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'startPlace',
                title: lang.text(Translations.common.issuedPlace),
            },
            {
                field: 'expiredDate',
                title: lang.text(Translations.common.expireDate),
            },
            {
                field: 'organizeUnitName',
                title: lang.text(Translations.userProfile.courseOrganizeUnit),
            },
            {
                field: 'status',
                title: lang.text(Translations.userProfile.courseOrganizeUnit),
            },
            {
                field: 'file',
                title: lang.text(Translations.document.link),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    const onAdd = async () => {
        var item: ProfileCourseRequest = await openModal({
            title: lang.text(Translations.userProfile.course),
            body: <ProfileCourseForm onClose={onClose} onCompleted={onCompleted} />,
        });
        if (item) {
            profileCourseApi
                .create(item)
                .then((n) => {
                    fethData();
                })
                .catch((n) => {});
        }
    };

    const onUpdate = async (item: ProfileCourseRequest) => {
        var rs = await openModal({
            title: lang.text(Translations.userProfile.course),
            body: <ProfileCourseForm profile={item} onClose={onClose} onCompleted={onCompleted} />,
        });

        rs &&
            profileCourseApi
                .update(rs)
                .then((n) => {
                    fethData();
                })
                .catch(() => {});
    };

    const onDelete = async (id: string) => {
        profileCourseApi
            .delete(id)
            .then((n) => {
                fethData();
            })
            .catch(() => {});
    };

    return (
        <div>
            <div className="flex justify-end ">
                <AddButton isRender={true} onClick={onAdd} />
            </div>
            {!datas.hasValue() ? <Empty maxHeight={200} /> : <ResponsiveTable columns={cols} datas={datas} isStriped={true} />}
            {Prompt}
        </div>
    );
}
