import { Button } from 'components/ui-kit';
import { Fragment } from 'react';
import { BaseButtonProps } from './BaseButton.props';

export function BaseButton(props: BaseButtonProps) {
    return props.isRender === true ? (
        // <Button disabled={props.disable} onClick={props.onClick} variant={props.color} className={' px-2 py-1 mx-2 rounded ' + (props.className ?? '')} type={props.type} size="sm">
        //     <div className="flex flex-row items-center">
        //         {props.icon ?? <span className="btn-inner me-2">{props.icon}</span>}
        //         {props.text && <div style={{ marginLeft: '4px' }}>{props.text}</div>}
        //     </div>
        // </Button>
        <Button type={props.type} onClick={props.onClick} label={props.text} severity={props.color} icon={props.icon} size={props.size == 'sm' ? 'small' : 'small'} />
    ) : (
        <Fragment></Fragment>
    );
}
