import { Card as PrimeCard, CardProps as PrimeCardProps } from 'primereact/card';
import React, { FC, HTMLAttributes } from 'react';

// Define the CardProps interface
export interface CardProps extends PrimeCardProps {
    children?: React.ReactNode;
}

// Define subcomponents as individual functional components
const CardHeader: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
    <div className="p-card-title" {...props}>
        {children}
    </div>
);

const CardHeaderTitle: FC<HTMLAttributes<HTMLHeadingElement>> = ({ children, ...props }) => (
    <h5 className="p-card-subtitle" {...props}>
        {children}
    </h5>
);

const CardHeaderAction: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
    <div className="card-header-action" {...props}>
        {children}
    </div>
);

const CardBody: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
    <div className="" {...props}>
        {children}
    </div>
);

const CardFooter: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
    <div className="p-card-footer" {...props}>
        {children}
    </div>
);

// Define the main Card component
const CardBase: FC<CardProps> = (props) => {
    const { children, ...rest } = props;
    return <PrimeCard {...rest}>{children}</PrimeCard>;
};

// Extend the CardBase component with static subcomponents
export const Card: FC<CardProps> & {
    Header: typeof CardHeader;
    HeaderTitle: typeof CardHeaderTitle;
    HeaderAction: typeof CardHeaderAction;
    Body: typeof CardBody;
    Footer: typeof CardFooter;
} = CardBase as FC<CardProps> & {
    Header: typeof CardHeader;
    HeaderTitle: typeof CardHeaderTitle;
    HeaderAction: typeof CardHeaderAction;
    Body: typeof CardBody;
    Footer: typeof CardFooter;
};

// Assign subcomponents to the main Card component
Card.Header = CardHeader;
Card.HeaderTitle = CardHeaderTitle;
Card.HeaderAction = CardHeaderAction;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;
