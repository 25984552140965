import { Content, Footer } from 'components/builder';
import MobileSideMenu from './mobileSideMenu';
import BoxLayoutHeader from './boxLayoutHeader';
import { TreeSelect } from 'primereact/treeselect';
export default function BoxLayout() {
    return (
        <div style={{ background: '#b4d7ff' }}>
            <div className={'box-layout-wrapper'}>
                <main className="main-content">
                    <div className={`iq-banner default position-relative rounded-bottom`}>
                        <BoxLayoutHeader />
                    </div>
                    {/* Content Page */}
                    <Content />
                    <Footer />
                </main>
            </div>
        </div>
    );
}
