import { IIRSequenOfEventModel } from 'api/v2/iir/model/iirReportComponent.model';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import TimelineComponent from 'components/Common/TimeLine';
import { useForm } from 'react-hook-form';
import IIRReportItemBaseComponent, { IIRListComponentProps } from './iirBaseItem.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField, TextAreaField } from 'components/FormControl';
import { useEffect } from 'react';
import * as yup from 'yup';

import DateTimeField from 'components/FormControl/DateTimeField';
import { formatDateTime } from 'helper';
import { Row } from 'components/ui-kit';

const schema = yup.object().shape({
    description: yup.string().required('Mô tả không được để trống '),
    dateTimeHappen: yup.date().required('Thời gian không được để trống'),
});
export default function IIRSequenOfEventViewComponent(props: IIRListComponentProps) {
    const itemForm = useForm({
        resolver: yupResolver(schema),
    });

    const FormComponent = ({ data }: { data: IIRSequenOfEventModel }) => {
        useEffect(() => {
            itemForm.reset(data);
        }, [data]);

        return (
            <Row>
                <DateTimeField md={12} control={itemForm.control} name="dateTimeHappen" label={lang.text(Translations.iir.sequenOfEvent.dateTimeHappen)} />
                <TextAreaField md={12} control={itemForm.control} name="description" label={lang.text(Translations.iir.sequenOfEvent.description)} />
            </Row>
        );
    };

    return props.isReadOnly ? (
        <TimelineComponent
            title={lang.text(Translations.iir.sequenOfEvents)}
            items={
                props.datas?.map((n: IIRSequenOfEventModel) => ({
                    date: n.dateTimeHappen,
                    title: '',
                    createdFulName: '',
                    description: n.description,
                    content: n.description,
                })) ?? []
            }
        />
    ) : (
        <IIRReportItemBaseComponent
            formComponent={(data) => <FormComponent data={data} />}
            isReadOnly={props.isReadOnly}
            title={lang.text(Translations.iir.sequenOfEvents)}
            datas={props.datas.map((n) => ({ ...n, dateTimeHappen: formatDateTime(n.dateTimeHappen) }))}
            formComponentControl={itemForm}
            arrayForm={props.arrayForm}
            columns={[
                {
                    field: 'dateTimeHappen',
                    title: lang.text(Translations.iir.sequenOfEvent.dateTimeHappen),
                    style: { maxWidth: '200%' },
                },
                {
                    field: 'description',
                    title: lang.text(Translations.iir.sequenOfEvent.description),
                },
                {
                    field: 'action',
                    title: '',
                },
            ]}
        />
    );
}
