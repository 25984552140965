import { yupResolver } from '@hookform/resolvers/yup';
import { TextAreaField } from 'components/FormControl';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect } from 'react';

import { Control, useForm } from 'react-hook-form';
import * as yup from 'yup';
import IIRReportItemBaseComponent from './iirBaseItem.component';
import { Row } from 'components/ui-kit';

const schema = yup.object().shape({
    name: yup.string().required('Data is required'),
});

export default function IIRTextListViewComponent({
    datas,
    title,
    headerColumn,
    isReadOnly,
    arrayForm,
}: {
    headerColumn: string;
    title: string;
    datas: string[];
    isReadOnly?: boolean;
    arrayForm?: any;
    control?: Control;
}) {
    const itemForm = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
    }, [datas]);
    const FormComponent = ({ data }: { data: { [x: string]: string } }) => {
        useEffect(() => {
            itemForm.reset(data);
        }, [data]);
        return (
            <Row>
                <TextAreaField md={12} control={itemForm.control} name="name" label={lang.text(Translations.table.data)} />
            </Row>
        );
    };

    return (
        <IIRReportItemBaseComponent
            title={title}
            datas={datas?.map((n) => ({ name: n }))}
            isTextField={true}
            columns={[
                {
                    field: 'name',
                    title: headerColumn,
                },
                {
                    field: 'action',
                    title: '',
                },
            ]}
            isReadOnly={isReadOnly}
            formComponent={(data) => <FormComponent data={data} />}
            formComponentControl={itemForm}
            arrayForm={arrayForm}
        />
    );
}
