import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { TNTopicCreateRequest, TNTopicResponse, TNTopicUpdateRequest } from 'api/v2/training/models/tnTopic.model';
import tnTopicApi from 'api/v2/training/tnTopicApi';
import { AddButton } from 'components/Button';
import useModalHook from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import Card from 'components/ui-kit/primereact/Card';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { Fragment, useEffect, useMemo, useState } from 'react';
import TNTopicForm from '../component/tnTopic.component';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

const TNTopicPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const { userPms } = useAuth();

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    console.log(userPms);
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        tnTopicApi
            .getPagination(pagination)
            .then((n: PaginationBase<TNTopicResponse>) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                action: <ActionButtons2 id={n.id} onDelete={onDelete} deletePms={userPms.training.admin} editPms={userPms.training.admin} editAction={() => onUpdate(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onAdd = async () => {
        var request: TNTopicCreateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <TNTopicForm text="" id="" onClose={onClose} onCompleted={onCompleted} />,
        });
        tnTopicApi
            .create(request)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };

    const onUpdate = async (data: TNTopicResponse) => {
        var request: TNTopicUpdateRequest = await openModal({
            title: lang.text(Translations.equipment.templateCategories),
            body: <TNTopicForm text={data?.name ?? ''} id={data.id} onClose={onClose} onCompleted={onCompleted} />,
        });
        request &&
            tnTopicApi
                .update(request)
                .then(() => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch(() => {});
    };
    const onDelete = async (id: string) => {
        tnTopicApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div className="flex justify-end my-2">
                        <AddButton isRender={true} onClick={onAdd} />
                    </div>
                </Card.Body>
            </Card>
            <PaginationTable
                onSearch={handleFetchData}
                onPaginate={handleFetchData}
                onRowClick={(item) => {}}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={true}
                wrapperClass="mt-5"
            />
            {Prompt}
        </Fragment>
    );
};

export default TNTopicPage;
