import { SORDashboardResponse, SORReportFilterRequest } from 'api/v2/sor/model/sorReport.model';
import sorReportApi from 'api/v2/sor/sorReportApi';
import { FilterButton } from 'components/Button/FilterButton';
import { BardChart } from 'components/chart/bardChart';
import { PieChart } from 'components/chart/pieChart';
import { AreaSelect, CompanySelect } from 'components/Common';
import DateTimeField from 'components/FormControl/DateTimeField';
import { Card, Col, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import ProgressItem from 'views/safety-inspections/component/progressItem';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 60);
const SORDashboard = () => {
    const { control, handleSubmit, getValues } = useForm();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [data, setData] = useState<SORDashboardResponse | null>(null);
    const handleFetchData = async () => {
        var form = getValues();
        // return;
        let filter = {
            ...form,
        } as SORReportFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        sorReportApi
            .getDashboard(filter)
            .then((dashboarResponse) => {
                setData(dashboarResponse);
            })
            .catch((e) => {});
    };
    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <form className="w-full" onSubmit={handleSubmit(() => handleFetchData())}>
                        <Row>
                            <CompanySelect name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <AreaSelect name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />

                            <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} xs={12} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                            <DateTimeField label={lang.text(Translations.common.toDate)} md={4} xs={12} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />
                            <Col className="flex justify-end">
                                <FilterButton type="submit" className="mx-2 mt-3 mb-2" />
                            </Col>
                        </Row>
                    </form>
                </Card.Header>
            </Card>
            <Row>
                {data?.reportTypes && (
                    <Col md={4} className="flex justify0content-center">
                        <Card className="card-block">
                            <Card.Header>
                                <h4 className="mb-0">{lang.text(Translations.common.status)}</h4>
                            </Card.Header>
                            <PieChart labels={[lang.text(Translations.common.safe), lang.text(Translations.common.unSafe)]} series={[data.reportTypes.countSafe, data.reportTypes.countUnsafe]} />
                        </Card>
                    </Col>
                )}
                {data?.piCs && (
                    <Col md={4} className="flex justify0content-center ">
                        <Card className="card-block">
                            <Card.Header>
                                <h4 className="mb-0">{lang.text(Translations.common.pic)}</h4>
                            </Card.Header>
                            <Card.Body>
                                {data.piCs.map((item) => (
                                    <ProgressItem
                                        key={item.label}
                                        text={item.label}
                                        value={(item.countSafe / (item.countUnsafe + item.countSafe)) * 100}
                                        successText={`${item.countSafe}`}
                                        errorText={`${item.countUnsafe}`}
                                    />
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                )}
                {data?.riskLevels && (
                    <Col md={4} className="flex justify0content-center ">
                        <Card className="card-block">
                            <Card.Header>
                                <h4 className="mb-0">{lang.text(Translations.common.riskLevel)}</h4>
                            </Card.Header>
                            <BardChart
                                labels={data.riskLevels?.map((n) => n.label) ?? []}
                                series={[
                                    {
                                        data: data?.riskLevels?.map((n) => n.countSafe) ?? [],
                                        name: lang.text(Translations.common.safe),
                                    },
                                    {
                                        data: data?.riskLevels?.map((n) => n.countUnsafe) ?? [],
                                        name: lang.text(Translations.common.unSafe),
                                    },
                                ]}
                            />
                        </Card>
                    </Col>
                )}

                {data?.categories && (
                    <Col md={4} className=" flex justify0content-center ">
                        <Card className="card-block">
                            <Card.Header>
                                <h4 className="mb-0">{lang.text(Translations.sor.category)}</h4>
                            </Card.Header>
                            <BardChart
                                labels={data.categories?.map((n) => n.label) ?? []}
                                series={[
                                    {
                                        data: data?.categories?.map((n) => n.countSafe) ?? [],
                                        name: lang.text(Translations.common.safe),
                                    },
                                    {
                                        data: data?.categories?.map((n) => n.countUnsafe) ?? [],
                                        name: lang.text(Translations.common.unSafe),
                                    },
                                ]}
                            />
                        </Card>
                    </Col>
                )}

                {data?.goldenRules && (
                    <Col className=" flex justify0content-center ">
                        <Card className="card-block">
                            <Card.Header>
                                <h4 className="mb-0">{lang.text(Translations.common.goldenRules)}</h4>
                            </Card.Header>
                            <BardChart
                                labels={data.goldenRules?.map((n) => n.label) ?? []}
                                series={[
                                    {
                                        data: data?.goldenRules?.map((n) => n.countSafe) ?? [],
                                        name: lang.text(Translations.common.safe),
                                    },
                                    {
                                        data: data?.goldenRules?.map((n) => n.countUnsafe) ?? [],
                                        name: lang.text(Translations.common.unSafe),
                                    },
                                ]}
                            />
                        </Card>
                    </Col>
                )}
            </Row>
        </Fragment>
    );
};
export default SORDashboard;
