import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { TNCourseFilterRequest, TNCourseResponse } from 'api/v2/training/models/tnCourse.model';
import tnCourseAPI from 'api/v2/training/tnCourseApi';
import { AddButton } from 'components/Button';
import { InputField, SelectField } from 'components/FormControl';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import Routers from 'router/const/routers';

import { FilterButton } from 'components/Button/FilterButton';
import { Row } from 'components/ui-kit';
import { CourseStatus } from 'enum/training';
import { formatDateTime } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { TNCourseStatusBadge } from '../component/tnCourseStatus.component';
import TNTopicSelect from '../component/tnTopic.select';

const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const TNCoursePage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<TNCourseResponse>>();
    const navigate = useNavigate();

    const { query, setQuery } = useQuery();

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);

    const reportStatus = useMemo(() => {
        return [
            { value: CourseStatus.Completed, label: lang.text(Translations.training.status.completed) },
            { value: CourseStatus.Waiting, label: lang.text(Translations.training.status.waiting) },
        ];
    }, []);

    const cols = useMemo(
        () => [
            {
                field: 'title',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'topicName',
                title: lang.text(Translations.training.topic),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    const { control, handleSubmit, getValues, reset, formState } = useForm();
    useEffect(() => {
        handleFetchData(query as TNCourseFilterRequest);
    }, [query]);

    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        tnCourseAPI
            .getPagination({
                ...query,
                ...pagination,
            })
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                status: <TNCourseStatusBadge status={n.status} />,
                                action: <ActionButtons2 id={n.id} onViewDetail={() => navigate(Routers.trainingOnline.courseDetail.replace(':id', n.id))} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <Card>
                <Card.Body className="d-block">
                    <form
                        className="w-full"
                        onSubmit={handleSubmit((datas) => {
                            setQuery({
                                ...datas,
                                ...defaultPaginationRequest,
                            });
                        })}
                    >
                        <Row>
                            <InputField label={lang.text(Translations.common.name)} required={false} name="keySearch" control={control} placeholder="Nhập tên khóa học để tìm kiếm " md={4} xs={12} />
                            <TNTopicSelect name="topicId" control={control} required={false} md={4} xs={12} />
                            <SelectField label={lang.text(Translations.common.status)} required={false} options={reportStatus} name="status" control={control} unSelectValue={''} md={4} xs={12} />
                            <div className="flex justify-end align-items-end">
                                <AddButton isRender={true} onClick={() => navigate(`${Routers.trainingOnline.courseCreate}`)} />
                                <FilterButton type="submit" className="mx-2" />
                            </div>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
            <PaginationTable
                onPaginate={(pag) => {
                    setQuery({
                        ...query,
                        ...pag,
                    });
                }}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={true}
                wrapperClass="mt-5"
            />
        </Fragment>
    );
};

export default TNCoursePage;
