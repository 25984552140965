import Translations from 'i18n/translation';
import { Save2Fill } from 'react-bootstrap-icons';
import { BaseButtonProps } from './BaseButton.props';
import { lang } from 'i18n/lang';
import { BaseButton } from './BaseButton';
import Color from 'enum/color';

export function SaveButton({ onClick, type, disable, className, text, color }: BaseButtonProps) {
    return (
        <BaseButton
            isRender={true}
            icon="pi pi-check"
            text={text ?? lang.text(Translations.form.done)}
            disable={disable ?? false}
            onClick={onClick}
            color={color ?? Color.success}
            type={type}
            size="sm"
            className={className ?? '' + ' rounded flex align-items-end gap-1  w-max'}
        ></BaseButton>
    );
}
