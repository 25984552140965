import { yupResolver } from '@hookform/resolvers/yup';
import { EquipmentReportTemplateCreateRequest, EquipmentReportTemplateResponse, EquipmentReportTemplateUpdateRequest } from 'api/models';
import equipmentApi from 'api/v2/equipmentApi';
import { CompanySelect } from 'components/Common';
import { InputField, SelectField, SelectOption } from 'components/FormControl';
import MutilSelectField from 'components/FormControl/MutilSelectField';
import ModalFooterButtons from 'components/Modal/ModelFooterButtons';
import { ModalHookComponentProps } from 'components/ModalHook';
import { EquipmentReportType } from 'enum';
import { EnumHelper } from 'helper/enum.helper';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface EquipmentTemplateAction extends ModalHookComponentProps {
    template?: EquipmentReportTemplateResponse;
}

const AddOrUpdateComponent: React.FC<EquipmentTemplateAction> = ({ onClose, onCompleted, template }) => {
    const [types, setTypes] = useState<SelectOption[]>([]);
    const schema = yup.object().shape({
        companyIds: yup.array().required(),
        typeIds: yup.array().required(),
        name: yup.string().required().min(1).max(200),
        reportType: yup.string().required(),
    });
    useEffect(() => {
        if (!template) return;
        reset({
            companyIds: template.companies?.map((n) => n.id),
            typeIds: template?.typeGroups?.map((n) => n.id),
            name: template.templateName,
            reportType: template.reportType,
        });
    }, [template]);

    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {
            companyIds: template?.companies?.map((n) => n.id) ?? [],
            typeIds: template?.typeGroups?.map((n) => n.id) ?? [],
            reportType: template?.reportType ?? EquipmentReportType.Daily,
            name: template?.templateName,
        },
        resolver: yupResolver(schema),
    });

    const reportTypes = useMemo(() => {
        var options: SelectOption[] = [
            {
                label: t(Translations.equipment.dailyReportTemplate),
                value: EquipmentReportType.Daily,
            },
            {
                label: t(Translations.equipment.scheduledReportTemplate),
                value: EquipmentReportType.Scheduled,
            },
        ];
        return options;
    }, []);

    const onAddOrUpdateAsync = async () => {
        var form = getValues();
        var type = EnumHelper.toEquipmentReportType(form.reportType);
        if (!template) {
            var payload: EquipmentReportTemplateCreateRequest = {
                companies: form.companyIds,
                name: form.name,
                typeGroups: form.typeIds,
                reportType: type,
            };
            // case add
            equipmentApi
                .createTemplate(payload)
                .then((n) => {
                    onCompleted(true);
                })
                .catch((n) => {});
        } else {
            var payloadUpdate: EquipmentReportTemplateUpdateRequest = {
                companies: form.companyIds,
                name: form.name,
                typeGroups: form.typeIds,
                reportType: type,
                id: template.id,
            };
            // case update
            equipmentApi
                .updateTemplate(payloadUpdate)
                .then((n) => {
                    onCompleted(true);
                })
                .catch((n) => {});
        }
    };

    const onCompanyAddChange = (orgs: string[]) => {
        equipmentApi
            .getTypeByCompanies(orgs)
            .then((types) => {
                var newTypes = types.toSelectOptions('typeGroupName', 'id');
                setTypes(newTypes);
            })
            .catch((e) => {});
    };
    return (
        <form onSubmit={handleSubmit(onAddOrUpdateAsync)}>
            <InputField md={12} xs={12} name="name" control={control} label={t(Translations.common.name)} />
            <CompanySelect
                md={12}
                xs={12}
                name="companyIds"
                onCompaniesChange={(ids) => {
                    onCompanyAddChange(ids);
                }}
                isMutiple={true}
                control={control}
                required={true}
            />
            <MutilSelectField md={12} xs={12} label={t(Translations.equipment.type)} options={types} name="typeIds" control={control} required={true} />

            <SelectField md={12} xs={12} label={t(Translations.equipment.templateCategories)} options={reportTypes} name="reportType" control={control} required={true} />
            <ModalFooterButtons onClose={onClose} okButtonType="submit" />
        </form>
    );
};
export default AddOrUpdateComponent;
