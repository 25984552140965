import { IIRCauseGroupModel } from 'api/v2/iir/model/iirReportComponent.model';
import CheckboxTreeComponent, { TreeNode } from 'components/Common/CheckBoxTreeComponent';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { IIRListComponentProps } from './iirBaseItem.component';

interface IIRRootCauseComponentProps extends IIRListComponentProps {
    title: string;
    name: string;
    control: Control<any>;
}
export default function IIRRootCauseFormComponent(props: IIRRootCauseComponentProps) {
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [groupIds, setGroupIds] = useState<string[]>([]);

    useEffect(() => {
        let nod = props.datas.map((group: IIRCauseGroupModel) => ({
            value: group.id,
            label: group.name,
            children: group.causes.map((cause) => ({ value: cause.id, label: `${cause.causeName}<hr/> jfadlsjflksdj` })),
        }));

        setNodes(nod);
        setGroupIds(props.datas?.map((n) => n.id) ?? []);
    }, [props.datas]);

    // const onCheckedTempChanged = (checkedChange: Array<string>, node: OnCheckNode) => {
    //     var newDatas = checkedChange.filter((n) => !groupIds.includes(n));
    //     if (!newDatas.equals(props.checked)) {
    //         props.onChanged(newDatas);
    //     }
    // };
    return (
        <CheckboxTreeComponent isUserForm control={props.control} name={props.name} options={nodes} />

        // <Card>
        //     <h6>{props.title}</h6>

        //     <CheckboxTree
        //         icons={{
        //             check: <CheckSquare className="text-primary bold" />,
        //             uncheck: <Square className="text-primary bold" />,
        //             halfCheck: <DashSquare className="text-primary bold" />,
        //             expandClose: <ChevronRight />,
        //             expandOpen: <ChevronDown />,
        //             expandAll: <PlusSquare className="text-primary" />,
        //             collapseAll: <NodeMinus className="text-primary" />,
        //             parentClose: <></>,
        //             parentOpen: <></>,
        //             leaf: <></>,
        //         }}
        //         nodes={nodes}
        //         checked={props.checked}
        //         expanded={expanded}
        //         onCheck={onCheckedTempChanged}
        //         onExpand={setExpanded}
        //     />
        // </Card>
    );
}
