import { AccordionProps, Accordion as PrimeAccordion } from 'primereact/accordion';

// // Define the main Card component
// const AccordionBase: FC<AccordionProps> = (props) => {
//     const { children, ...rest } = props;
//     return <PrimeAccordion {...rest}>{children}</PrimeAccordion>;
// };

// const AccordionHeader: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
//     <div className="p-accordion-header" {...props}>
//         {children}
//     </div>
// );

// const AccordionBody: FC<HTMLAttributes<HTMLHeadingElement>> = ({ children, ...props }) => (
//     <h5 className="p-accordion-content" {...props}>
//         {children}
//     </h5>
// );
// export const Accordion: FC<AccordionProps> & {
//     Header: typeof AccordionHeader;
//     Body: typeof AccordionBody;
// } = AccordionBase as FC<AccordionProps> & {
//     Header: typeof AccordionHeader;
//     Body: typeof AccordionBody;
// };

export default PrimeAccordion;
export type { AccordionProps };
