import React, { CSSProperties } from 'react';

type RowProps = {
    children: React.ReactNode;
    className?: string;
    cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    style?: CSSProperties | undefined;
};
const colsClass: any = {
    12: 'grid-cols-12',
    11: 'grid-cols-11',
    10: 'grid-cols-10',
    9: 'grid-cols-9',
    8: 'grid-cols-8',
    7: 'grid-cols-7',
    6: 'grid-cols-6',
    5: 'grid-cols-5',
    4: 'grid-cols-4',
    3: 'grid-cols-3',
    2: 'grid-cols-2',
    1: 'grid-cols-1',
};

const Row: React.FC<RowProps> = ({ style, children, className, cols }) => {
    return (
        <div style={style} className={`grid ${colsClass[String(cols ?? 12)]} gap-4 my-2 ${className ?? ''}`}>
            {children}
        </div>
    );
};

export default Row;
