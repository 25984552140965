import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { DocumentResponse } from 'api/v2/document/model/document.model';
import { LegalDocumentFilterRequest, LegalDocumentResponse } from 'api/v2/legal-document/legalDocument.models';
import legalDocumentApi from 'api/v2/legal-document/legalDocumentApi';
import { AddButton, DownloadButton } from 'components/Button';
import { FilterButton } from 'components/Button/FilterButton';
import useModalHook from 'components/ModalHook';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row } from 'components/ui-kit';
import { formatDate, formatDateTime } from 'helper';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Routers from 'router/const/routers';
import LegalDocGroupSelect from './component/legalDocumentGroupSelect';
import LegalDocumentTagSelect from './component/legalDocumentTagSelect';
import LegalDocumentCreatePage from './legalDocumentCreatePage';

// eslint-disable-next-line react-hooks/exhaustive-deps
const LegalDocumentListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'title',
                title: lang.text(Translations.legalDocument.title),
            },
            {
                field: 'referenceNumber',
                title: lang.text(Translations.legalDocument.referenceNumber),
            },
            {
                field: 'groupName',
                title: lang.text(Translations.legalDocument.group),
            },
            {
                field: 'issuePlace',
                title: lang.text(Translations.legalDocument.issuePlace),
            },
            {
                field: 'effectiveDateFrom',
                title: lang.text(Translations.legalDocument.effectiveDateFrom),
            },
            {
                field: 'effectiveDateTo',
                title: lang.text(Translations.legalDocument.effectiveDateTo),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    const location = useLocation();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    var navigate = useNavigate();
    const [paginationData, setPaginationData] = useState<PaginationBase<DocumentResponse>>();
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();

    useEffect(() => {
        let id = getQueryParam('id');
        id && showDetail(id);
    }, [location.search]);

    const showDetail = (id: string) => {
        id &&
            legalDocumentApi
                .getById(id)
                .then(async (n) => {
                    // setLegalDocumentDetail(n);
                    await openModal({
                        isFullScreen: true,
                        title: lang.text(Translations.equipment.templateCategories),
                        isResetRoute: true,
                        body: <LegalDocumentCreatePage detail={n} />,
                    });
                })
                .catch((n) => {});
    };
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as LegalDocumentFilterRequest;
        legalDocumentApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n: LegalDocumentResponse) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                effectiveDateFrom: formatDate(n.effectiveDateFrom),
                                effectiveDateTo: formatDate(n.effectiveDateTo),
                                action: <ActionButtons2 id={n.id ?? ''} onViewDetail={() => navigate('?id=' + n.id, { replace: true })} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const downloadReport = () => {
        if (!paginationData?.datas || paginationData.datas.length == 0) {
            toast.info('Không có dữ liệu');
            return;
        }
        var form = getValues();
        let filter = {
            currentPage: 1,
            rowsPerPage: 1000,
            ...form,
        } as LegalDocumentFilterRequest;
        legalDocumentApi
            .downloadReport(filter)
            .then(() => {})
            .catch(() => {});
    };
    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Header>
                        <form className="w-full" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <Row>
                                <LegalDocGroupSelect md={4} xs={12} control={control} name="groupId" />
                                <LegalDocumentTagSelect md={4} xs={12} isCanCreate={false} isMulti={false} control={control} name="tagId" />
                            </Row>
                            <Row>
                                <Col md={12} className="flex justify-end">
                                    <AddButton isRender={true} onClick={() => navigate(Routers.legalDocument.create)} text={lang.text(Translations.common.upload)} />
                                    <DownloadButton color="warning" onClick={() => downloadReport()} />
                                    <FilterButton type="submit" />
                                </Col>
                            </Row>
                        </form>
                    </Card.Header>
                    <Card.Body>
                        <PaginationTable
                            onSearch={(pagi) => {
                                defaultPaginationRequest.keySearch = pagi.keySearch;
                            }}
                            onPaginate={handleFetchData}
                            columns={cols}
                            pagination={paginationData}
                            isStriped={true}
                            disableSearch={false}
                            wrapperClass="mt-5"
                        />
                        {/* <ModalFullScreen
                            title={lang.text(Translations.common.reportDetail)}
                            bodyModal={
                                <LegalDocumentCreatePage
                                    // onDeleted={() => {
                                    //     navigate('', { replace: true });
                                    //     handleFetchData(defaultPaginationRequest);
                                    //     setLegalDocumentDetail(null);
                                    // }}
                                    detail={legalDocumentDetail}
                                />
                            }
                            showModal={legalDocumentDetail != null && legalDocumentDetail != undefined}
                            handleCloseModal={() => {
                                navigate('', { replace: true });
                                setLegalDocumentDetail(null);
                            }}
                        /> */}
                    </Card.Body>
                </Card>
            </div>
            {Prompt}
        </Fragment>
    );
};

export default LegalDocumentListPage;
