// @ts-nocheck

//react-bootstrap

//components
import { PPRReportDetailResponse } from 'api/v2/ppr/model/ppr.model';
import pprReportApi from 'api/v2/ppr/pprReportApi';
import { DeleteButton, DownloadButton } from 'components/Button';
import { CloseButton } from 'components/Button/CloseButton';
import ToastConfirm from 'components/Modal/ToastConfirm';
import useModalHook from 'components/ModalHook';
import DataListView from 'components/shared/DataListView';
import { Accordion, Card, Col, Row } from 'components/ui-kit';
import { PPRStatus } from 'enum/ppr';
import { formatDate, formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { ModalDetailProps } from 'models/modalDetailProps';
import { AccordionTab } from 'primereact/accordion';
import { useEffect, useState } from 'react';
import PPRCloseComponent from './close.component';
const PPRDetailPage = ({ id, onDeleted }: ModalDetailProps) => {
    const [report, setReport] = useState<PPRReportDetailResponse>(null);
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    useEffect(() => {
        loadReport();
    }, []);

    const onCloseReport = async () => {
        var rs = await openModal({
            title: lang.text(Translations.common.closeReport),
            body: <PPRCloseComponent companyId={report.companyId} id={report.id} onClose={onClose} onCompleted={onCompleted} />,
        });
        rs &&
            pprReportApi
                .closeSReport({
                    ...rs,
                })
                .then((n) => {
                    loadReport();
                })
                .catch((n) => {});
    };

    const loadReport = () => {
        if (id)
            pprReportApi
                .getById(id)
                .then((r: PPRReportDetailResponse) => {
                    setReport(r);
                })
                .catch((n) => {});
    };
    const onDelete = () => {
        if (id) {
            pprReportApi
                .delete(id)
                .then(() => props.onDeleted && props.onDeleted())
                .catch(() => {});
        }
    };
    const onDownload = () => {
        if (id) {
            pprReportApi
                .downloadReport(id)
                .then((n) => {})
                .catch((n) => {});
        }
    };
    return (
        <div>
            <Card>
                <Row>
                    <div className="flex justify-end">
                        {report?.canClose && <CloseButton onClick={onCloseReport} />}
                        {report?.canDelete && (
                            <ToastConfirm message={lang.text(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                <DeleteButton />
                            </ToastConfirm>
                        )}
                        <DownloadButton onClick={onDownload} isRender={report !== undefined} />
                    </div>
                </Row>
            </Card>
            {report && (
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <DataListView
                                    datas={[
                                        {
                                            label: lang.text(Translations.form.site.name),
                                            value: report.companyName,
                                        },
                                        {
                                            label: lang.text(Translations.form.area.name),
                                            value: report.areaName,
                                        },
                                        {
                                            label: lang.text(Translations.contractor),
                                            value: report.contractorName,
                                        },
                                        {
                                            label: lang.text(Translations.common.pic),
                                            value: report.pICUserFullName,
                                        },
                                        {
                                            label: lang.text(Translations.common.createdBy),
                                            value: report.createdUserName,
                                        },
                                        {
                                            label: lang.text(Translations.common.createdTime),
                                            value: report.createdTime && formatDateTime(report.createdTime),
                                        },
                                    ]}
                                />
                            </Col>
                            <Col md={6}>
                                {report && report.status == PPRStatus.CLOSED && (
                                    <DataListView
                                        datas={[
                                            {
                                                label: lang.text(Translations.common.closedBy),
                                                value: report.closedUserFullName,
                                            },
                                            {
                                                label: lang.text(Translations.common.closedDate),
                                                value: report.closedDate && formatDateTime(report.closedDate),
                                            },
                                            {
                                                label: lang.text(Translations.common.description),
                                                value: report.closedDescription,
                                            },
                                        ]}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
            {report && (
                <Card>
                    <Row>
                        <Accordion defaultActiveKey="0">
                            {report.tasks.map((item, index) => (
                                <AccordionTab
                                    header={
                                        <div className="flex w-full justify-between px-2">
                                            <span># {index + 1}</span>
                                        </div>
                                    }
                                    key={index}
                                >
                                    {[
                                        { label: lang.text(Translations.common.fromDate), value: formatDate(item.fromDate) },
                                        { label: lang.text(Translations.common.toDate), value: formatDate(item.toDate) },
                                        { label: lang.text(Translations.wpr.ppr.descriptionTask), value: item.descriptionTask },
                                        { label: lang.text(Translations.wpr.ppr.type), value: item.taskType },
                                        { label: lang.text(Translations.wpr.ppr.statusTask), value: item.statusTask },
                                        { label: lang.text(Translations.wpr.ppr.object), value: item.targetObjectName },
                                    ].map((item, index) => (
                                        <div key={index} className="flex">
                                            <b style={{ minWidth: '25%' }}>{item.label} </b>
                                            <span style={{ minWidth: '75%' }}>{item.value}</span>
                                            <hr />
                                        </div>
                                    ))}
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </Row>
                </Card>
            )}
            {Prompt}
        </div>
    );
};

export default PPRDetailPage;
