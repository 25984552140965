import { Col, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { DataView } from 'primereact/dataview';
import { CSSProperties } from 'react';
export interface DataListViewItem {
    label: any | string;
    value: any;
}
interface DataListViewProps {
    datas: DataListViewItem[];
    bordered?: boolean;
    classNameRow?: string;
    firstColumnStyle?: CSSProperties; // class fist column, use incase first column is index (1,2,3,4...)
    header?: any;
    headerGeneralInfo?: boolean;
    headerClosedInfo?: boolean;
}
const DataListView = ({ firstColumnStyle, datas, headerGeneralInfo, header, headerClosedInfo, bordered, classNameRow = 'py-2' }: DataListViewProps) => {
    const listTemplate = (items: DataListViewItem[]) => {
        if (!items || items.length === 0) return null;

        let list = items.map((detail, index) => {
            return (
                <Row className="flex grap-2 data-list-view-item pb-1" key={index}>
                    <Col md={4}>{detail.label}</Col>
                    <Col md={8}>{detail.value}</Col>
                </Row>
            );
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };

    return (
        datas && (
            <div>
                {/* <ResponsiveTable */}
                {headerGeneralInfo == true ? <h6 className="text-primary">{lang.text(Translations.common.generalInfo)} </h6> : ''}
                {headerClosedInfo == true ? <h6 className="text-primary">{lang.text(Translations.common.closeReport)} </h6> : ''}
                {header && <b className="text-primary mb-2">{header}</b>}
                <DataView value={datas} listTemplate={listTemplate} />
                {/* <tabl value={datas} stripedRows={bordered}>
                    {datas.map((item, index) => (
                        // <tr key={index}>
                        //     <td className={classNameRow} style={firstColumnStyle}>
                        //         {item.label}
                        //     </td>
                        //     <td className={classNameRow}>{item.value}</td>
                        // </tr>
                        <Column key={index} field={item.value} header={item.label} />
                    ))}
                </tabl> */}
            </div>
        )
    );
};
export default DataListView;
