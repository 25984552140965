import { setShowCustomerModal } from 'app/appSlice';
import { Button, Dropdown, Menu } from 'components/ui-kit';
import useLayout, { LAYOUT_TYPE } from 'hooks/useLayout';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { InputSwitch } from 'primereact/inputswitch';
import { useAuth } from 'providers/contexts';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routers from 'router/const/routers';

export default function UserProfileActions() {
    const { tokenProfile, logOut } = useAuth();
    const [show1, setShow1] = useState(false);
    const [currentLayout, setCurrentLayout] = useLayout();

    const navigate = useNavigate();
    const menuRight = useRef(null);

    const dispatch = useDispatch();
    // show modal choose customer
    const showCustomerModal = () => {
        dispatch(setShowCustomerModal(true));
    };

    const switchLayout = () => {
        setCurrentLayout(currentLayout === LAYOUT_TYPE.BOX ? LAYOUT_TYPE.DEFAULT : LAYOUT_TYPE.BOX);
        setTimeout(() => {
            window.location.reload();
        }, 200);
    };

    useEffect(() => {
        //offcanvase code
        const result = window.matchMedia('(max-width: 1200px)');
        window.addEventListener('resize', () => {
            if (result.matches === true) {
                if (show1 === true) {
                    document.documentElement.style.setProperty('overflow', 'hidden');
                } else {
                    document.documentElement.style.removeProperty('overflow');
                }
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        });
        if (window.innerWidth <= 1200) {
            if (show1 === true) {
                document.documentElement.style.setProperty('overflow', 'hidden');
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        } else {
            document.documentElement.style.removeProperty('overflow');
        }
    });

    const items = [
        {
            label: 'SOP',
            items: [
                {
                    label: lang.text(Translations.common.profile),
                    command: () => {
                        navigate(Routers.user.profile);
                    },
                },
                {
                    label: lang.text(Translations.common.changePassword),
                    command: () => {
                        navigate(Routers.auth.changePassword);
                    },
                },
                {
                    label: lang.text(Translations.form.customer.name),
                    command: showCustomerModal,
                },
                {
                    label: lang.text(Translations.common.oldLayout),
                    template: (item: any) => {
                        return (
                            <div className="cursor-pointer flex justify-between items-center px-4 py-2" onClick={() => switchLayout()}>
                                {item.label}
                                <InputSwitch checked={currentLayout === LAYOUT_TYPE.BOX} />
                            </div>
                        );
                    },
                },
                {
                    separator: true,
                },
                {
                    label: lang.text(Translations.common.logout),
                    command: logOut,
                },
            ],
        },
    ];

    return (
        <div className="flex gap-4 items-center">
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            {/* @ts-ignore */}
            <Button icon="pi pi-user !text-md -mr-1" className="mr-2 text-primary font-bold" onClick={(event) => menuRight.current.toggle(event)} outlined />
            {/* Notification */}
            <Dropdown className="nav-item hidden">
                {/* <Dropdown.Toggle as={CustomToggle} variant="nav-link ">
                    <div className="btn btn-primary btn-icon btn-sm rounded-pill btn-action">
                        <span className="btn-inner">
                            <i className="icon">
                                <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        opacity="0.4"
                                        d="M21.9999 15.9402C21.9999 18.7302 19.7599 20.9902 16.9699 21.0002H16.9599H7.04991C4.26991 21.0002 1.99991 18.7502 1.99991 15.9602V15.9502C1.99991 15.9502 2.00591 11.5242 2.01391 9.29821C2.01491 8.88021 2.49491 8.64621 2.82191 8.90621C5.19791 10.7912 9.44691 14.2282 9.49991 14.2732C10.2099 14.8422 11.1099 15.1632 12.0299 15.1632C12.9499 15.1632 13.8499 14.8422 14.5599 14.2622C14.6129 14.2272 18.7669 10.8932 21.1789 8.97721C21.5069 8.71621 21.9889 8.95021 21.9899 9.36721C21.9999 11.5762 21.9999 15.9402 21.9999 15.9402Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M21.476 5.6736C20.61 4.0416 18.906 2.9996 17.03 2.9996H7.05001C5.17401 2.9996 3.47001 4.0416 2.60401 5.6736C2.41001 6.0386 2.50201 6.4936 2.82501 6.7516L10.25 12.6906C10.77 13.1106 11.4 13.3196 12.03 13.3196C12.034 13.3196 12.037 13.3196 12.04 13.3196C12.043 13.3196 12.047 13.3196 12.05 13.3196C12.68 13.3196 13.31 13.1106 13.83 12.6906L21.255 6.7516C21.578 6.4936 21.67 6.0386 21.476 5.6736Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </i>
                        </span>
                        <span className="notification-alert"></span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="end p-0 sub-drop">
                    <Card className="m-0 shadow-none ">
                        <Card.Header className="py-3 flex justify-between bg-primary">
                            <div className="header-title">
                                <h5 className="mb-0 text-white">All Carts</h5>
                            </div>
                        </Card.Header>
                        <Card.Footer className="p-0">
                            <div className="d-grid">
                                <Link to="/e-commerce/order-process" className="btn btn-primary">
                                    View All
                                </Link>
                            </div>
                        </Card.Footer>
                    </Card>
                </Dropdown.Menu> */}
            </Dropdown>
        </div>
    );
}
