//react-bootstrap
import orgUnitApi from 'api/v2/organizationUnitApi';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationUnitResponse } from 'api/models/organization';
import { SaveButton } from 'components/Button';
import { InputField, UploadImageField } from 'components/FormControl';
import { Col, Row } from 'components/ui-kit';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const ConfigCompanyUpdatePage = memo(() => {
    const { loadInfrastructure } = useCustomer();
    const { t } = useTranslation();
    const params = useParams();
    const [logo, setLogo] = useState<string | null>('');

    const schema = yup.object().shape({
        name: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        logo: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        setValue,
    } = useForm<{ name: string; logo?: string | undefined }>({
        defaultValues: {
            name: '',
            logo: '',
        },
        resolver: yupResolver(schema),
    });

    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        params.id &&
            orgUnitApi
                .getById(params.id)
                .then((res: OrganizationUnitResponse) => {
                    setValue('name', res.name);
                    setValue('logo', res.logo ?? undefined);
                    setLogo(res.logo);
                })
                .catch(() => {});
    }, []);

    const handleConfirmSubmit = () => {
        setOpenConfirm(true);
    };

    const handleAddCategory = () => {
        if (!params.id) return;
        const values = getValues();

        const _addData = {
            name: values.name,
            logo: values.logo,
            id: params.id,
        };

        orgUnitApi
            .update(_addData)
            .then(() => {
                setOpenConfirm(false);
                loadInfrastructure();
            })
            .catch((n) => {});
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <form onSubmit={handleSubmit(handleConfirmSubmit)}>
                        <Row className="justify-end">
                            <SaveButton type="submit" />
                        </Row>
                        <Row>
                            <InputField md={12} required={true} name="name" control={control} label={t(Translations.form.site.name)} placeholder="" />

                            <Col>
                                <UploadImageField md={12} control={control} src={logo} btnText={t(Translations.common.upload)} name="logo" />
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigCompanyUpdatePage;
