import { Dialog, DialogProps } from 'primereact/dialog';
import { FC, HTMLAttributes } from 'react';
// const Modal = Dialog;

const ModalBase: FC<DialogProps> = (props) => {
    const { children, ...rest } = props;
    return <Dialog {...rest}>{children}</Dialog>;
};

const ModalFooter: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
    <div className="p-dialog-footer mt-4" {...props} style={{ padding: 0 }}>
        {children}
    </div>
);
const ModalBody: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
    <div className="p-dialog-content" {...props}>
        {children}
    </div>
);
export const Modal: FC<DialogProps> & {
    Footer: typeof ModalFooter;
    Body: typeof ModalBody;
} = ModalBase as FC<DialogProps> & {
    Footer: typeof ModalFooter;
    Body: typeof ModalBody;
};

Modal.Footer = ModalFooter;
export default Modal;
export type { DialogProps };
