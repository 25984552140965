import { yupResolver } from '@hookform/resolvers/yup';
import { CustomerCreateRequest } from 'api/models/organization';
import { SaveButton } from 'components/Button';
import { TextAreaField, UploadImageField } from 'components/FormControl';
import { ModalHookComponentProps } from 'components/ModalHook';
import { Card, Col, Row } from 'components/ui-kit';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface AddFormProps extends ModalHookComponentProps {
    name?: string;
    logo?: string;
}
// const Component = ({ id, name, description, onClose, onCompleted }:SORCategoryFormProps)
export function AddForm({ name, logo, onClose, onCompleted }: AddFormProps) {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        name: yup.string().required(t(Translations.form.validate.required)),
        logo: yup.string().nullable(),
    });
    const { control, handleSubmit, getValues } = useForm({
        defaultValues: {
            logo,
            name,
        },
        resolver: yupResolver(schema),
    });

    const handleFormSubmit = async (formValues: CustomerCreateRequest) => {
        var form = getValues();
        let data: CustomerCreateRequest = {
            ...form,
        };
        onCompleted(data);
    };

    return (
        <>
            <form>
                <Card>
                    <Card.Body>
                        <form className=" justify-center" onSubmit={handleSubmit(handleFormSubmit)}>
                            <Row>
                                <Col>
                                    <UploadImageField control={control} btnText={t(Translations.common.upload)} name="logo" src={logo} />
                                </Col>
                                <TextAreaField formGroupClasses="mt-3" required={true} name="name" control={control} label={t(Translations.form.customer.name)} placeholder="" defaultValue={name} />
                            </Row>
                        </form>
                    </Card.Body>
                    <Card.Footer className="flex justify-end">
                        <>
                            {/* <CancelButton onClick={handleClose} type="button" /> */}
                            <SaveButton onClick={handleSubmit(handleFormSubmit)} type="submit" />
                        </>
                    </Card.Footer>
                </Card>
            </form>
        </>
    );
}
