import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';

import { PaginationBase, defaultPaginationRequest } from 'api/models/common';
import { TripReportFilter, TripReportResponse, TripReportType } from 'api/v2/trip/model/trip.model';
import tripApi from 'api/v2/trip/tripApi';
import { FilterButton } from 'components/Button/FilterButton';
import { CompanySelect } from 'components/Common';
import EquipmentSelectField from 'components/Common/EquipmentSelect';
import UserSelectField from 'components/Common/UserSelect';
import { SelectField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import useModalHook from 'components/ModalHook';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row } from 'components/ui-kit';
import { useQuery } from 'hooks/useQuery';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import moment from 'moment';
import { useForm } from 'react-hook-form';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const TripReportPage = () => {
    const { query, setQuery } = useQuery();
    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.common.name),
            },
            {
                field: 'totalKm',
                title: lang.text(Translations.trip.report.totalDistance),
            },
            {
                field: 'countTrip',
                title: lang.text(Translations.trip.report.totalTrip),
            },
        ],
        []
    );

    const { Prompt } = useModalHook();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { control, handleSubmit, watch } = useForm();
    const tripReportType = watch('tripReportType');
    const [companyId, setCompanyId] = useState<string>('');

    useEffect(() => {
        handleFetchData(query as TripReportFilter);
    }, [query]);

    const handleFetchData = async (query: TripReportFilter) => {
        tripApi
            .getTripReport(query)
            .then((datas: TripReportResponse[]) => {
                let pagi: PaginationBase = {
                    ...defaultPaginationRequest,
                    datas: datas ?? [],
                    totalItems: datas?.length || 0,
                    totalPage: 1,
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const reportTypes = useMemo(
        () => [
            {
                label: lang.text(Translations.trip.passengers),
                value: TripReportType.Passenger,
            },
            {
                label: lang.text(Translations.equipment.equipment),
                value: TripReportType.Equipment,
            },
        ],
        []
    );
    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <form
                            className="w-full"
                            onSubmit={handleSubmit((data) => {
                                setQuery({
                                    companyId: data?.companyId || '',
                                    passengerId: data?.passengerId || '',
                                    equipmentId: data?.equipmentId || '',
                                    fromDate: data?.fromDate ? moment(data?.fromDate).format('MM-DD-YYYY') : '',
                                    toDate: data?.toDate ? moment(data?.toDate).format('MM-DD-YYYY') : '',
                                    tripReportType: data?.tripReportType,
                                });
                            })}
                        >
                            <Row>
                                <CompanySelect onCompanyChange={setCompanyId} defaultValue={String(query?.companyId || '')} name="companyId" control={control} />
                                <SelectField
                                    md={4}
                                    xs={12}
                                    label={lang.text(Translations.common.reportType)}
                                    options={reportTypes}
                                    defaultValue={TripReportType.Equipment}
                                    name={'tripReportType'}
                                    control={control}
                                />

                                {tripReportType === TripReportType.Equipment && (
                                    <EquipmentSelectField defaultValue={String(query?.equipmentId || '')} md={4} xs={12} name="equipmentId" control={control} />
                                )}
                                {tripReportType === TripReportType.Passenger && (
                                    <UserSelectField
                                        companyId={companyId}
                                        defaultValue={String(query?.passengerId || '')}
                                        label={lang.text(Translations.trip.passengers)}
                                        name="passengerId"
                                        control={control}
                                        md={4}
                                        xs={12}
                                    />
                                )}
                                <DateTimeField
                                    md={4}
                                    xs={12}
                                    name={'fromDate'}
                                    defaultDate={defaultFromDate}
                                    maxDate={defaultToDate}
                                    label={lang.text(Translations.common.fromDate)}
                                    control={control}
                                />
                                <DateTimeField md={4} xs={12} name={'toDate'} defaultDate={defaultToDate} maxDate={new Date()} label={lang.text(Translations.common.toDate)} control={control} />
                                <Col className=" flex justify-end">
                                    <FilterButton type="submit" />
                                </Col>
                            </Row>
                        </form>
                    </Card.Body>
                </Card>
                <PaginationTable
                    // onSearch={(keySearch) =>
                    //     setQuery({
                    //         ...query,
                    //         keySearch: keySearch,
                    //     })
                    // }
                    disableSearch={true}
                    columns={cols}
                    pagination={paginationData}
                    isStriped={true}
                    wrapperClass="mt-5"
                />
            </div>
            {Prompt}
        </Fragment>
    );
};

export default TripReportPage;
