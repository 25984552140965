import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileCourseRequest } from 'api/v2/user/model';
import { InputField } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import UploadFileField from 'components/FormControl/UploadFileField';
import ModalFooterButtons from 'components/Modal/ModelFooterButtons';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface ProfileCourseFormProps extends ModalHookComponentProps {
    profile?: ProfileCourseRequest;
}
const ProfileCourseForm = ({ profile, onClose, onCompleted }: ProfileCourseFormProps) => {
    const { control, handleSubmit, getValues } = useForm<ProfileCourseRequest>({
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required(),
            })
        ),
    });
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const onSubmit = async () => {
        var form = getValues();
        let data: ProfileCourseRequest = {
            ...form,
            fileName: fileName,
            id: profile?.id,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputField name={'name'} control={control} defaultValue={profile?.name} label={lang.text(Translations.common.name)} readOnly={false} />
            <InputField name={'organizeUnitName'} control={control} defaultValue={profile?.organizeUnitName} label={lang.text(Translations.userProfile.courseOrganizeUnit)} readOnly={false} />
            <DateTimeField name={'startDate'} control={control} defaultDate={profile?.startDate} label={lang.text(Translations.userProfile.courseStartDate)} readOnly={false} />
            <DateTimeField name={'expiredDate'} control={control} defaultDate={profile?.expiredDate} label={lang.text(Translations.common.expireDate)} readOnly={false} />
            <InputField name={'status'} control={control} defaultValue={profile?.status} label={lang.text(Translations.common.status)} readOnly={false} />
            <UploadFileField
                accepts=".pdf"
                md={4}
                name="filePath"
                control={control}
                onUploaded={(n) => {
                    setFileName(n.fileName);
                }}
                defaultFileName={profile?.fileName ?? ''}
                defaultFilePath={profile?.filePath ?? ''}
            />
            <ModalFooterButtons onClose={onClose} okButtonType="submit" />
        </form>
    );
};

export default ProfileCourseForm;
