import { Fragment, useEffect, useState } from 'react';

import equipmentApi from 'api/v2/equipmentApi';
import { Accordion, Table } from 'components/ui-kit';

import { EquipmentReportTemplateGroupResponse, EquipmentReportTemplateItemResponse, EquipmentReportTemplateResponse } from 'api/models/equipments';
import { AddButton, DeleteButton, UpdateButton } from 'components/Button';
import usePromptInputText from 'components/Modal/PromptInputText';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { Col, Row } from 'components/ui-kit/index';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { AccordionTab } from 'primereact/accordion';
import { useAuth } from 'providers/contexts';
import { useTranslation } from 'react-i18next';

const EquipmentTemplateDetail = (props: { id: string }) => {
    const { t } = useTranslation();
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    const [templateDetail, setTemplateDetail] = useState<EquipmentReportTemplateResponse | null>(null);
    const { userPms } = useAuth();
    const onAddGroup = async () => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        if (!text) return;
        equipmentApi
            .createTemplateGroup({
                name: text,
                reportTemplateId: props.id,
            })
            .then((groupId) => {
                let group = templateDetail?.groups ?? [];
                group.push({
                    groupName: text,
                    id: groupId,
                    items: [],
                });
                var temp: any = {
                    ...templateDetail,
                    groups: group,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };
    const onDeleteGroup = async (group: EquipmentReportTemplateGroupResponse) => {
        equipmentApi
            .deleteTemplateGroup(group.id)
            .then(() => {
                let groups = templateDetail?.groups?.filter((g) => g.id != group.id) ?? [];

                var temp: any = {
                    ...templateDetail,
                    groups: groups,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };
    const onUpdateGroup = async (group: EquipmentReportTemplateGroupResponse) => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: group.groupName,
        });
        if (!text) return;
        equipmentApi
            .updateTemplateGroup({
                name: text,
                id: group.id,
            })
            .then(() => {
                group.groupName = text;
                var temp: any = {
                    ...templateDetail,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };

    const onAddItem = async (group: EquipmentReportTemplateGroupResponse) => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        if (!text) return;
        equipmentApi
            .createTemplateItem({
                itemName: text,
                groupTemplateId: group.id as string,
            })
            .then((itemId) => {
                let item = {
                    itemName: text,
                    id: itemId,
                    groupTemplateId: group.id,
                };
                if (!group.items) group.items = [];
                group.items.push(item);

                var temp = { ...templateDetail };
                temp?.groups?.map((g) => {
                    if (g.id == group.id) {
                        g.items = group.items;
                        return;
                    }
                });
                setTemplateDetail(temp as EquipmentReportTemplateResponse);
            })
            .catch((n) => {});
    };

    const onDeleteItem = async (group: EquipmentReportTemplateGroupResponse, item: EquipmentReportTemplateItemResponse) => {
        equipmentApi
            .deleteTemplateItem(item.id)
            .then(() => {
                group.items = group.items?.filter((n) => n.id != item.id) ?? [];
                // var gr = group.items.filter((n) => n.id != item.id);
                // templateDetail.groups = templateDetail?.groups?.filter(n=>n.id != group.id)
                var temp = {
                    ...templateDetail,
                };
                setTemplateDetail(temp as EquipmentReportTemplateResponse);
            })
            .catch((n) => {});
    };
    const onUpdateItem = async (item: EquipmentReportTemplateItemResponse) => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: item.itemName,
        });
        if (!text) return;
        equipmentApi
            .updateTemplateItem({
                itemName: text,
                id: item.id,
            })
            .then(() => {
                item.itemName = text;
                var temp: any = {
                    ...templateDetail,
                };
                setTemplateDetail(temp);
            })
            .catch((n) => {});
    };
    useEffect(() => {
        equipmentApi
            .getTemplateById(props.id)
            .then((temp) => setTemplateDetail(temp))
            .catch(() => {});
    }, []);

    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <h4 className="text-primary">{templateDetail?.templateName}</h4>
                            <Row>
                                {' '}
                                {/* <Col md={4}>
                                    <ListGroup as="ul">
                                        <ListGroup.Item as="li" variant="primary" aria-current="true">
                                            {t(Translations.form.site.name)}
                                        </ListGroup.Item>

                                        {templateDetail?.companies?.map((company) => (
                                            <ListGroup.Item as="li" aria-current="true">
                                                {company.name}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                                <Col md={4}>
                                    <ListGroup as="ul">
                                        <ListGroup.Item as="li" variant="primary" aria-current="true">
                                            {lang.text(Translations.equipment.type)}
                                        </ListGroup.Item>
                                        {templateDetail?.typeGroups?.map((company) => (
                                            <ListGroup.Item as="li" aria-current="true">
                                                {company.typeGroupName}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                {templateDetail && (
                    <Col>
                        <Card>
                            <Card.Body>
                                <div className="flex justify-between flex-wrap media-support-info">
                                    <h4>{t(Translations.equipment.templateCategories)}</h4>
                                    <div className="flex items-center gap-3">
                                        <AddButton isRender={true} onClick={onAddGroup} />
                                    </div>
                                </div>

                                {templateDetail.groups && (
                                    <Accordion>
                                        {templateDetail.groups.map((group, index) => (
                                            <AccordionTab
                                                header={
                                                    <span>
                                                        {' '}
                                                        {group.groupName} - {group?.items ? group?.items?.length : 0} {t(Translations.equipment.templateGroupItem)}
                                                    </span>
                                                }
                                                key={group.id}
                                            >
                                                <Table className="table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="flex justify-start gap-2">
                                                                    <AddButton isRender={true} onClick={() => onAddItem(group)} />
                                                                    <UpdateButton onClick={() => onUpdateGroup(group)} />
                                                                    <ToastConfirm onConfirm={() => onDeleteGroup(group)}>
                                                                        <DeleteButton />
                                                                    </ToastConfirm>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        {group.items &&
                                                            group.items.map((item) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.itemName} </td>
                                                                    <td>
                                                                        <div>
                                                                            <ActionButtons2
                                                                                editPms={userPms.equipment.admin}
                                                                                deletePms={userPms.equipment.admin}
                                                                                id={item.id}
                                                                                editAction={() => onUpdateItem(item)}
                                                                                onDelete={() => onDeleteItem(group, item)}
                                                                                onDeleteSuccess={() => {}}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                            </AccordionTab>
                                        ))}
                                    </Accordion>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
            {PromptInputText}
        </Fragment>
    );
};

export default EquipmentTemplateDetail;
