import { DataTable, DataTableProps } from 'primereact/datatable';
import { CSSProperties } from 'react';

export type TableColumn = {
    field: string;
    title?: string;
    className?: string;
    style?: CSSProperties | undefined;
    header?: any;
};
export default DataTable;
export type { DataTableProps };
