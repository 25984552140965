import { QRBaseResponse } from 'api/v2/common/model/qrBaseResponse';
import { SECRegisterDetailResponse, SECReportRequest } from 'api/v2/sec/model/register.model';
import secRegisterApi from 'api/v2/sec/secRegisterApi';
import secReportApi from 'api/v2/sec/secReportApi';
import { BaseButton } from 'components/Button/BaseButton';
import TimelineComponent, { TimelineItem } from 'components/Common/TimeLine';
import useModalHook from 'components/ModalHook';
import ImageWithDefault from 'components/bootstrap/ImageWithDefault';
import DataListView from 'components/shared/DataListView';
import { Card, Col, Row } from 'components/ui-kit';
import { QRType } from 'enum/qrType';
import { SECCheckInOutType, SECRegisterType } from 'enum/sec';
import { decodeBase64, formatDateTime, isMobileDevice } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight, QrCodeScan } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import Routers from 'router/const/routers';
import CheckInOutModalModal from './checkInOrOut.component';
import ScanQRComponent from './scanQR.component';

const SECRegisterDetailPage = ({ registerId }: { registerId?: string | null }) => {
    const [register, setRegister] = useState<SECRegisterDetailResponse | null>(null);
    const [histories, setHistories] = useState<TimelineItem[]>([]); // [{ title: string; date: Date; description: string }
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    useEffect(() => {
        loadRegister();
    }, []);

    const loadRegister = () => {
        if (registerId)
            secRegisterApi
                .getById(registerId)
                .then((res) => {
                    setRegister(res);
                    setHistories(
                        res.reports.map((n) => {
                            var item: TimelineItem = {
                                title: n.type,
                                createdFulName: n.createdUserName,
                                date: n.timeOn,
                                description: (
                                    <div>
                                        <p>{n.gateName}</p>
                                        {n.reason}
                                        <div style={{ maxWidth: 300 }}>{n.imagePath && <ImageWithDefault addLink={true} className="img-thumbnail" src={n.imagePath} />}</div>
                                    </div>
                                ),
                            };
                            return item;
                        })
                    );
                })
                .catch((n) => {});
    };
    const isValidQR = (qr: QRBaseResponse) => {
        if (qr && qr.code && (qr.qrType == QRType.EQUIPMENT || qr.qrType == QRType.USER)) {
            if (qr.qrType == QRType.EQUIPMENT && register?.registerType == SECRegisterType.EQUIPMENT) return true;
            if (qr.qrType == QRType.USER && register?.registerType == SECRegisterType.PERSON) return true;
        }
    };

    const onScanQRCheckInOut = async (type: SECCheckInOutType) => {
        try {
            if (!isMobileDevice()) {
                toast.error('Chức năng chỉ hỗ trợ trên thiết bị di động');
                return;
            }

            if (register == null) return;
            var base64 = await openModal({
                title: type,
                body: <ScanQRComponent onClose={onClose} onCompleted={onCompleted} />,
            });
            if (base64) {
                let json = decodeBase64(base64);

                var data: QRBaseResponse = JSON.parse(json);
                if (isValidQR(data)) {
                    if (data.code == register.objectCode) {
                        toast.success(type);
                        checkInOutCheckOut(type);
                    } else {
                        toast.error('QR code khác với đối tượng hiện tại' + data.code + ' - ' + register.objectCode);
                    }
                } else toast.error('QR code không hợp lệ');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkInOutCheckOut = async (type: SECCheckInOutType) => {
        if (register == null) return;
        var rs = await openModal({
            title: type,
            body: <CheckInOutModalModal organizationId={register.organizationId} onClose={onClose} onCompleted={onCompleted} />,
        });
        if (rs) {
            let payload: SECReportRequest = {
                registerId: register.id,
                gateId: rs.gateId,
                reason: rs.reason,
                imagePath: rs.imagePath,
                type: type,
                timeOn: new Date(),
            };
            secReportApi
                .create(payload)
                .then((res) => {
                    loadRegister();
                })
                .catch((n) => {});
        }
    };

    return (
        <Row>
            <Col md={4}>
                <Card header={lang.text(Translations.common.generalInfo)}>
                    <Card.Body>
                        {register && (
                            <div>
                                <div className="flex justify-end me-1 ">
                                    <BaseButton
                                        size="sm"
                                        color="success"
                                        onClick={() => {
                                            onScanQRCheckInOut(SECCheckInOutType.CHECK_IN);
                                        }}
                                        className="me-1"
                                    >
                                        <QrCodeScan /> {lang.text(Translations.sec.scanQRCode)}
                                    </BaseButton>
                                    <BaseButton
                                        size="sm"
                                        color="warning"
                                        onClick={() => {
                                            onScanQRCheckInOut(SECCheckInOutType.CHECK_OUT);
                                        }}
                                        className="me-1"
                                    >
                                        <QrCodeScan /> {lang.text(Translations.sec.scanQRCode)}
                                    </BaseButton>
                                    <BaseButton size="sm" onClick={() => checkInOutCheckOut(SECCheckInOutType.CHECK_IN)} className="me-1">
                                        <ArrowRight /> {lang.text(Translations.sec.checkIn)}
                                    </BaseButton>
                                    <BaseButton size="sm" color="warning" onClick={() => checkInOutCheckOut(SECCheckInOutType.CHECK_OUT)} className="me-1">
                                        <ArrowLeft /> {lang.text(Translations.sec.checkOut)}
                                    </BaseButton>
                                </div>
                                <DataListView
                                    datas={[
                                        {
                                            label: lang.text(Translations.common.name),
                                            value: register.name,
                                        },
                                        {
                                            label: lang.text(register.registerType == SECRegisterType.PERSON ? Translations.sec.dkshNumber : Translations.equipment.code),
                                            value:
                                                SECRegisterType.PERSON == register.registerType ? (
                                                    register.objectCode
                                                ) : (
                                                    <a target="_blank" href={Routers.equipment.detail.replace(':id', register.objectId)}>
                                                        {register.objectCode}
                                                    </a>
                                                ),
                                        },
                                        {
                                            label: lang.text(Translations.form.area.name),
                                            value: register.organizationName,
                                        },
                                        {
                                            label: lang.text(Translations.contractor),
                                            value: register.contractorName,
                                        },
                                        {
                                            label: lang.text(Translations.common.fromDate),
                                            value: formatDateTime(register.from),
                                        },
                                        {
                                            label: lang.text(Translations.common.toDate),
                                            value: formatDateTime(register.to),
                                        },
                                    ]}
                                />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <TimelineComponent title={lang.text(Translations.common.history)} items={histories} />
            </Col>
            {Prompt}
        </Row>
    );
};

export default SECRegisterDetailPage;
