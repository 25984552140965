import { selectLanguage, setLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { SelectOption } from 'components/FormControl';
import StorageKeys from 'constants/storageKeys';
import Translations from 'i18n/translation';
import i18n from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Dropdown } from 'components/ui-kit';
interface SelectLanguageProps {
    isRounded?: boolean;
    className?: string;
}

export default function SelectLanguage(props: SelectLanguageProps) {
    const { className = '' } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectedLanguage: string = useAppSelector(selectLanguage) as string;

    const languageOptions: SelectOption[] = [
        {
            label: t(Translations.common.language.vi),
            value: 'vi',
        },
        {
            label: t(Translations.common.language.en),
            value: 'en',
        },
    ];

    const handleChange = (value: string) => {
        i18n.changeLanguage(value);
        localStorage.setItem(StorageKeys.language, value);
        dispatch(setLanguage(value));
    };

    useEffect(() => {
        if (selectedLanguage) i18n.changeLanguage(selectedLanguage);
    }, [selectedLanguage]);

    const options = languageOptions.filter((i) => i.value !== selectedLanguage);

    if (props.isRounded) {
        return <div>rounded</div>;
    }

    return (
        <div className="flex items-center">
            <span className="me-2">{t(Translations.common.language.name)}</span>
            {/* <Dropdown.Toggle as={CustomToggle} variant="text-secondary">
              <Button variant="outline-primary" className="border-0">
                  {t((Translations.common.language as any)[selectedLanguage])}
              </Button>
          </Dropdown.Toggle>

          <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
              {options.map((item) => (
                  <li key={item.value} onClick={() => handleChange(item.value.toString())}>
                      <Dropdown.Item>{t((Translations.common.language as any)[item.value])}</Dropdown.Item>
                  </li>
              ))}
          </Dropdown.Menu> */}
            <Dropdown
                value={selectedLanguage}
                onChange={(e: DropdownChangeEvent) => handleChange(e.value)}
                options={options.map((option) => ({
                    label: option.label,
                    value: option.value,
                }))}
                placeholder={t((Translations.common.language as any)[selectedLanguage])}
            />
        </div>
    );
}
