import { Fragment, useState } from 'react';

import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';

import { yupResolver } from '@hookform/resolvers/yup';
import { SKPIObjectiveRequest, SKPIRequest } from 'api/v2/skpi/model/skpiRequest.model';
import skpiReportApi from 'api/v2/skpi/skpiApi';
import { SaveButton } from 'components/Button';
import { CompanySelect, ContractorSelect } from 'components/Common';
import UserSelectField from 'components/Common/UserSelect';
import DateTimeField from 'components/FormControl/DateTimeField';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import DataListView from 'components/shared/DataListView';
import { Accordion, Col, Row } from 'components/ui-kit';
import { PPRTaskType } from 'enum/ppr';
import { formatDate } from 'helper';
import { lang } from 'i18n/lang';
import { AccordionTab } from 'primereact/accordion';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import SkpiActionComponent from './actionViewList.component.';
import SKPIAddObjectiveComponent from './skpiAddObjective.component';
import { SKPIObjectTypeSelect } from './skpiTaskTypeSelect';

const defaultFromDate = new Date();
const SKPICreatePage = () => {
    const [companyId, setCompanyId] = useState<string>('');

    const [objectives, setObjectives] = useState<SKPIObjectiveRequest[]>([]);
    const [updatingObjectives, setUpdatingObjectives] = useState<SKPIObjectiveRequest>();

    const schema = yup.object().shape({
        areaId: yup.string().notRequired().nullable(),
        companyId: yup.string().required(),
        contractorId: yup.string().required(),
        pICId: yup.string().required(),
        targetObjectId: yup.string().required(),
        targetObjectName: yup.string().required(),
        fromDate: yup.date().required(),
        toDate: yup.date().required(),
        taskType: yup
            .mixed<PPRTaskType>() // Cast the type to your enum
            .oneOf(Object.values(PPRTaskType)) // Ensure the value is one of the enum options
            .required(),
    });
    const {
        control,
        getValues,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const onSubmidAddObjective = (obj: SKPIObjectiveRequest) => {
        setObjectives([...objectives, obj]);
    };

    // Save change SKPI report
    const SaveChange = () => {
        if (!objectives.hasValue()) {
            toast.error('Nhập ít nhất 1 chỉ tiêu');
            return;
        }
        // if (confirm('Bạn có chắc chắn muốn tạo báo cáo?') === false) return;
        var form = getValues();
        var payload: SKPIRequest = {
            ...form,
            companyIds: [form.companyId],
            items: objectives,
            type: form.taskType,
        };
        skpiReportApi
            .create(payload)
            .then((res) => {})
            .catch(() => {});
    };

    const onDeleteObjectives = (action: SKPIObjectiveRequest) => {
        setObjectives(objectives.filter((n) => n.uiId !== action.uiId));
        return Promise.resolve();
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(SaveChange)}>
                <Card>
                    <Card.Header className="flex justify-between">
                        <h4 className="p-card-title  ">
                            <span>Tạo báo cáo</span>
                        </h4>
                        <SaveButton />
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <CompanySelect md={4} xs={12} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <ContractorSelect md={4} xs={12} name="contractorId" control={control} readOnly={false} />

                            <DateTimeField md={4} xs={12} label={lang.text(Translations.common.fromDate)} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                            <DateTimeField md={4} xs={12} label={lang.text(Translations.common.toDate)} control={control} readOnly={false} name={'toDate'} defaultDate={defaultFromDate} />
                            <UserSelectField md={4} xs={12} readOnly={false} label={lang.text(Translations.common.pic)} name="pICId" control={control} required={true} />
                            <SKPIObjectTypeSelect companyId={companyId} md={4} xs={12} control={control} onTargetObjectNameChanged={(name: string) => {}} />
                        </Row>
                    </Card.Body>
                </Card>
            </form>

            <Row>
                <Col md={6}>
                    <Card className="border border-gray">
                        <Card.Header className="flex justify-between">
                            <h5 className="p-card-title">
                                {lang.text(Translations.common.addNew)} {lang.text(Translations.wpr.skpi.objectives).toLocaleLowerCase()}
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            <SKPIAddObjectiveComponent onSubmidAddObjective={onSubmidAddObjective} currentTaskUpdate={updatingObjectives} companyId={companyId} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header className="flex justify-between">
                            <h5 className="p-card-title">{lang.text(Translations.wpr.skpi.objectives)}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row cols={1}>
                                <Accordion>
                                    {objectives.map((item, index) => (
                                        <AccordionTab
                                            key={index}
                                            header={
                                                <div className="flex w-full justify-between px-2">
                                                    <span>
                                                        # {index + 1} {item.name}
                                                    </span>
                                                    <ActionButtons2
                                                        id={item.id ?? ''}
                                                        onDelete={(id: string) => {
                                                            onDeleteObjectives(item);
                                                            return Promise.resolve();
                                                        }}
                                                        editAction={() => {
                                                            setObjectives(objectives.filter((n) => n.uiId !== item.uiId));
                                                            setUpdatingObjectives({ ...item });
                                                        }}
                                                    />
                                                </div>
                                            }
                                        >
                                            <DataListView
                                                datas={[
                                                    { label: lang.text(Translations.common.fromDate), value: formatDate(item.fromDate) },
                                                    { label: lang.text(Translations.common.toDate), value: formatDate(item.toDate) },
                                                    { label: lang.text(Translations.common.pic), value: item.pICFullName },
                                                    { label: lang.text(Translations.wpr.ppr.object), value: item.targetObjectName },
                                                    { label: lang.text(Translations.wpr.ppr.type), value: item.taskType },
                                                ]}
                                            />
                                            <SkpiActionComponent actions={item.actions} />
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SKPICreatePage;
