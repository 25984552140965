import { yupResolver } from '@hookform/resolvers/yup';
import { SORCategoryRequest } from 'api/v2/sor/model/sorCategory.model';
import { InputField, TextAreaField } from 'components/FormControl';
import ModalFooterButtons from 'components/Modal/ModelFooterButtons';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface SORCategoryFormProps extends SORCategoryRequest, ModalHookComponentProps {}
const SORCategoryForm = ({ id, name, description, onClose, onCompleted }: SORCategoryFormProps) => {
    const { control, handleSubmit, getValues } = useForm<{
        name: string;
        description: string;
    }>({
        resolver: yupResolver(
            yup.object().shape({
                name: yup.string().required(),
                description: yup.string().required(),
            })
        ),
    });

    const onSubmit = async () => {
        var form = getValues();
        let data: SORCategoryRequest = {
            ...form,
            id: id,
        };
        onCompleted(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputField name={'name'} control={control} defaultValue={name} label={lang.text(Translations.common.name)} required={true} />
            <TextAreaField name={'description'} control={control} defaultValue={description} label={lang.text(Translations.common.description)} required={true} />
            <ModalFooterButtons onClose={onClose} okButtonType="submit" />
        </form>
    );
};
export default SORCategoryForm;
