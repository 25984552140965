import { yupResolver } from '@hookform/resolvers/yup';
import { PermissionGroupDetailResponse, RoleCreateRequest } from 'api/v2/role/model/role.model';
import roleApi from 'api/v2/role/roleApi';
import { InputField } from 'components/FormControl';
import ModalFooterButtons from 'components/Modal/ModelFooterButtons';
import { ModalHookComponentProps } from 'components/ModalHook';
import { Card } from 'components/shared';
import { Checkbox, Col, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface RoleCreateComponentProps extends ModalHookComponentProps {
    permissionIds: string[]; // Permission selected in role
    name: string;
}
export default function RoleCreateComponent({ name, permissionIds, onCompleted, onClose }: RoleCreateComponentProps) {
    const schema = yup.object().shape({
        name: yup.string().required(),
    });
    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDetailResponse[]>([]);

    useEffect(() => {
        roleApi
            .getAllPermissions()
            .then((groups) => {
                if (permissionIds && groups) {
                    groups.forEach((g) => {
                        g.permissions &&
                            g.permissions.forEach((p) => {
                                if (permissionIds.includes(p.id)) {
                                    p.isInRole = true;
                                }
                            });
                    });
                }
                setPermissionGroups(groups);
            })
            .catch((n) => {});
    }, []);
    const onTogglePms = (groupId: string, pmsId: string, value: boolean) => {
        let group = permissionGroups.find((n) => n.id == groupId);
        let pms = group && group.permissions.find((n) => n.id == pmsId);
        if (pms) pms.isInRole = value;
        setPermissionGroups([...permissionGroups]);
    };
    const onHandleSubmit = () => {
        const form = getValues();
        let pmsIds: string[] = [];
        permissionGroups.forEach((g) => {
            g.permissions
                .filter((p) => p.isInRole === true)
                .forEach((x) => {
                    pmsIds.push(x.id);
                });
        });
        let payload: RoleCreateRequest = {
            name: form.name,
            permissionIds: pmsIds,
        };
        onCompleted(payload);
    };
    return (
        <form onSubmit={handleSubmit(onHandleSubmit)}>
            <Row>
                <InputField defaultValue={name} label={lang.text(Translations.common.name)} name={'name'} control={control} required={true} md={12} type="string" />
            </Row>
            <Row>
                <Col md={12}>
                    <h5>{lang.text(Translations.role.role)}</h5>
                </Col>
                {permissionGroups &&
                    permissionGroups.map((g) => (
                        <Col md={4}>
                            <Card>
                                <Card.Header className="bg-soft-success">{g.groupName}</Card.Header>
                                <Card.Body className="bg-soft-secondary">
                                    {g.permissions &&
                                        g.permissions.map((p, i) => (
                                            <div className="flex align-items-center">
                                                <Checkbox
                                                    onChange={(e) => {
                                                        onTogglePms(g.id, p.id, e.checked ?? false);
                                                    }}
                                                    checked={p.isInRole}
                                                ></Checkbox>
                                                <label htmlFor={p.id} className="ml-2">
                                                    {p.displayName}
                                                </label>
                                            </div>
                                        ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
            </Row>

            <ModalFooterButtons onClose={onClose} okButtonType="submit" />
        </form>
    );
}
