import Card from 'components/ui-kit/primereact/Card';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { TripFilter } from 'api/v2/trip/model/trip.model';
import tripApi from 'api/v2/trip/tripApi';
import { AddButton } from 'components/Button';
import { FilterButton } from 'components/Button/FilterButton';
import { CompanySelect } from 'components/Common';
import EquipmentSelectField from 'components/Common/EquipmentSelect';
import UserSelectField from 'components/Common/UserSelect';
import { SelectField } from 'components/FormControl';
import useModalHook from 'components/ModalHook';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row, TableColumn } from 'components/ui-kit';
import { getTripStatus } from 'constants/trip';
import { useQuery } from 'hooks/useQuery';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Routers from 'router/const/routers';

// eslint-disable-next-line react-hooks/exhaustive-deps
const TripPage = () => {
    const { query, setQuery } = useQuery();

    const cols: TableColumn[] = useMemo(
        () => [
            {
                field: 'fromPlaceName',
                header: lang.text(Translations.trip.from),
            },
            {
                field: 'toPlaceName',
                header: lang.text(Translations.trip.to),
            },
            {
                field: 'distance',
                header: lang.text(Translations.trip.distance),
            },
            {
                field: 'status',
                header: lang.text(Translations.common.status),
            },
            {
                field: 'reason',
                header: 'Lý do',
            },
            {
                field: 'createdUserName',
                header: lang.text(Translations.common.createdBy),
            },
            {
                field: 'createdTime',
                header: lang.text(Translations.common.createdTime),
            },
            {
                field: 'action',
                header: '',
            },
        ],
        []
    );
    const statuses = useMemo(getTripStatus, []);
    const { Prompt } = useModalHook();
    var navigate = useNavigate();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const { control, handleSubmit, getValues, reset, formState, setValue } = useForm();

    useEffect(() => {
        handleFetchData(query as PaginationBaseRequest);
    }, [query]);

    const handleFetchData = async (paginate: PaginationBaseRequest) => {
        var filter: TripFilter = {
            ...paginate,
            ...query,
        };
        tripApi
            //@ts-ignore
            .getByIndex(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((data) => {
                            return {
                                ...data,
                                fromPlaceName: data.fromPlace.displayName,
                                toPlaceName: data.toPlace.displayName,
                                createdTime: moment(data?.createdTime).format('DD/MM/yyyy HH:mm'),
                                action: (
                                    <ActionButtons2
                                        id={data?.id!}
                                        editAction={() => window.open(`${Routers.trip.trip}/${data?.id}`, '_blank')}
                                        viewPms={true}
                                        onViewDetail={() => window.open(`${Routers.trip.trip}/${data?.id}`, '_blank')}
                                    />
                                ),
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <>
            <Row cols={1}>
                <Card>
                    <Card.Body>
                        <form
                            className="w-full"
                            onSubmit={handleSubmit((data) => {
                                setQuery({
                                    ...data,
                                    currentPage: 1,
                                });
                            })}
                        >
                            <Row>
                                <CompanySelect defaultValue={query?.companyId} required={false} name="companyId" control={control} disable={false} readOnly={false} />
                                <EquipmentSelectField md={4} xs={12} defaultValue={query?.equipmentId} name="equipmentId" control={control} disable={false} readOnly={false} />
                                <SelectField md={4} xs={12} label={lang.text(Translations.common.status)} options={statuses} name={'status'} control={control} />

                                <UserSelectField
                                    defaultValue={query?.driverId}
                                    readOnly={false}
                                    label={lang.text(Translations.equipment.operator)}
                                    name="driverId"
                                    control={control}
                                    isMutiple={false}
                                    md={4}
                                    xs={12}
                                />
                                <UserSelectField
                                    defaultValue={query?.passengerId}
                                    readOnly={false}
                                    label={lang.text(Translations.trip.passengers)}
                                    name="passengerId"
                                    control={control}
                                    isMutiple={false}
                                    md={4}
                                    xs={12}
                                />
                                <Col md={12} className="flex justify-end align-items-end gap-2">
                                    <AddButton isRender={true} onClick={() => navigate(`${Routers.trip.trip}/create`)} />
                                    <FilterButton type="submit" />
                                </Col>
                            </Row>
                        </form>
                    </Card.Body>

                    {Prompt}
                </Card>
            </Row>
            <Row cols={1}>
                <PaginationTable
                    onSearch={(pagi) => {
                        defaultPaginationRequest.keySearch = pagi.keySearch;
                    }}
                    onPaginate={(p) => handleFetchData(p)}
                    columns={cols}
                    pagination={paginationData}
                    isStriped={true}
                    disableSearch={false}
                    wrapperClass="mt-5"
                />
            </Row>
        </>
    );
};

export default TripPage;
