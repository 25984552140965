//react-bootstrap

import { Col, Row } from 'components/ui-kit/index';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import { RiskLevelResponse, RiskLevelUpdateRequest } from 'api/models/organization';
import riskLevelApi from 'api/v2/common/riskLevel/riskLevelApi';
import { SaveButton } from 'components/Button';
import { InputField, TextAreaField } from 'components/FormControl';
import Card from 'components/ui-kit/primereact/Card';
import Translations from 'i18n/translation';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const ConfigRiskLevelUpdatePage = memo(() => {
    const { t } = useTranslation();
    const params = useParams();

    const schema = yup.object().shape({
        fieldNameVn: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        note: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        setValue,
    } = useForm<{ fieldNameVn: string; note?: string }>({
        defaultValues: {
            fieldNameVn: '',
            note: '',
        },
        resolver: yupResolver(schema),
    });

    const [openConfirm, setOpenConfirm] = useState(false);
    const [data, setData] = useState<RiskLevelUpdateRequest>({
        fieldNameVn: '',
        note: '',
        id: '',
    });

    useEffect(() => {
        params.id &&
            riskLevelApi
                .getById(params.id)
                .then((res: RiskLevelResponse) => {
                    setValue('fieldNameVn', res.fieldNameVn);
                    setValue('note', res.note);
                    setData(res);
                })
                .catch((n) => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = () => {
        const values = getValues();

        const _updateData: RiskLevelUpdateRequest = {
            id: params.id ?? '',
            fieldNameVn: values.fieldNameVn,
            note: values.note,
        };

        riskLevelApi
            .update(_updateData)
            .then(() => {})
            .catch((n) => {});
    };

    return (
        <Card>
            <Card.Header>
                <div className="text-end w-full flex justify-between items-center"></div>
            </Card.Header>
            <Card.Body>
                <form className=" justify-center" onSubmit={handleSubmit(onUpdate)}>
                    <Row>
                        <InputField md={12} xs={12} name="fieldNameVn" control={control} label={t(Translations.form.name)} placeholder="" />
                        <TextAreaField md={12} xs={12} name="note" control={control} label={t(Translations.form.content)} placeholder="" />
                        <Col md={12} sm={12}>
                            <div className="flex justify-end">
                                <SaveButton type="submit" />
                            </div>
                        </Col>
                    </Row>
                </form>
            </Card.Body>
        </Card>
    );
});

export default ConfigRiskLevelUpdatePage;
