import { IIRUserModel } from 'api/v2/iir/model/iirReportComponent.model';
import { Card, Image } from 'components/ui-kit';
import { avataUserDefault } from 'constants/common';

const IIRUserComponent = (props: { title: string; users: IIRUserModel[] }) => {
    return (
        <Card>
            <Card.Header>
                <div className="flex items-center">
                    <h6 className="mr-1 mb-0 text-primary">{props.title}</h6>
                    <div>
                        <span className="btn btn-sm btn-icon btn-soft-primary text-primary rounded-pill">{props.users.length}</span>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                {props.users.map((n, i) => (
                    <div className="flex  gap-3">
                        <Image src={avataUserDefault} alt="01" className="img-fluid rounded-circle avatar-50" />
                        <div>
                            <div className="flex items-center gap-2">
                                <h5 className="mb-0">{n.fullName}</h5>
                            </div>
                            <div className="flex items-center mt-2 gap-2">
                                <span className="text-body small">{n.iIRUserStatus}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </Card.Body>
        </Card>
    );
};

export default IIRUserComponent;
