import { yupResolver } from '@hookform/resolvers/yup';
import { PPRReportCloseRequest } from 'api/v2/ppr/model/ppr.model';
import pprLevelApi from 'api/v2/ppr/pprLevelApi';
import { SelectField, SelectOption, TextAreaField } from 'components/FormControl';
import ModalFooterButtons from 'components/Modal/ModelFooterButtons';
import { ModalHookComponentProps } from 'components/ModalHook';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
interface EquipmentReportRatingComponentProps extends ModalHookComponentProps {
    id: string;
    companyId: string;
}
/**
 *
 * @param param0 returrn object PPRReportCloseRequest
 * @returns
 */
const PPRCloseComponent = ({ companyId, id, onClose, onCompleted }: EquipmentReportRatingComponentProps) => {
    const [levels, setLevels] = useState<SelectOption[]>([]);

    useEffect(() => {
        pprLevelApi
            .getPagination({ companyId: companyId, rowsPerPage: 100, currentPage: 1 })
            .then((n) => {
                setLevels(n.datas?.toSelectOptions('levelName', 'id') ?? []);
            })
            .catch((n) => {});
    }, []);
    const schema = yup.object().shape({
        description: yup.string().min(1).max(1000),
        pprLevelId: yup.string().required(),
    });
    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {
            description: '',
            pprLevelId: '',
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async () => {
        var form = getValues();
        var rs: PPRReportCloseRequest = {
            description: form.description ?? '',
            pPRLevelId: form.pprLevelId,
            reportId: id,
        };
        onCompleted(rs);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SelectField md={12} label={lang.text(Translations.equipment.reportRating)} options={levels} name="pprLevelId" control={control} required={true} />
            <TextAreaField md={12} label={lang.text(Translations.common.description)} name="description" control={control} required={true} />
            <ModalFooterButtons onClose={onClose} okButtonType="submit" />
        </form>
    );
};

export default PPRCloseComponent;
