import { Modal } from 'components/ui-kit';
import { MODAL_BOX_CLASSNAME } from 'constants/common';
import { getCurrentLayout, LAYOUT_TYPE } from 'hooks/useLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ModalHookProps {
    show: boolean;
    title: string;
    body: React.ReactNode;
    modalSize: 'sm' | 'lg' | 'xl';
    isFullScreen: true | string;
    onClose: () => void;
    onConfirm: () => void;
}
export interface ModalHookComponentProps {
    onClose: () => void;
    onCompleted: (value: any) => void;
}
const ModalHook: React.FC<ModalHookProps> = ({ show, onClose, onConfirm, modalSize = 'lg', title, body, isFullScreen }) => {
    const layout = getCurrentLayout();
    const getSize = () => {
        if (isFullScreen) {
            return { width: '100%', height: '100%' };
        }
        switch (modalSize) {
            case 'sm':
                return { width: '400px' };
            case 'lg':
                return { width: '800px' };
            case 'xl':
                return { width: '1200px' };
            default:
                return { width: '800px' };
        }
    };
    return (
        <div>
            <Modal
                header={title}
                className={layout === LAYOUT_TYPE.BOX ? MODAL_BOX_CLASSNAME : ''}
                visible={show}
                onHide={onClose}
                maximizable={true}
                blockScroll={true}
                style={getSize()}
                draggable={false}
            >
                {body}
            </Modal>
        </div>
    );
};

interface OpenModalHookProps {
    title: string;
    body: React.ReactNode;
    modalSize?: 'sm' | 'lg' | 'xl';
    isFullScreen?: true | string;
    /**Reset route affter when close modal */
    isResetRoute?: boolean;
}
const useModalHook = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    var resolve: any = undefined;
    const [modalSize, setModalSize] = useState<'sm' | 'lg' | 'xl'>('lg');
    const [title, setTitle] = useState<string>('');
    const [fullScreen, setfullScreen] = useState<true | string>('');
    const [body, setBody] = useState<React.ReactNode>();
    const [isResetRoute, setIsResetRoute] = useState<boolean>(false);
    const openModal = ({ title, body, modalSize, isFullScreen, isResetRoute }: OpenModalHookProps) => {
        setShow(true);
        setModalSize(modalSize ?? 'xl');
        setfullScreen(isFullScreen ?? '');
        setTitle(title);
        setBody(body);
        setIsResetRoute(isResetRoute ?? false);
        return new Promise<any>((resol, reject) => {
            resolve = resol;
        });
    };
    /**
     * Export pass to Body Component,
     * Because button save and close manage on body
     * when close resolved result with null
     */
    const onClose = () => {
        handleClose();
    };

    /**
     * Export pass to Body Component,
     * Because button save and close manage on body
     * when completed resolved result with value cusstomr,
     * Outsite component check that value, if is null, it is close envet, if othe it is success confirm event
     * base on that result action handle feeth data
     */
    const onCompleted = (val: any) => {
        console.log("AAAAA")
        setShow(false);
        resolve && resolve(val);
    };
    // const onSubmit = () => setShow(false);
    const handleClose = () => {
        setShow(false);
        if (isResetRoute) {
            navigate('', { replace: true });
        }
        resolve && resolve(false);
    };

    /**Do not use because button close and confirm manage on body component */
    const handleConfirm = () => {
        console.log('handleConfirm');
        resolve && resolve(false);
    };

    return {
        onCompleted,
        onClose,
        openModal,
        Prompt: <ModalHook modalSize={modalSize} body={body} title={title} show={show} onClose={handleClose} onConfirm={handleConfirm} isFullScreen={fullScreen} />,
    };
};

export default useModalHook;
