import { BaseButton } from 'components/Button/BaseButton';
import { Modal } from 'components/ui-kit';
import Color from 'enum/color';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';

export default function ModalFooterButtons(props: { onClose: () => void; onConfirm?: () => void; okButtonType?: 'submit' | 'button' }) {
    return (
        <Modal.Footer>
            <BaseButton isRender={true} color={Color.danger} onClick={props.onClose} text={lang.text(Translations.modal.confirm.cancel)} />
            <BaseButton
                isRender={true}
                color={Color.info}
                type={props.okButtonType ?? 'button'}
                onClick={() => {
                    props.onConfirm && props.okButtonType !== 'submit' && props.onConfirm();
                }}
                text={lang.text(Translations.modal.confirm.ok)}
            />
        </Modal.Footer>
    );
}
