import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { ResponsiveTable } from 'components/Table/ResponsiveTable';
import { Col, Dropdown, IconField, InputIcon, InputText, Paginator, Row, TableColumn } from 'components/ui-kit';
import { isMobileDevice } from 'helper';
import { debounce } from 'helper/debounce';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../ui-kit/primereact/Card';
import Empty from './Empty';

export type ColumnType = {
    field: string;
    title: string;
    className?: string;
    style?: CSSProperties | undefined;
};

const fancyTableDefaultPerPage = [10, 20, 30, 40, 50];

interface PaginationTableProps {
    disableSearch?: boolean;
    columns: TableColumn[];
    pagination: PaginationBase<any> | undefined;
    isStriped?: boolean;
    wrapperClass?: string;
    onSearch?: (paginate: PaginationBaseRequest) => void;
    onPaginate?: (paginate: PaginationBaseRequest) => void;
    onRowClick?: (row: any) => void;
    searchPlaceholder?: string;
    loading?: boolean;
    hideHead?: boolean;
    action?: () => React.ReactNode;
}
export function PaginationTable({
    disableSearch,
    columns,
    pagination,
    isStriped = false,
    onSearch,
    onPaginate,
    onRowClick,
    hideHead = false,
    searchPlaceholder = lang.text(Translations.table.find),
}: // action,
PaginationTableProps) {
    const defaultRequest = defaultPaginationRequest;
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRequest.rowsPerPage);
    const [columnStates, setColumnStates] = useState<TableColumn[]>(columns);
    const [currentPage, setCurrentPage] = useState<number>(defaultRequest.currentPage);
    const [keySearch, setKeySearch] = useState<string>('');

    useEffect(() => {
        var lastColumn = columns[columns.length - 1];
        if (isMobileDevice() && lastColumn.field === 'action') {
            var newCols: TableColumn[] = [lastColumn, ...columns.slice(0, columns.length - 1)];
            console.log(newCols);
            setColumnStates(newCols);
        }
    }, [columns]);

    const handlePaginate = (page: PaginationBaseRequest) => {
        if (pagination?.currentPage === page.currentPage && pagination.rowsPerPage === page.rowsPerPage) return;
        setCurrentPage(page.currentPage);
        setRowsPerPage(page.rowsPerPage);

        if (pagination && onPaginate) {
            onPaginate({
                currentPage: page.currentPage,
                rowsPerPage: page.rowsPerPage,
                keySearch: keySearch,
            });
        }
    };
    useEffect(() => {
        setRowsPerPage(pagination?.rowsPerPage ?? defaultRequest.rowsPerPage);
        setCurrentPage(pagination?.currentPage ?? defaultRequest.currentPage);
    }, [pagination]);

    const handleSearchChange = (value: string) => {
        setKeySearch(value);
        onSearch &&
            onSearch({
                currentPage: currentPage,
                rowsPerPage: rowsPerPage,
                keySearch: value,
            });
    };
    const handleSearch = useCallback(debounce(handleSearchChange, 1000), []);

    const renderMainContent = () => (
        <section>
            {!hideHead && (
                <div className="flex-column gap-2 flex-md-row justify-between row mb-4 align-items-end mt-4 grid grid-cols-12">
                    {!disableSearch && (
                        <Col md={4}>
                            <IconField iconPosition="right">
                                <InputIcon className="pi pi-search"> </InputIcon>
                                <InputText className="w-full" placeholder={searchPlaceholder} onChange={(e) => handleSearch(e.target.value)} id="search" type="text" />
                            </IconField>
                        </Col>
                    )}
                    {disableSearch && <Col md={4}></Col>}
                    {pagination && (
                        <Col md={4} className="flex items-center gap-2 justify-end">
                            <span>{t(Translations.table.display)}</span>
                            <Dropdown
                                value={rowsPerPage}
                                onChange={(e) => {
                                    if (rowsPerPage === Number(e.value)) return;
                                    handlePaginate({
                                        rowsPerPage: Number(e.value),
                                        currentPage: 1,
                                    });
                                }}
                                options={fancyTableDefaultPerPage.map((opt, index) => {
                                    return { name: opt, value: opt };
                                })}
                                optionLabel="name"
                                placeholder=""
                            />
                        </Col>
                    )}
                </div>
            )}

            {/* Table body */}
            <ResponsiveTable
                isWrapByCard={false}
                emptyLayout={<Empty />}
                pagination={pagination}
                columns={columnStates}
                datas={pagination?.datas ?? []}
                isStriped={isStriped}
                onRowClick={onRowClick}
            />

            <Row className="items-center">
                <Col md={6}>
                    {pagination && pagination.totalItems ? (
                        <div className="dataTables_info">
                            Showing 1 to {pagination.totalItems > pagination.rowsPerPage ? pagination.rowsPerPage : pagination.totalItems} of {pagination.totalItems} entries
                        </div>
                    ) : (
                        ''
                    )}
                </Col>
                <Col md={6}>
                    <div className="flex items-center justify-end pt-3">
                        {pagination && (
                            <Paginator
                                first={currentPage - 1}
                                onPageChange={(event) => {
                                    if (currentPage === event.first + 1) return;
                                    handlePaginate({
                                        currentPage: event.first + 1,
                                        rowsPerPage: pagination.rowsPerPage,
                                    });
                                }}
                                rows={pagination.rowsPerPage}
                                totalRecords={pagination.totalPage}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </section>
    );
    return isMobileDevice() ? (
        renderMainContent()
    ) : (
        <Card>
            <Card.Body>{renderMainContent()}</Card.Body>
        </Card>
    );
}
