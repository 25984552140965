import { Col } from 'components/ui-kit';
import React, { memo, ReactNode } from 'react';
import Card from '../ui-kit/primereact/Card';

// Components

interface AppointmentsCardProps {
    color: any;
    svg: ReactNode;
    counter: number;
    content: string;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const AppointmentsCard: React.FC<AppointmentsCardProps> = memo((props) => {
    return (
        <Col md={props.md ?? 4}>
            <Card>
                <Card.Body>
                    <div className="flex items-center gap-3">
                        <div className={`bg-soft-${props.color} p-2 rounded`}>{props.svg}</div>
                        <div>
                            <h4 className="counter">{props.counter}</h4>
                            <span className="text-dark">{props.content}</span>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
});

AppointmentsCard.displayName = 'AppointmentsCard';
export default AppointmentsCard;
