import { Col, Row } from 'components/ui-kit';
import { memo } from 'react';
import SIReportDashboard from 'views/safety-inspections/reports/dashboard';
import SORDashboard from 'views/sor/report/dashboard';

const DashboardPage = memo(() => {
    return (
        <Row>
            <Col>
                <SORDashboard />
            </Col>
            <Col>
                <SIReportDashboard />
            </Col>
        </Row>
    );
});

export default DashboardPage;
