import { Logo as MainLogo } from 'assets/images';
import { Avatar } from 'primereact/avatar';
import { Fragment, memo } from 'react';

const Logo = memo(() => {
    return (
        <Fragment>
            <div>
                <Avatar image={MainLogo} size="xlarge" shape="circle" />
            </div>
        </Fragment>
    );
});

Logo.displayName = 'Logo';
export default Logo;
