import equipmentApi from 'api/v2/equipmentApi';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/ui-kit/primereact/Card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { EquipmentReportFilterRequest, EquipmentReportResponse } from 'api/models';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import equipmentReportApi from 'api/v2/equipmentReportApi';
import { FilterButton } from 'components/Button/FilterButton';
import { CompanySelect, ContractorSelect } from 'components/Common';
import { SelectField, SelectOption } from 'components/FormControl';
import DateTimeField from 'components/FormControl/DateTimeField';
import SelectSearchField from 'components/FormControl/SelectSearchField';
import useModalHook from 'components/ModalHook';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Badge, Row } from 'components/ui-kit';
import { EquipmentReportLevel, EquipmentReportStatusEnum, EquipmentReportType } from 'enum';
import { formatDateTime } from 'helper';
import { EquipmentHelper } from 'helper/Feature/equipment.help';
import useQueryParams from 'hooks/useQueryParams';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EquipmentReportDetailPage from './detail';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);

// eslint-disable-next-line react-hooks/exhaustive-deps
const EquipmentReportListPage = () => {
    const cols = [
        {
            field: 'equipmentCode',
            title: lang.text(Translations.equipment.code),
        },
        {
            field: 'pICFullName',
            title: lang.text(Translations.equipment.reportPersonInCharge),
        },
        {
            field: 'reportType',
            title: lang.text(Translations.common.reportType),
        },
        {
            field: 'status',
            title: lang.text(Translations.common.status),
        },
        {
            field: 'level',
            title: lang.text(Translations.equipment.reportRating),
        },
        {
            field: 'createdTime',
            title: lang.text(Translations.common.createdTime),
        },
        {
            field: 'createdUserName',
            title: lang.text(Translations.common.createdBy),
        },
        {
            field: 'action',
            title: '',
        },
    ];
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const reportTypes = useMemo(() => EquipmentHelper.getReportTypesOptions(), []);
    const levels = useMemo(() => EquipmentHelper.getLevelOptions(), []);
    const reportStatus = useMemo(() => EquipmentHelper.getReportStatusOptions(), []);
    const location = useLocation();
    var navigate = useNavigate();
    const { getQueryParam } = useQueryParams();
    const { t } = useTranslation();
    const [equipmentOptions, setEquipmentOptions] = useState<SelectOption[]>();

    const [paginationData, setPaginationData] = useState<PaginationBase<EquipmentReportResponse>>();
    const { control, handleSubmit, getValues, reset, formState } = useForm();

    useEffect(() => {
        let id = getQueryParam('id');
        id && showDetail(id);
    }, [location.search]);

    const showDetail = async (id: string) => {
        id &&
            (await openModal({
                isFullScreen: true,
                title: lang.text(Translations.common.reportDetail),
                isResetRoute: true,
                body: <EquipmentReportDetailPage id={id} />,
            }));
    };
    const searchEquipments = async (query: string) => {
        var form = getValues();
        equipmentApi
            .forDropdown(
                {
                    currentPage: 1,
                    rowsPerPage: 5,
                    keySearch: query,
                    companyId: form.companyId,
                    areaId: form.areaId,
                    contractorId: form.contractorId,
                },
                false
            )
            .then((res: SelectOption[]) => {
                setEquipmentOptions(res);
            })
            .catch((e) => {});
    };
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as EquipmentReportFilterRequest;
        filter.toDate = filter.toDate.toEndDay();

        equipmentReportApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                level: <Badge color={EquipmentHelper.getLevelColor(n.level)}>{n.level}</Badge>,
                                status: <Badge color={EquipmentHelper.getStatusColor(n.status)}>{n.status}</Badge>,
                                action: <ActionButtons2 id={n.id} onViewDetail={() => navigate('?id=' + n.id, { replace: true })} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <form className="w-full" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <Row>
                                <CompanySelect
                                    readOnly={false}
                                    disabled={false}
                                    name="companyId"
                                    control={control}
                                    onCompanyChange={(c) => {
                                        // onCompanyChange(c);
                                    }}
                                />
                                <SelectField
                                    label={t(Translations.common.reportType)}
                                    control={control}
                                    name="reportType"
                                    options={reportTypes}
                                    unSelectValue={EquipmentReportType.All}
                                    md={4}
                                    xs={12}
                                    onValueChange={(value) => {}}
                                />
                                <ContractorSelect required={false} control={control} name="contractorId" md={4} xs={12} />
                                <SelectField
                                    label={t(Translations.common.status)}
                                    options={reportStatus}
                                    name="status"
                                    control={control}
                                    required={false}
                                    unSelectValue={EquipmentReportStatusEnum.All}
                                    md={4}
                                    xs={12}
                                />
                                <SelectSearchField
                                    label={t(Translations.equipment.equipment)}
                                    control={control}
                                    name="equipmentId"
                                    options={equipmentOptions}
                                    onSearch={searchEquipments}
                                    md={4}
                                    xs={12}
                                    onValueChange={(value) => {
                                        // setEquipmentId(value);
                                    }}
                                />
                                <SelectField
                                    label={t(Translations.equipment.reportRating)}
                                    options={levels}
                                    name="level"
                                    control={control}
                                    required={false}
                                    md={4}
                                    xs={12}
                                    unSelectValue={EquipmentReportLevel.All}
                                />
                                <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} xs={6} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                                <DateTimeField label={lang.text(Translations.common.toDate)} md={4} xs={6} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />
                                <div className="col-span-full w-full self-center md:col-span-4">
                                    <div className="flex justify-end">
                                        <FilterButton type="submit" className="mt-3" />
                                    </div>
                                </div>
                            </Row>
                        </form>
                    </Card.Body>
                    <Card.Body>
                        <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} wrapperClass="mt-5" />
                        {Prompt}
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    );
};

export default EquipmentReportListPage;
