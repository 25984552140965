import { TRSafeInformation } from 'api/v2/trip/model/route.model';
import { AddButton, DeleteButton } from 'components/Button';
import { BaseFormValidationProps } from 'components/Common/BaseFormValidationProps';
import { InputField, TextAreaField, UploadImageField } from 'components/FormControl';
import { LocationField } from 'components/FormControl/LocationField';
import { Card, Col, Row } from 'components/ui-kit';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { Fragment } from 'react';
import { FieldValues, Path, useController, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
interface SafeInformationPlaceComponentProps<T extends FieldValues> extends BaseFormValidationProps<T> {
    required: boolean;
    canAdd: boolean;
}

export default function SafeInformationPlaceComponent<T extends FieldValues>(props: SafeInformationPlaceComponentProps<T>) {
    const {
        append,
        remove,
        fields: safeInformations,
    } = useFieldArray({
        control: props.control,
        name: props.name,
        rules: { required: 'This field is required' },
    });

    const {
        field: { value },
    } = useController({
        name: props.name,
        control: props.control,
        rules: props.required ? { required: 'This field is required' } : {},
    });

    return (
        <Fragment>
            <Card>
                <Card.Header>
                    <div className="flex justify-between">
                        <h5>{lang.text(Translations.trip.safetyInformation)}</h5>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row>
                        {value?.map((item: TRSafeInformation, index: number) => {
                            return (
                                <Fragment key={index}>
                                    <LocationField
                                        md={4}
                                        name={`${props.name}.${index}.fromPlace`}
                                        readOnly={props.isReadOnly}
                                        label={`${lang.text(Translations.trip.from)} ${index + 1}`}
                                        control={props.control}
                                        required={false}
                                    />
                                    <LocationField
                                        md={4}
                                        name={`${props.name}.${index}.toPlace`}
                                        readOnly={props.isReadOnly}
                                        label={`${lang.text(Translations.trip.to)} ${index + 1}`}
                                        control={props.control}
                                        required={false}
                                    />
                                    <InputField
                                        defaultValue={item.distance}
                                        required={true}
                                        control={props.control}
                                        name={`${props.name}.${index}.distance`}
                                        label={lang.text(Translations.trip.distance)}
                                        md={4}
                                        xs={12}
                                        type="number"
                                        max={2000}
                                        readOnly={props.isReadOnly}
                                        className="mt-4"
                                        placeholder="0 (km)"
                                        suffixText="km"
                                    />

                                    <TextAreaField
                                        md={12}
                                        xs={12}
                                        name={`${props.name}.${index}.safeInformation`}
                                        control={props.control}
                                        defaultValue={''}
                                        label={lang.text(Translations.trip.safetyInformation)}
                                        readOnly={props.isReadOnly}
                                    />
                                    {!props.isReadOnly && (
                                        <div style={{ height: 28 }} className="flex justify-end">
                                            <DeleteButton type="button" onClick={() => remove(index)}></DeleteButton>
                                        </div>
                                    )}
                                    <UploadImageField src={item.imagePath} readOnly={props.isReadOnly} control={props.control} name={`${props.name}.${index}.imagePath`} />
                                    <Col>
                                        <hr />
                                    </Col>
                                </Fragment>
                            );
                        })}
                    </Row>
                    {!props.isReadOnly && (
                        <div className="flex justify-end mt-5 w-full">
                            <div className="flex justify-end w-full mt-4">
                                <AddButton
                                    onClick={async () => {
                                        const isValid = await props.trigger(props.name as Path<T>); // Validate 'name' field
                                        if (!isValid) {
                                            toast.error('Vui lòng điền thông tin an toàn trước khi thêm mới');
                                            return;
                                        }
                                        append(
                                            {},
                                            {
                                                shouldFocus: true,
                                            }
                                        );
                                    }}
                                    type="button"
                                    isRender={props.canAdd}
                                    text={lang.text(Translations.trip.addSafetyInformation)}
                                />
                            </div>
                        </div>
                    )}
                </Card.Body>
            </Card>
        </Fragment>
    );
}
