import clsx from 'clsx';
import { classNames } from 'primereact/utils';
import React, { CSSProperties, useEffect } from 'react';
export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    className?: string;
    // xs?: number;
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    // lg?: number;
    // xl?: number;
    style?: CSSProperties | undefined;
}

export const Col: React.FC<ColProps> = ({ style, children, className, sm = 12, md = 12 }) => {
    const columns: any = {
        sm1: 'sm:col-span-1',
        sm2: 'sm:col-span-2',
        sm3: 'sm:col-span-3',
        sm4: 'sm:col-span-4',
        sm5: 'sm:col-span-5',
        sm6: 'sm:col-span-6',
        sm7: 'sm:col-span-7',
        sm8: 'sm:col-span-8',
        sm9: 'sm:col-span-9',
        sm10: 'sm:col-span-10',
        sm11: 'sm:col-span-11',
        sm12: 'sm:col-span-12',
        md1: 'md:col-span-1',
        md2: 'md:col-span-2',
        md3: 'md:col-span-3',
        md4: 'md:col-span-4',
        md5: 'md:col-span-5',
        md6: 'md:col-span-6',
        md7: 'md:col-span-7',
        md8: 'md:col-span-8',
        md9: 'md:col-span-9',
        md10: 'md:col-span-10',
        md11: 'md:col-span-11',
        md12: 'md:col-span-12',
    };
    return (
        <div style={style} className={`col-span-12 ${columns['md' + md]} ${columns['sm' + sm]} ${className}`}>
            {children}
        </div>
    );
};

export default Col;
