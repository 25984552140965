import { TNCourseDetailResponse } from 'api/v2/training/models/tnCourse.model';
import { TNCourseSubmitQuestionRequest, TNSubmitQuizRequest } from 'api/v2/training/models/tnMyCourse.model';
import tnMyCourseAPI from 'api/v2/training/tnMyCourseApi';
import { BaseButton } from 'components/Button/BaseButton';
import { TextAreaField } from 'components/FormControl';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { Card, Tab } from 'components/ui-kit';
import { CourseLimitType, CourseStatus, TNQuestionType } from 'enum/training';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { TabPanel } from 'primereact/tabview';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Routers from 'router/const/routers';
import TNHistoryComponent from '../component/myCourse/tnHistory.component';
import { TNDocumentFileList } from '../component/tnCourseDocuments.component';
import TNGeneralInfoComponent from '../component/tnGeneralInfo.component';

const TNTakeQuizPage = () => {
    const { control, watch, reset, handleSubmit, setValue, getValues } = useForm<TNCourseSubmitQuestionRequest>();
    const [course, setCourse] = useState<TNCourseDetailResponse>();
    const { id } = useParams();

    const [isStarted, setIsStarted] = useState(false);
    const [canStartQuiz, setCanStartQuiz] = useState<boolean>(false);

    const navigate = useNavigate();

    const answers = watch('answers');

    useEffect(() => {
        if (!id) return;
        handleGetQuiz(id);
        tnMyCourseAPI
            .canStartQuiz(id)
            .then((res) => {
                setCanStartQuiz(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id]);

    const handleGetQuiz = (id: string) => {
        tnMyCourseAPI
            .getById(id)
            .then((res) => {
                setValue('course', res!);
                setCourse(res);
                setValue(
                    'answers',
                    res?.questions?.map((question) => ({
                        submissionId: '',
                        questionId: question.id!,
                        answerIds: [],
                        quizType: question.quizType,
                        answerContent: '',
                        comment: '',
                    })) || []
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onSubmit = () => {
        const values = getValues();
        const data: TNSubmitQuizRequest[] = values?.answers.map((answer) => {
            return {
                questionId: answer?.questionId,
                answerIds: answer?.answerIds,
                answerContent: answer?.answerContent,
            };
        });
        handleSubmitQuiz(data);
    };

    const onChooseAnswer = ({ value, questionIndex, answerId }: { value: boolean; questionIndex: number; answerId: string }) => {
        const answerIds = value ? [...(answers?.[questionIndex]?.answerIds || []), answerId] : answers?.[questionIndex]?.answerIds?.filter((id: string) => id !== answerId);

        setValue(`answers.${questionIndex}.answerIds`, answerIds);
    };

    const handleStartQuiz = () => {
        tnMyCourseAPI
            .startQuiz(id!)
            .then(() => {
                setIsStarted(true);
            })
            .catch((err) => {
                // TODO: draft test, remove then
            });
    };

    const handleSubmitQuiz = (data: TNSubmitQuizRequest[]) => {
        tnMyCourseAPI
            .submitQuiz(id!, data)
            .then(() => {
                // setIsStarted(true);
                navigate(Routers.trainingOnline.myCourse);
            })
            .catch((err) => {
                // TODO: draft test, remove then
            });
    };

    return !course ? (
        <div></div>
    ) : (
        <form onSubmit={() => {}}>
            <Card>
                <Card.Body>
                    <div className="flex text-center justify-center flex-column">
                        <h4 className="text-primary">
                            {course.title} <br />
                            {!isStarted && (
                                <div className="flex justify-center mt-4">
                                    {canStartQuiz && (
                                        <ToastConfirm message={lang.text(Translations.modal.confirm.startQuiz)} onConfirm={() => handleStartQuiz()}>
                                            <BaseButton size="sm">{lang.text(Translations.training.startQuiz)}</BaseButton>
                                        </ToastConfirm>
                                    )}
                                </div>
                            )}
                        </h4>

                        <div className="flex gap-2 justify-center">
                            {course?.courseLimitType == CourseLimitType.Limit && (
                                <Fragment>
                                    <b>
                                        {lang.text(Translations.training.quizStartDate)}: {course.quizStartDate && formatDateTime(course.quizStartDate)}
                                    </b>

                                    <b>
                                        {lang.text(Translations.training.quizEndDate)}: {course.quizEndDate && formatDateTime(course.quizEndDate)}
                                    </b>
                                </Fragment>
                            )}
                        </div>
                    </div>
                    {!isStarted && (
                        <Tab activeIndex={0} className="mb-3 border-bottom border-primary rounded-0 course-tabs">
                            <TabPanel className="rounded-0" header={lang.text(Translations.common.generalInfo)}>
                                <TNGeneralInfoComponent isShowChart={false} control={control as any} isReadOnly={true} watch={watch as any} detail={course as any} />
                            </TabPanel>
                            <TabPanel className="rounded-0" header={lang.text(Translations.training.document)}>
                                <TNDocumentFileList documents={course?.documents ?? []} />
                            </TabPanel>
                            <TabPanel className="rounded-0" header="Bài nộp">
                                <TNHistoryComponent course={course} courseId={course?.id ?? ''} />
                            </TabPanel>
                        </Tab>
                    )}

                    {course.status !== CourseStatus.Completed && (
                        <>
                            {isStarted && (
                                <>
                                    <div className="mt-4">
                                        {course.questions?.map((question, index) => {
                                            return (
                                                <div key={index} className="mb-3">
                                                    <b>
                                                        {lang.text(Translations.training.question)} {index + 1}: {question.question}
                                                    </b>
                                                    <i>
                                                        {question?.quizType === TNQuestionType.Foreword && ' (Tự luận)'}
                                                        {question?.quizType === TNQuestionType.Experiment && ' (Chọn 1 đáp án đúng)'}
                                                        {question?.quizType === TNQuestionType.Mutiple && ' (Chọn ít nhất 1 đáp án đúng)'}
                                                    </i>
                                                    {question.quizType === TNQuestionType.Foreword && (
                                                        <TextAreaField placeholder="Nhập câu trả lời" control={control} name={`answers.${index}.answerContent`} />
                                                    )}
                                                    {question.answers && question.answers.length > 0 && (
                                                        <div>
                                                            {question?.answers?.map((answer, idx) => {
                                                                return (
                                                                    <div className="flex">
                                                                        {/* <Form.Check
                                                                            name={`answers.${index}.answers.${idx}.isUserAnswer`}
                                                                            inline
                                                                            type={'checkbox'}
                                                                            onChange={(e) => onChooseAnswer({ value: e.target.checked, questionIndex: index, answerId: answer.id! })}
                                                                        />
                                                                         */}
                                                                        <div className="flex align-items-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                name={`answers.${index}.answers.${idx}.isUserAnswer`}
                                                                                onChange={(e) => onChooseAnswer({ value: e.target.checked, questionIndex: index, answerId: answer.id! })}
                                                                            />
                                                                        </div>
                                                                        <div>{answer.content}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="flex justify-center">
                                        <ToastConfirm message={lang.text(Translations.modal.confirm.submitQuiz)} onConfirm={onSubmit}>
                                            <BaseButton size="sm">{lang.text(Translations.training.submitQuiz)}</BaseButton>
                                        </ToastConfirm>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
        </form>
    );
};

export default TNTakeQuizPage;
