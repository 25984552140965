//react-bootstrap
//components
import { PaginationBase } from 'api/models/common';
import { GoldenRuleResponse } from 'api/models/organization';
import configGoldenRuleApi from 'api/v2/configGoldenRuleApi';
import { AddButtonNavigate, DeleteButton, UpdateButtonNavigate } from 'components/Button';
import { Card, Empty } from 'components/shared';
import { Accordion } from 'components/ui-kit';
import { AccordionTab } from 'primereact/accordion';
import { memo, useEffect, useState } from 'react';
import Routers from 'router/const/routers';

const ConfigGoldenRulesPage = memo(() => {
    const [data, setData] = useState<GoldenRuleResponse[]>([]);

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        configGoldenRuleApi
            .getByCurrentCustomer()
            .then((res: PaginationBase<GoldenRuleResponse>) => {
                setData(res?.datas ?? []);
            })
            .catch((n) => {});
    };

    const handleDeleteData = async (id: string) => {
        await configGoldenRuleApi.delete(id);
        handleFetchData();
    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <div className="text-end w-full">
                        <AddButtonNavigate url={Routers.config.goldenRulesCreate} isRender={true} />
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* Table */}
                    {data.length ? (
                        <Accordion>
                            {data.map((item: any, index: number) => (
                                <AccordionTab key={index} header={item.name}>
                                    <Card className="p-0">
                                        <div>
                                            {item.content && <span className="d-block">{item.content}</span>}

                                            <div className="d-lg-flex justify-end gap-2">
                                                <DeleteButton showConfirm={true} onConfirmed={() => handleDeleteData(item.id)} />
                                                <UpdateButtonNavigate url={Routers.config.goldenRulesUpdate.replace(':id', item.id)} />
                                            </div>
                                        </div>
                                    </Card>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    ) : (
                        <Empty />
                    )}
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigGoldenRulesPage;
