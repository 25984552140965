import { avatarCompanyDefault } from 'constants/common';
import { ImageProps } from 'components/ui-kit/index';
import { CSSProperties, useMemo, useState } from 'react';

interface ImageWithDefaultProps extends ImageProps {
    addLink?: boolean;
    style?: CSSProperties | undefined;
}
const ImageWithDefault = (props: ImageWithDefaultProps) => {
    const [imageLoadError, setImageLoadError] = useState(false);
    const handleImageError = () => {
        setImageLoadError(true);
    };
    let sourcePath = useMemo(() => {
        return props.src ? `${process.env.REACT_APP_FILE_SEVER_URL}${props.src}` : avatarCompanyDefault;
    }, [props.src]);
    return (
        <a className="p-0" href={props.addLink == true ? sourcePath : `#`} target={props.addLink ? '_blank' : '_self'}>
            <img
                {...props}
                src={sourcePath}
                //onError={handleImageError}
                className={`bg-soft-primary rounded img-fluid  mr-1 ${props.className}`}
                onError={(e: any) => {
                    e.target.src = avatarCompanyDefault; // Replace with your desired fallback image
                    // e.target.style = 'padding: 8px; margin: 16px'; // Optional: Apply inline styles
                }}
            />
        </a>
    );
};
export default ImageWithDefault;
