import { CustomerCreateRequest, CustomerResponse } from 'api/models';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import customerApi from 'api/v2/customerApi';
import ImageWithDefault from 'components/bootstrap/ImageWithDefault';
import { AddButton } from 'components/Button';
import useModalHook from 'components/ModalHook';
import { Card } from 'components/shared';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import { Col, Row, TableColumn } from 'components/ui-kit';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { AddForm } from './addForm';

const CustomerPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<CustomerResponse>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);
    const { userPms } = useAuth();

    const onDelete = async (id: string) => {
        customerApi
            .delete(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch((n) => {});
    };

    const onAdd = async () => {
        var rs = await openModal({
            title: lang.text(Translations.pages.customer.add),
            body: <AddForm onClose={onClose} onCompleted={onCompleted} />,
        });
        rs &&
            customerApi
                .create(rs as CustomerCreateRequest)
                .then((res) => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch((err) => {});
    };

    const onUpdate = async (cus: CustomerResponse) => {
        var rs = await openModal({
            title: lang.text(Translations.pages.customer.update),
            body: <AddForm onClose={onClose} onCompleted={onCompleted} name={cus.name} logo={cus.logo ?? undefined} />,
        });
        rs &&
            customerApi
                .update({
                    ...rs,
                    id: cus.id,
                })
                .then((res) => {
                    handleFetchData(defaultPaginationRequest);
                })
                .catch((err) => {});
    };

    const handleFetchData = async (paginate: PaginationBaseRequest) => {
        customerApi
            .getPagination(paginate)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                logoImg: <ImageWithDefault className=" avatar-40" src={n.logo ?? undefined} />,
                                action: <ActionButtons2 id={n.id} editPms={userPms.tenantAdmin} deletePms={userPms.tenantAdmin} onDelete={onDelete} editAction={(id: string) => onUpdate(n)} />, // replace 'value' with the actual value you want to set
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((n) => {});
    };

    const cols: TableColumn[] = useMemo(
        () => [
            {
                field: 'logoImg',
                header: '',
                style: {
                    width: '50px',
                },
            },
            {
                field: 'name',
                header: lang.text(Translations.form.name),
            },
            {
                field: 'createdTime',
                header: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                header: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                header: '',
            },
        ],
        []
    );

    return (
        <Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <div className="flex justify-end">
                                <AddButton onClick={onAdd} isRender={userPms.supperAdmin} />
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <PaginationTable onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} disableSearch={true} wrapperClass="mt-5" />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {Prompt}
        </Fragment>
    );
};

export default CustomerPage;
