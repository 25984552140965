import { useEffect } from 'react';
import { LoginRequest } from '../../api/v2/user/model/login.model';

//react-bootstrap
import { Image } from 'components/ui-kit';

//router
import { useNavigate } from 'react-router-dom';

//components

// img
import { Logo } from 'assets/images';
import { AuthO1 } from 'assets/images/auth';

// Import selectors & action from setting store

// Redux Selector / Action
import { selectIsLoggin } from 'app/appSlice';
import LoginForm from 'views/auth/loginForm';
// import useAsync from 'hooks/useAsync';
import authApi from 'api/v2/authApi';
import { useAppSelector } from 'app/hooks';
import { SelectLanguage } from 'components/shared';
import useQueryParams from 'hooks/useQueryParams';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useAuth } from 'providers/contexts';

const SignInPage = () => {
    const { loginSuccess, handleRefesh } = useAuth();
    const isLoggin = useAppSelector(selectIsLoggin);
    const initialValues: LoginRequest = {
        phoneNumber: '',
        password: '',
    };
    const navigate = useNavigate();
    const { getQueryParam } = useQueryParams();

    const handleLoginFormSubmit = async (formValues: LoginRequest) => {
        await authApi
            .login(formValues)
            .then((rs) => {
                if (rs.access_token) {
                    loginSuccess(rs.access_token);
                    navigate(getQueryParam('url') ?? '/', { replace: true });
                }
            })
            .catch((err) => {});
    };
    useEffect(() => {
        handleRefesh();
    }, []);

    return (
        <div className="flex h-screen overflow-hidden">
            <div className="px-10 justify-center flex flex-col flex-1 min-w-[460px] max-w-[600px]">
                <div className="flex justify-end items-center">
                    <SelectLanguage />
                </div>
                <span className="navbar-brand flex items-center">
                    <Image
                        src={Logo}
                        style={{
                            width: 50,
                            height: 50,
                        }}
                        alt="logo"
                    />
                    <h4 className="">HSEVN</h4>
                </span>
                <h2 className="mb-2 text-center">{t(Translations.pages.signin.name)}</h2>
                <p className="text-center">{t(Translations.pages.signin.subTitle)}</p>

                <LoginForm initialValues={initialValues} onSubmit={handleLoginFormSubmit} />
            </div>
            <Image src={AuthO1} className="h-screen object-cover hidden md:block flex-1" alt="images" />
        </div>
    );
};

export default SignInPage;
