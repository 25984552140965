import { Scanner } from '@yudiel/react-qr-scanner';
import { ModalHookComponentProps } from 'components/ModalHook';
import React from 'react';

interface ScanQRComponentProps extends ModalHookComponentProps {
}
const ScanQRComponent = ({ onClose, onCompleted }: ScanQRComponentProps) => {
    return (
        <Scanner
            constraints={{
                facingMode: 'development',
            }}
         
            allowMultiple={false}
            onScan={(result) => {
                onCompleted(result[0].rawValue);
            }}
        />
    );
};
export default ScanQRComponent;
