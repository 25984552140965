import PrimeMenu from 'components/builder/prime/PrimeMenu';
import React from 'react';
import { Link } from 'react-router-dom';
import Routers from 'router/const/routers';

import { Logo as MainLogo } from 'assets/images';
import { Sidebar } from 'components/ui-kit';

const MobileSidebar: React.FC = () => {
    const [visible, setVisible] = React.useState(false);

    return (
        <>
            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                header={
                    <div className="flex gap-2 p-2">
                        <div className="w-[60px] h-[60px]">
                            <Link to={Routers.root}>
                                <img src={MainLogo} alt="logo" className="w-full h-full" />
                            </Link>
                        </div>
                        <div className="">
                            <span className="text-[18px] font-semibold">HSEVN</span>
                            <div className="text-[12px]">Health Safety Security and Environment</div>
                        </div>
                    </div>
                }
            >
                <PrimeMenu />
            </Sidebar>
            <div className="pi pi-bars !text-lg -mr-1 text-primary p-1 cursor-pointer" onClick={() => setVisible(true)} />
        </>
    );
};

const PrimeSidebar: React.FC = () => {
    return (
        <>
            <div className="absolute md:hidden top-2 left-2 z-10">
                <MobileSidebar />
            </div>
            <div className="hidden md:block w-[288px] h-screen bg-white">
                <div className="flex gap-2 p-2">
                    <div className="w-[60px] h-[60px]">
                        <Link to={Routers.root}>
                            <img src={MainLogo} alt="logo" className="w-full h-full" />
                        </Link>
                    </div>
                    <div className="">
                        <span className="text-[18px] font-semibold">HSEVN</span>
                        <div className="text-[12px]">Health Safety Security and Environment</div>
                    </div>
                </div>
                <PrimeMenu />
            </div>
        </>
    );
};

export default PrimeSidebar;
