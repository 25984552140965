import { SelectField, SelectOption } from 'components/FormControl';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import MutilSelectField from 'components/FormControl/MutilSelectField';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { useCustomer } from 'providers/contexts';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';

export interface CompanySelectProps extends BaseFormControlProps {
    onCompanyChange?: (companyId: string) => void;
    onCompaniesChange?: (companyIds: string[]) => void;
    isAddAll?: boolean;
    control: Control<any>;
    isMutiple?: boolean;
}
export function CompanySelect({ name, control, isMutiple = false, required, md = 4, xs = 12, onCompaniesChange, onCompanyChange, isAddAll, readOnly, defaultValue }: CompanySelectProps) {
    const { currentCustomer } = useCustomer();
    const [companyOptions, setCompanyOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadCompany();
    }, [currentCustomer]);

    useEffect(() => {
        loadCompany();
    }, []);

    const loadCompany = () => {
        const options = currentCustomer?.companies.toSelectOptions('name', 'id') ?? [];
        setCompanyOptions(options);
    };
    if (isMutiple === true) {
        return (
            <MutilSelectField
                label={lang.text(Translations.form.site.name)}
                control={control}
                options={companyOptions}
                name={name ?? 'companyIds'}
                required={required}
                md={md}
                xs={xs}
                onValueChange={(ids) => {
                    if (!Array.isArray(ids)) onCompanyChange && onCompanyChange(ids);
                    else onCompaniesChange && onCompaniesChange(ids);
                }}
            />
        );
    }
    return (
        <SelectField
            readOnly={readOnly}
            label={t(Translations.form.site.name)}
            options={companyOptions || []}
            name={name ?? 'companyId'}
            control={control}
            required={required}
            md={md}
            xs={xs}
            onValueChange={(e) => {
                onCompanyChange && onCompanyChange(e);
            }}
        />
    );
}
