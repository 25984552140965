// @ts-nocheck

//react-bootstrap
import { Badge } from 'components/ui-kit';

//components
import { SORReportDetailResponse } from 'api/v2/sor/model/sorReport.model';
import sorReportApi from 'api/v2/sor/sorReportApi';
import { DeleteButton, DownloadButton } from 'components/Button';
import { CloseButton } from 'components/Button/CloseButton';
import ImageDisplay from 'components/Images/ImageDisplay/imageDisplay';
import usePromptInputText from 'components/Modal/PromptInputText';
import DataListView, { DataListViewItem } from 'components/shared/DataListView';
import { Col, Row } from 'components/ui-kit';
import Card from 'components/ui-kit/primereact/Card';
import Color from 'enum/color';
import { formatDateTime } from 'helper';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { ModalDetailProps } from 'models/modalDetailProps';
import { useEffect, useState } from 'react';

interface SORDetailPageProps extends ModalDetailProps {
    reportDefault?: SORReportDetailResponse;
}

const SORDetailPage = ({ id, onDeleted, reportDefault }: SORDetailPageProps) => {
    const [report, setReport] = useState<SORReportDetailResponse>(null);
    const [datas, setDatas] = useState<DataListViewItem[]>([]);
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    useEffect(() => {
        if (reportDefault) {
            setDataState(reportDefault);
        } else loadReport();
    }, []);

    const onCloseReport = () => {
        report &&
            sorReportApi
                .closeSReport(report.id)
                .then((n) => {
                    loadReport();
                })
                .catch((n) => {});
    };

    const loadReport = () => {
        if (id)
            sorReportApi
                .getById(id)
                .then((r: SORReportDetailResponse) => setDataState(r))
                .catch((n) => {});
    };

    const setDataState = (r: SORReportDetailResponse) => {
        setReport(r);
        try {
            let dts = [
                {
                    label: lang.text(Translations.common.reportType),
                    value: (
                        <div className="p-0">
                            <Badge color={r.isSafe ? Color.success : Color.danger}>{r.isSafe ? lang.text(Translations.common.safe) : lang.text(Translations.common.unSafe)} </Badge>
                        </div>
                    ),
                },
                {
                    label: lang.text(Translations.common.status),
                    value: (
                        <div className="p-0">
                            <Badge color={r.status ? Color.success : Color.danger}>{r.status}</Badge>
                        </div>
                    ),
                },
                {
                    label: lang.text(Translations.common.createdBy),
                    value: r.createdUserName,
                },
                {
                    label: lang.text(Translations.common.createdTime),
                    value: r.createdTime && formatDateTime(r.createdTime),
                },
                {
                    label: lang.text(Translations.form.site.name),
                    value: r.companyName,
                },
                {
                    label: lang.text(Translations.form.area.name),
                    value: r.areaName,
                },

                {
                    label: lang.text(Translations.contractor),
                    value: r.contractorName,
                },

                {
                    label: lang.text(Translations.sor.category),
                    value: r.categoryName,
                },

                {
                    label: lang.text(Translations.sidebar.config.goldenRules),
                    value: r.goldenRuleName,
                },

                {
                    label: lang.text(Translations.sidebar.config.riskLevel),
                    value: r.riskLevelName,
                },
                {
                    label: lang.text(Translations.sor.target),
                    value: r.target,
                },
                {
                    label: lang.text(Translations.sor.target),
                    value: r.solution,
                },
            ];
            setDatas(dts);
        } catch (error) {}
    };
    const onDelete = () => {
        if (id) {
            sorReportApi
                .delete(id)
                .then(() => {
                    onDeleted && onDeleted();
                })
                .catch(() => {});
        }
    };
    const onDownload = () => {
        if (id) {
            sorReportApi
                .downloadReport(id)
                .then((n) => {})
                .catch((n) => {});
        }
    };
    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="flex justify-end">
                        <DownloadButton onClick={onDownload} isRender={report != undefined} />
                        <CloseButton onClick={onCloseReport} isRender={report?.canClose === true} />
                        <DeleteButton onClick={onDelete} isRender={report?.canDelete} />
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    {datas && <DataListView datas={datas} />}
                    <Row>
                        {report?.imagePathOne && (
                            <Col md={6}>
                                <ImageDisplay src={report?.imagePathOne} />
                            </Col>
                        )}
                        {report?.imagePathTwo && (
                            <Col md={6}>
                                <ImageDisplay src={report?.imagePathTwo} />
                            </Col>
                        )}
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default SORDetailPage;
