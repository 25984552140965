import { Col, Row } from 'components/ui-kit';
import Progress from 'components/ui-kit/primereact/progress';
import Color from 'enum/color';
interface ProgressItemProps {
    value: number;
    text: string;
    successText: string;
    errorText: string;
    maxValue?: number;
}
const ProgressItem = ({ value, errorText, successText, text, maxValue }: ProgressItemProps) => {
    return (
        <Row className=" border-bottom pb-3 items-center iq-social-keyMetrics">
            <Col md={5}>
                <label className="iq-key-metrics mb-0 text-info">{text}</label>
            </Col>
            <Col md={7} className="iq-drive-progress flex items-center">
                <span className="key-number">{successText} </span>
                <Progress softcolors={Color.danger} color={Color.primary} className="shadow-none w-full mx-1" value={value} minvalue={0} maxvalue={maxValue ?? 100} style={{ height: '6px' }} />
                <span className="ms-3"> {errorText}</span>
            </Col>
        </Row>
    );
};

export default ProgressItem;
