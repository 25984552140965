import { yupResolver } from '@hookform/resolvers/yup';
import secGateApi from 'api/v2/sec/secGateApi';
import { RadioField, RadioOption, TextAreaField, UploadImageField } from 'components/FormControl';
import ModalFooterButtons from 'components/Modal/ModelFooterButtons';
import { ModalHookComponentProps } from 'components/ModalHook';
import { EntityConst } from 'constants/EntityConst';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
interface CheckInOutModalModalProps extends ModalHookComponentProps {
    organizationId: string;
}
const CheckInOutModalModal = ({ onClose, onCompleted, organizationId }: CheckInOutModalModalProps) => {
    const [gates, setGates] = useState<RadioOption[]>([]);
    const schema = yup.object().shape({
        description: yup.string().notRequired().min(1).max(EntityConst.descriptionShort),
        gateId: yup.string().required(),
    });
    useEffect(() => {
        secGateApi
            .getDropdown(organizationId)
            .then((res) => {
                setGates(
                    res.map((n) => ({
                        label: n.label,
                        value: n.value,
                        defaultChecked: false,
                        className: '',
                    }))
                );
            })
            .catch(() => {});
    }, []);

    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const submit = async () => {
        let form = getValues();
        onCompleted({
            ...form,
        });
    };
    return (
        <form onSubmit={handleSubmit(submit)}>
            {gates && <RadioField orientation="vertical" required inline={true} label={lang.text(Translations.sec.gate)} name="gateId" control={control} options={gates} />}
            <hr />
            <RadioField
                required
                inline={true}
                label={lang.text(Translations.common.status)}
                name="isApproved"
                control={control}
                options={[
                    {
                        label: lang.text(Translations.sec.approve),
                        value: 1,
                        className: 'text-success',
                    },
                    {
                        label: lang.text(Translations.sec.unApprove),
                        value: 0,
                        className: 'text-danger',
                    },
                ]}
            />
            <hr />
            <TextAreaField name="reason" control={control} label={lang.text(Translations.common.description)} />
            <UploadImageField name="imagePath" control={control} />
            <ModalFooterButtons onClose={onClose} okButtonType="submit" />
        </form>
    );
};
export default CheckInOutModalModal;
