import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { t } from 'i18next';
import { confirmDialog } from 'primereact/confirmdialog';
import { BaseButton } from './BaseButton';
import { BaseButtonProps } from './BaseButton.props';

interface DeleteButtonProps extends BaseButtonProps {
    showConfirm?: boolean;
    onConfirmed?: () => void;
}

export function DeleteIconButton({ onClick }: DeleteButtonProps) {
    return <BaseButton icon="pi pi-trash" onClick={onClick} />;
}

export function DeleteButton(props: DeleteButtonProps) {
    var isRender = props.isRender ?? true;
    const onConfirmed = () => {
        confirmDialog({
            message: lang.text(Translations.modal.confirm.confirmDelete),
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: props.onConfirmed,
            reject: () => {},
        });
    };
    if (props.isRender === undefined) isRender = true;
    return <BaseButton onClick={!props.showConfirm ? props.onClick : onConfirmed} {...props} type="button" isRender={isRender} color="danger" text={props.text ?? t(Translations.button.delete)} />;
}
