import userApi from 'api/v2/user/userApi';
import { SelectField, SelectOption } from 'components/FormControl';
import MutilSelectField, { MutilSelectFieldProps } from 'components/FormControl/MutilSelectField';
import { Col } from 'components/ui-kit';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

export interface UserSelectFieldProps extends MutilSelectFieldProps {
    companyId?: string;
    isMutiple?: boolean;
}

export const UserSelectField: React.FC<UserSelectFieldProps> = (props: UserSelectFieldProps) => {
    const [users, setUsers] = useState<SelectOption[]>([]);

    const {
        field,
        fieldState: { error, invalid },
    } = useController({
        name: props?.name,
        control: props.control,
    });

    useEffect(() => {
        loadUsers();
    }, [props.companyId]);

    const loadUsers = () => {
        userApi
            .searchDropdown('', props.companyId)
            .then((users) => {
                setUsers(users);
            })
            .catch((n) => {});
    };
    useEffect(() => loadUsers, []);
    useEffect(() => {
        if (props.onValueChange) props.onValueChange(field.value);
        props.onSelectedOptionChange && props.onSelectedOptionChange(users.find((option) => option.value == field.value));
    }, [field.value]);
    return props?.isMutiple ? (
        <Col md={props?.md} sm={props?.xs}>
            <MutilSelectField {...props} label={props.label} options={users} name={props.name} />
        </Col>
    ) : (
        <SelectField {...props} label={props.label} options={users} name={props.name} />
    );
};
export default UserSelectField;
