import { confirmDialog } from 'primereact/confirmdialog';
interface Props {
    children: React.ReactNode;
    onConfirm: () => void;
    onCanceled?: () => void;
    message?: string;
}

const ToastConfirm = ({ children, onCanceled, onConfirm, message }: Props) => {
    const onConfirmed = () => {
        confirmDialog({
            message: message,
            header: 'Confirm',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: onConfirm,
            reject: () => {},
        });
    };
    return (
        <>
            <div onClick={() => onConfirmed()}>{children}</div>
        </>
    );
};
export default ToastConfirm;

// <ConfirmToast
//     asModal={asModal}
//     customCancel={customCancel}
//     customConfirm={customConfirm}
//     customFunction={customFunction}
//     message={message}
//     position={position}
//     showCloseIcon={showCloseIcon}
//     theme={'light'}
// >
//     {children}
// </ConfirmToast>
